















import IconInfo from '@/assets/svg/icons/icon-info.svg?inline';
import {Component, Vue} from 'vue-property-decorator';
import LocationCard from '@/components/global/LocationCard.vue';

@Component({
  name: 'InfoCard', components: {
    LocationCard,
    IconInfo
  }
})
export default class InfoCard extends Vue {
}
