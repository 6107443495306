















































































































































































































































import SvgArrowDown from '@/assets/svg/icons/icon-arrow-down.svg?inline';
import IconSearch from '@/assets/svg/icons/icon-search.svg?inline';
import AccordionWrap from '@/components/global/AccordionWrap.vue';
import TrButton from '@/components/shared/TrButton.vue';
import TrDropdown from '@/components/shared/TrDropdown.vue';
import TrTooltip from '@/components/shared/TrTooltip.vue';

import {UserTypeDefinitions} from '@/typings/interfaces/Misc';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
    SvgArrowDown,
    IconSearch,
    AccordionWrap,
    TrTooltip,
    TrButton,
    TrDropdown,
  },
})
export default class UiKit extends Vue {
  options: UserTypeDefinitions[] = [
    {
      value: 1,
      label: 'Professional',
      maxItems: 10
    },
    {
      value: 2,
      label: 'Architect',
      maxItems: 10
    },
    {
      value: 3,
      label: 'It\'s private',
      maxItems: 5
    },
  ];

  searchTest1 = '';
  checked = true;
  radio = 3;

  labelPosition = 'top';
  ruleForm = {
    name: '',
    region: '',
    type: '',
    date1: '',
  };
}
