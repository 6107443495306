






















import ChevronDown from '@/assets/svg/icons/icon-chevron-down.svg?inline';
import {Component, Prop, PropSync, Ref, Vue, Watch} from 'vue-property-decorator';

@Component({name: 'Accordion', components: {ChevronDown}})
export default class Accordion extends Vue {
  @Prop() public title!: string;
  @PropSync('isAccordionOpen', {type: Boolean}) public isAccordionOpenSynced!: boolean;

  @Ref('accordion-button') public readonly accordionButton!: HTMLElement;

  @Watch('isAccordionOpen')
  toggleAccordion(doOpen: boolean) {
    this.accordionButton.style.maxHeight = doOpen ? this.accordionButton.scrollHeight + 'px' : '';
    this.accordionButton.style.margin = doOpen ? '0px 0px 10px 0px' : '';
    this.$emit('toggleAccordionTriggered' , doOpen);
  }

  private mounted(): void {
    this.toggleAccordion(this.isAccordionOpenSynced);
  }
}

