import {StoreModules} from '@/store';
import {Module} from 'vuex';

export interface OrderingState {
    sampleBasketOrderModalOpen: boolean;
}

const module: Module<OrderingState, StoreModules> = {
    namespaced: true
};

module.state = {
    sampleBasketOrderModalOpen: false
};

module.getters = {
    isSampleBasketOrderModalOpen: state => state.sampleBasketOrderModalOpen
};

module.actions = {
    setSampleBasketOrderModalOpen({commit}, payload: boolean) {
        commit('setSampleBasketOrderModalOpen', payload);
    }
};

module.mutations = {
    setSampleBasketOrderModalOpen(state, payload: boolean) {
        state.sampleBasketOrderModalOpen = payload;
    }
};

export default module;

