

















































































































































































import cartApi from '@/api/cartApi';
import {Filter} from '@/api/DeliveryEndpoint';
import productsApi from '@/api/productsApi';
import IconCancel from '@/assets/svg/icons/icon-cancel.svg?inline';

import IconShopping from '@/assets/svg/icons/icon-shopping.svg?inline';
import SampleBasket from '@/components/sample-selector/SampleBasket.vue';
import TrButton from '@/components/shared/TrButton.vue';
import {Cart} from '@/store/shoppingCart';
import {Category} from '@/typings/interfaces/Misc';
import {CartMixin} from '@/utils/mixins/CartMixin';
import {ImgUrlMixin} from '@/utils/mixins/ImgUrlMixin.js';
import parseBool from '@/utils/parseBool';
import {mixins} from 'vue-class-component';
import {Component, Prop, Ref} from 'vue-property-decorator';
import {ElForm} from 'element-ui/types/form';
import Accordion from '@/components/global/Accordion.vue';

const isPrivacyChecked = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('Please accept Privacy policy'));
  }
  callback();
};

interface ValidateFormStepResult {
  isValid: boolean,
  invalidFields: Record<string, unknown>
}

interface ValidateFormResult {
  stepOne: ValidateFormStepResult,
  stepTwo: ValidateFormStepResult,
  isFormValid: boolean
}

@Component({
  name: 'SampleBasketOrderModal',
  components: {Accordion, TrButton, SampleBasket, IconShopping, IconCancel},
})
export default class SampleBasketOrderModal extends mixins(ImgUrlMixin, CartMixin) {
  @Prop({required: true}) readonly salutations!: Array<Category>;
  @Prop({required: true}) readonly professions!: Array<Category>;
  @Prop({required: true}) readonly countries!: Array<Category>;
  @Prop({required: true}) readonly regions!: Array<Category>;
  @Prop({required: true}) readonly privacyPolicyLink!: string;
  @Prop({required: true}) readonly formStepOneLabel!: string;
  @Prop({required: true}) readonly formStepTwoLabel!: string;
  @Prop({required: true}) readonly salutationLabel!: string;
  @Prop({required: true}) readonly firstNameLabel!: string;
  @Prop({required: false}) readonly honeyNameLabel!: string;
  @Prop({required: false}) readonly reverseFieldLabel!: string;
  @Prop({required: true}) readonly lastNameLabel!: string;
  @Prop({required: true}) readonly companyNameLabel!: string;
  @Prop({required: true}) readonly addressLabel!: string;
  @Prop({required: true}) readonly countryLabel!: string;
  @Prop({required: true}) readonly cityLabel!: string;
  @Prop({required: true}) readonly zipcodeLabel!: string;
  @Prop({required: true}) readonly emailLabel!: string;
  @Prop({required: true}) readonly phoneLabel!: string;
  @Prop({required: true}) readonly professionLabel!: string;
  @Prop({required: true}) readonly startDateLabel!: string;
  @Prop({required: true}) readonly squareFeetLabel!: string;
  @Prop({required: true}) readonly otherLabel!: string;
  @Prop({required: true}) readonly newsletterLabel!: string;
  @Prop({required: true}) readonly privacyPolicyLabel!: string;
  @Prop({required: true}) readonly regionLabel!: string;
  @Prop({required: true}) readonly blockList!: string[];
  @Prop() readonly introText!: string;
  @Prop() readonly moveToStepTwoText!: string;
  @Prop({required: true}) readonly orderPlacedMessage!: string;

  @Ref('formStep1') private formStep1!: ElForm;
  @Ref('formStep2') private formStep2!: ElForm;

  form = {
    title: '',
    firstName: '',
    honeyName: '',
    reverseField: 'Confirmed',
    lastName: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    region: '',
    city: '',
    zipcode: '',
    email: '',
    phone: '',
    profession: '',
    startDate: '',
    squareFeet: '',
    otherProject: '',
    newsletter: Boolean,
    privacy: false,
  };

  rules = {
    title: [
      {
        required: true,
        trigger: 'change',
      },
    ],
    firstName: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    honeyName: [
      {
        required: false,
        trigger: 'blur',
      },
    ],
    reverseField: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    lastName: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    companyName: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    houseNumber: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    addressLine1: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    addressLine2: [],
    country: [
      {
        required: true,
        trigger: 'change',
      },
    ],
    region: [
      {
        required: false,
        trigger: 'change',
      }
    ],
    city: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    zipcode: [
      {
        required: true,
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        trigger: 'blur',
      },
      {
        type: 'email',
        trigger: ['blur', 'change'],
      },
    ],
    phone: [
      {
        required: true,
        message: 'Phone is required',
        trigger: 'blur',
      },
    ],
    profession: [
      {
        required: true,
        trigger: 'change',
      },
    ],
    // put start day on this day
    startDate: [],
    squareFeet: [
      {required: true, message: 'Please add square feet façade cladding '},
      {type: 'number', message: 'This must be a number'},
    ],
    otherProject: [
      // {
      //   required: true,
      //   message: "Please input company name",
      //   trigger: "blur",
      // },
    ],
    newsletter: [
      // {
      //   trigger: 'blur',
      // },
    ],
    privacy: [
      {validator: isPrivacyChecked, trigger: 'change', required: true},
    ],
  };

  private isOrdering: boolean = false;
  private country: string = '';

  // Display controls
  infoMessage = '';
  showClose = false;
  showMainButtons = true;
  private isOrderProcessFinished: boolean = false;

  private showingFormStepOne: boolean = true;
  private showingFormStepTwo: boolean = false;

  get isUSCountrySelected() {
    return this.country === 'US';
  }

  private areAllStepsOpen() {
    return this.showingFormStepOne && this.showingFormStepTwo;
  }

  private toggleStep(stepNumber: number, doOpen: boolean) {
    if (stepNumber === 1) {
      this.showingFormStepOne = doOpen;
    }

    if (stepNumber === 2) {
      this.showingFormStepTwo = doOpen;
    }

    if (doOpen) {
      this.addSampleFormEventToDataLayer(stepNumber === 1 ? 'showFirstFormStep' : 'showSecondFormStep');
    }
  }

  private validateForm(): ValidateFormResult {
    function validateFormStep(step: ElForm): ValidateFormStepResult {
      let result = {
        isValid: false,
        invalidFields: {}
      };

      step.validate((isValid, invalidFields) => {
        result.isValid = isValid
        result.invalidFields = invalidFields;
      })

      return result;
    }

    const validateFormStepOneResult = validateFormStep(this.formStep1);
    const validateFormStepTwoResult = validateFormStep(this.formStep2);

    return {
      stepOne: validateFormStepOneResult,
      stepTwo: validateFormStepTwoResult,
      isFormValid: validateFormStepOneResult.isValid && validateFormStepTwoResult.isValid
    }
  }

  // Used when testing sample order flow
  private emitTest(success, failure, succeeds) {
    if (succeeds) {
      setTimeout(success, 2000);
    } else {
      setTimeout(failure, 2000);
    }
  }

  // Blocked e-mail adresses
  private isBlocked = (email: string) => this.blockList.some((entry) => {
    entry = entry.toLowerCase();
    email = email.toLowerCase();
    if (entry.startsWith("*")) {
      const domain = entry.split("@")[1];
      return email.endsWith(domain);
    }
    return entry === email;
  });

  public close() {
    if (this.isOrderProcessFinished) {
      this.addSampleFormEventToDataLayer('closedAfterFinishedSampleOrderProcess');
    } else {
      this.addSampleFormEventToDataLayer('sampleOrderCancelled');
    }

    this.$store.dispatch('ordering/setSampleBasketOrderModalOpen', false);
    this.$emit('applySampleOrderFinishedStyling', false);
  }

  private async placeOrder() {
    await this.$recaptchaLoaded();
    const token = await this.$recaptcha('submitOrder');

    if (!this.isBlocked(this.form.email)) {
      if (this.isOrdering) return;

      this.isOrdering = true;

      const validateFormResult = this.validateForm();

      if (validateFormResult.isFormValid) {
        if (!(this.form.honeyName == null || this.form.honeyName === '') || this.form.reverseField !== "Confirmed") {
          const logInfo = {
            email: this.form.email,
            info: {
              failure: "Honey pot span triggered",
              honeyName: this.form.honeyName,
              reverseField: this.form.reverseField,
            }
          }
          await cartApi.log(logInfo).catch(e => console.log(e));
        }

        this.infoMessage = this.$t('sampleSelector.info.processingOrder').toString();

        const cart: Cart = this.$store.getters['shoppingCart/cart']; //await this.cartToItemList();

        cart.items.forEach(item => {
          delete item.panelOrSidingLocation;
        });

        const cartObj = {
          mail: this.form.email,
          phone: this.form.phone,
          comment: this.form.otherProject,
          title: this.form.title,
          profession: this.form.profession,
          newsletter: {
            subscribe: this.form.newsletter,
            catExterior: this.form.newsletter,
            catScientific: this.form.newsletter
          },
          project: {
            startDate: this.formatDate(this.form.startDate),
            size: this.form.squareFeet
          },
          address: {
            organization: this.form.companyName,
            givenName: this.form.firstName,
            familyName: this.form.lastName,
            addressLine1: this.form.addressLine1,
            addressLine2: this.form.addressLine2,
            locality: this.form.city,
            postalCode: this.form.zipcode,
            administrativeArea: '',
            countryCode: this.form.country,
            region: this.form.region
          },
          cart,
          token
        };

        this.addSampleFormEventToDataLayer('placingSampleOrder');

        cartApi.order(cartObj).then(() => {
          this.$store.dispatch('shoppingCart/clearCart');

          this.isOrdering = false;
          this.infoMessage = this.orderPlacedMessage;
          this.showClose = true;
          this.showMainButtons = false;
          this.isOrderProcessFinished = true;
          this.addSampleFormEventToDataLayer('sampleOrderPlaced');
          this.$emit('applySampleOrderFinishedStyling', true);

        }).catch(reason => {
          console.error('Order failed', reason);
          this.isOrdering = false;
          this.infoMessage = this.$t('sampleSelector.info.orderError').toString();
          this.addSampleFormEventToDataLayer('placingSampleOrderFailed');
        });

      } else {
        this.toggleStep(1, !validateFormResult.stepOne.isValid);
        this.toggleStep(2, !validateFormResult.stepTwo.isValid);

        let invalidFields: string[] = [];

        const invalidFieldsOfStepOne: string[] = Object.keys(validateFormResult.stepOne.invalidFields);
        const invalidFieldsOfStepTwo: string[] = Object.keys(validateFormResult.stepTwo.invalidFields);

        invalidFields = invalidFields.concat(invalidFieldsOfStepOne)
        invalidFields = invalidFields.concat(invalidFieldsOfStepTwo)

        this.addSampleFormEventToDataLayer('formIsNotValid', invalidFields);

        this.isOrdering = false;
      }
    }
    else
    {
      const logInfo = {
        email: this.form.email,
        info: {
          failure: "Generic",
          honeyPot: this.form.honeyName,
          reverseField: this.form.reverseField,
        }
      }
      await cartApi.log(logInfo).catch(e => console.log(e));

      this.isOrdering = false;
      this.infoMessage = this.$t('sampleSelector.info.orderError').toString();
      this.addSampleFormEventToDataLayer('placingSampleOrderFailed');
    }
  }

  private async uuidToSampleId(uuid: string): Promise<null | string> {
    return productsApi.getFinishes(new Filter('@jcr:uuid', uuid), false).then(value => {
      const finish = value.data.results[0];
      const enabled = parseBool(finish.enabled);
      const inStock = parseBool(finish.inStock);
      if (!enabled || !inStock) {
        console.error('Failed to convert', {enabled, inStock});
        return null;
      }
      return finish.sampleId;
    }).catch(reason => {
      console.error(reason);
      return null;
    });
  }

  private formatDate(date: Date | string): string {
    let d: Date | string = date;
    if (typeof date === 'string') {
      d = new Date(date);
    }
    d = d as Date;
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  private onCountryChange(country: string) {
    this.country = country;
    if (country == "US") {
      this.rules.region[0] = {
        required: true,
        trigger: 'change',
      }
    } else {
      this.rules.region[0] = {
        required: false,
        trigger: 'change',
      }
    }
  }

  private mounted() {
    this.form.startDate = new Date().toString();
  }

  private addSampleFormEventToDataLayer(action: string, invalidFields: string[] = []) {
    const dataLayerItem = {
      'event': 'orderForm',
      'action': action
    }

    if (action === "formIsNotValid") {
      dataLayerItem["invalidFields"] = invalidFields
    }

    // @ts-ignore
    window.dataLayer?.push(dataLayerItem);
  }

  private toggleAccordionTriggeredHandler(isOpen, formStep) {
    if(isOpen) {
      this.addSampleFormEventToDataLayer(formStep === 'firstFormStep' ? 'showFirstFormStep' : 'showSecondFormStep');
    }
  }
}
