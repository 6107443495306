

















































































































import decorSetsApi from '@/api/decorSetsApi';
import {FilterHelpers} from '@/components/filter/FilterHelpers';
import SelectableImage from '@/components/global/SelectableImage.vue';
import PreviewImgListItem from '@/components/preview-img/PreviewImgListItem.vue';
import TrButton from '@/components/shared/TrButton.vue';
import SmoothReflow from '@/components/SmoothReflow.vue';
import {addFilter, hasFilter, removeFilter} from '@/store/productSearch';
import {ProductDeliveryEndpoint} from '@/typings/interfaces/ProductDeliveryEndpoint';
import {ActiveFilters} from '@/typings/types/ActiveFilters';
import {ImageHelpers} from '@/utils/imageHelpers';
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import {Options} from 'vue-smooth-reflow/types/vue-smooth-reflow';
import PersonalizationHelper from "@/utils/PersonalizationHelper";
import {CartMixin} from "@/utils/mixins/CartMixin";



type TypePreference = 'material' | 'colour' | '';

@Component({
  name: 'SampleSelectorWizard',
  components: {PreviewImgListItem, SelectableImage, SmoothReflow, TrButton},
})
export default class SampleSelectorWizard extends Mixins(ImageHelpers, FilterHelpers, CartMixin) {

  @Prop({default: false, type: Boolean}) public visibility!: boolean;
  private step = 1;
  private numOfSteps = 3;
  private typePreference: TypePreference = '';
  private smoothReflowOptions: Options = {
    hideOverflow: false,
    transitionEvent: {
      propertyName: 'opacity'
    }
  };

  private decorSetImages: { [p: string]: string } = {};
  private tempState: { activeFilters: ActiveFilters } = {
    activeFilters: {}
  };

  get nextBtnText() {
    return this.step === 3 ? this.$t('buttons.finish') : this.$t('buttons.next');
  }

  get backBtnDisabled() {
    return this.step <= 1;
  }

  get nextBtnDisabled() {
    if (this.step === 1 && this.typePreference) {
      return false;
    }
    if (this.step > 1) {
      return false;
    }
    return true;
  }

  get description() {
    return this.$t('sampleSelector.dialog.desc').toString()
        .replace("{0}", PersonalizationHelper.getConfigFromCookie().maxItems.toString());
  }

  tempStoreFilter(event: Event, name: string, value: string) {
    const target: EventTarget = event.target as EventTarget;
    const checked = (target as HTMLInputElement).checked || false;
    if (checked) {
      addFilter(this.tempState, {name, value});
    } else {
      removeFilter(this.tempState, {name, value});
    }
  }

  public decorSetImage(key: string) {
    return this.decorSetImages[key];
  }

  @Watch('step')
  onStepChange() {
    if (this.step === 1) {
      this.resetTempState();
    }
  }

  @Watch('visibility')
  onVisibilityChange() {
    if (this.visibility === true) {
      this.step = 1;
      this.typePreference = '';
      this.resetTempState();
    }
  }

  selectType(type: TypePreference) {
    this.typePreference = type;
  }

  public close(updateFilters: boolean = false) {
    if (updateFilters) {
      this.$store.dispatch('productSearch/replaceFilters', this.tempState.activeFilters);
      this.resetTempState();
    }
    this.$emit('close');
    setTimeout(() => {
      this.resetTempState();
    }, 200);
  }

  public nextStep() {
    if (this.step < this.numOfSteps) {
      this.step++;
    } else {
      this.close(true);
    }
  }

  public backStep() {
    if (this.step > 1) {
      this.step--;
    }
  }

  private mounted() {
    decorSetsApi.getDecorSets().then(response => {
      this.decorSetImages = response.data.results.reduce(
          (collector: { [p: string]: string }, currentValue: ProductDeliveryEndpoint.DecorSet) => {
            if (currentValue.image) {
              collector[currentValue['@name']] = currentValue.image.renditions['highlighted-thumbnail'].link;
            }
            return collector;
          }, {});
    });
  }

  private hasFilter(name: string, value: string) {
    return hasFilter(this.tempState, {name, value});
  }

  private resetTempState() {
    this.tempState = {
      activeFilters: {}
    };
  }

}
