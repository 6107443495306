// import { Vue, Component } from "vue-property-decorator";

import Vue from 'vue';
import Component from 'vue-class-component';
import requireAsset from '@/utils/requireAsset';

@Component
export class ImgUrlMixin extends Vue {
    getImgUrlMixin(imgUrl) {
        if (!imgUrl) return;
        return requireAsset(imgUrl);
    }
}
