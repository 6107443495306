export function getImageSize(imgSrc: string): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
        const img: HTMLImageElement = new Image();
        img.onload = () => resolve({
            width: img.width || 0,
            height: img.height || 0
        });
        img.onerror = reject;
        img.src = imgSrc;
    });
}
