


























import TrButton from '@/components/shared/TrButton.vue';
import {InspirationData} from '@/typings/interfaces/Misc';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {parseTextFields} from "@/utils/StringUtils";

@Component({
  name: 'Inspiration',
  components: {Swiper, SwiperSlide, TrButton},
})
export default class Inspiration extends Vue {
  @Prop({required: true}) readonly data!: InspirationData;

  get parsedData() {
    return parseTextFields(this.data);
  }

  optionsBg = {
    effect: 'fade',
    keyboard: {
      enabled: true
    },
    slideToClickedSlide: true,
    loop: true,
    autoplay: {
      delay: 2000,
    },
  };
}
