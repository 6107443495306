import api from '@/api/api';

export interface RecipientRequestField {
    Description: string;
    Id: string;
    Value: string;
}

export interface MailupForm {
    Name?: string;
    Email: string;
    MobileNumber?: string;
    MobilePrefix?: string;
    Fields?: Array<RecipientRequestField>;
}

export default {
    subscribe: (groupId: string | number, form: MailupForm) => {
        return api.post(`mailup/v1/recipients/${groupId}`, form);
    }
};
