























import {EmbedConfig} from '@/utils/embed-configs/embedConfig';
import {createEmbedConfig} from '@/utils/embed-configs/embedConfigFactory';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class VideoComponent extends Vue {
  @Prop({}) private url?: string | undefined;
  @Prop({default: 'black'}) private buttonColor?: string;
  @Prop({default: ''}) private uuid?: string;
  @Prop({default: ''}) private poster?: string;
  @Prop({default: ''}) private poster2x?: string;
  @Prop({default: ''}) private poster3x?: string;
  @Prop({default: true}) private fallback?: boolean;
  @Prop({default: '16by9'}) private videoRatio?: string;
  private thumbUrl?: string = '';
  private isPlaying: boolean = false;
  private embed: EmbedConfig | null = null;

  get posterSrcSet() {
    let value = '';

    if (this.poster3x && this.poster3x.length > 0) {
      value += `${this.poster3x} 3x,`;
    }
    if (this.poster2x && this.poster2x.length > 0) {
      value += `${this.poster2x} 3x,`;
    }
    if (this.poster && this.poster.length > 0) {
      value += `${this.poster} 1x,`;
    }
    return value;
  }

  get posterSrc() {
    return this.poster || this.thumbUrl;
  }

  private mounted() {
    if (!this.url) {
      return;
    }
    this.embed = createEmbedConfig(this.url);
    if (!this.embed) {
      return;
    }
    this.setEmbedThumbAsVideoPoster();

    const embedTarget = this.$el.querySelector<HTMLElement>('.embed-responsive-item');

    (this.embed as EmbedConfig).on('play', () => {
      this.playHandler();
    });

    (this.embed as EmbedConfig).on('pause', () => {
      this.pauseHandler();
    });

    (this.embed as EmbedConfig).initPlayer(embedTarget as HTMLElement);
  }

  private setEmbedThumbAsVideoPoster() {
    if (this.fallback) {
      this.embed?.getThumb().then((thumbUrl) => {
        if (thumbUrl) {
          this.thumbUrl = thumbUrl;
        }
      });
    }
  }

  private posterClick(e: Event) {
    e.preventDefault();
    this.embed?.play();
    this.playHandler();
  }

  private isNotCurrentEmbedConfig(e: EmbedConfig) {
    return e !== this.embed;
  }

  private playHandler() {
    this.isPlaying = true;
  }

  private pauseHandler() {
    this.isPlaying = false;
  }

}
