export default function (s: string | number | boolean): null | boolean {
    if (typeof s === 'boolean') {
        return s;
    }
    if (typeof s === 'number') {
        if (s === 0) return false;
        if (s === 1) return true;
        return null;
    }
    s = s.toLowerCase();
    if (s === 'yes' || s === 'on' || s === 'true' || s === '1') return true;
    if (s === 'no' || s === 'off' || s === 'false' || s === '0') return false;
    return null;
}
