































import DocumentationLink from '@/components/DocumentationLink.vue';
import SmoothReflow from '@/components/SmoothReflow.vue';
import lineDrip from '@/directives/lineDrip';
import {
  DocumentationTab,
  DocumentationTabsModel,
  ParsedDocumentationTab,
  ParsedDocumentationTabEntry,
  TabList,
} from '@/models/documentationTabs';
import {LinkInterceptionMixin} from '@/utils/mixins/LinkInterceptionMixin';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {Options} from 'vue-smooth-reflow/types/vue-smooth-reflow';

@Component({name: 'DocumentationTabs', components: {DocumentationLink, SmoothReflow}, directives: {lineDrip}})
export default class DocumentationTabs extends mixins(LinkInterceptionMixin) {
  @Prop() readonly data: DocumentationTabsModel | undefined;
  private smoothReflowOptions: Options = {
    hideOverflow: false,
    transitionEvent: {
      selector: '.doc',
      propertyName: 'opacity'
    }
  };
  private selectedContent: TabList | null = null;
  private activeTab = 0;

  get currentTab(): ParsedDocumentationTab {
    return this.filteredTabs[this.activeTab];
  }

  get filteredTabs(): Array<ParsedDocumentationTab> {
    const parsedDocumentationTabs: Array<ParsedDocumentationTab> = [];
    if (this.data) {
      this.data.docs.forEach((documentationTab: DocumentationTab) => {
        const foundDoc = this.findDoc(parsedDocumentationTabs, documentationTab);
        if (!foundDoc) {
          const parsedDocumentationTab: ParsedDocumentationTab = {
            tabName: documentationTab.docType,
            tabDisplayName: this.$t(`documentationTabs.tab.${documentationTab.docType}`),
            tabList: []
          };
          parsedDocumentationTabs.push(parsedDocumentationTab);
        }
        const documentationTabEntry: ParsedDocumentationTabEntry = {
          docName: documentationTab.docName,
          link: documentationTab.link,
          linkRel: documentationTab.linkRel,
          linkTarget: documentationTab.linkTarget,
          thumbnail: documentationTab.thumbnail,
          linkType: documentationTab.linkType
        };
        (this.findDoc(parsedDocumentationTabs, documentationTab) as ParsedDocumentationTab).tabList.push(
            documentationTabEntry);
      });
    }

    return parsedDocumentationTabs;
  }

  private mounted() {
    this.selectedContent = this.filteredTabs[0].tabList;
  }

  private tabClick(index) {
    this.selectedContent = this.filteredTabs[index].tabList;
    this.activeTab = index;
  }

  private findDoc(docs: ParsedDocumentationTab[], documentationTab: DocumentationTab): ParsedDocumentationTab | undefined {
    return docs.find((parsed: ParsedDocumentationTab) => parsed.tabName === documentationTab.docType);
  }
}
