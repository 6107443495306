






















import IconSearch from '@/assets/svg/icons/icon-search.svg?inline';
import IconCancel from '@/assets/svg/icons/icon-cancel.svg?inline';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {IconSearch, IconCancel},
})
export default class NavigationSearch extends Vue {
  @Prop({required: true, default: "#"}) link!: string;
  private searchValue: string = '';
  private showSearch: boolean = false;

  mounted() {
    const initParams = new URLSearchParams(window.location.search);
    this.searchValue = initParams.get('q') || '';
  }

  private toggleSearchPanel(show) {
    this.showSearch = show;
    (this.$refs.searchInput as HTMLFormElement).focus();
  }

  private goToSearchPage() {
    if (this.searchValue) {
      window.location.href = this.link + '?q=' + encodeURIComponent(this.searchValue);
    }
  }
}
