


















import TrButton from '@/components/shared/TrButton.vue';
import TrModal from '@/components/shared/TrModal.vue';
import {LinkInterceptionMixin} from '@/utils/mixins/LinkInterceptionMixin';
import {mixins} from 'vue-class-component';
import {Component} from 'vue-property-decorator';

@Component({
  components: {TrModal, TrButton},
})
export default class LinkInterceptionComponent extends mixins(LinkInterceptionMixin) {

  private accepted = false;

  get href() {
    return this.getAttribute('data-href');
  }
  get rel() {
    return this.getAttribute('data-rel');
  }
  get target() {
    return this.getAttribute('data-target');
  }
  get thumbnail() {
    return (this.interceptedEvent.target as HTMLElement).getAttribute('data-thumbnail') || null;
  }
  get title() {
    return (this.interceptedEvent.target as HTMLElement).textContent || null;
  }

  private getAttribute(name: string): string | null {
    return (this.interceptedEvent.target as HTMLElement).getAttribute(name);
  }

  go() {
    window.open(this.href as string, this.target as string, this.rel as string);
  }

  close() {
    this.interceptedEvent = undefined as unknown as MouseEvent;
  }
}
