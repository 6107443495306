























import IconCancel from '@/assets/svg/icons/icon-cancel.svg?inline';
import {toggleBodyOverflow} from '@/utils/helpers';
import {Component, Prop, Vue} from 'vue-property-decorator';

let instanceCount = 0;

@Component({name: 'TrModal', components: {IconCancel}})
export default class TrModal extends Vue {
  @Prop({default: false}) private small!: boolean;
  @Prop({default: false}) private width90!: boolean
  @Prop({default: false}) private alertInfo!: boolean;
  @Prop({default: false}) private hideCloseCross!: boolean;
  @Prop({default: false}) private compact!: boolean
  @Prop() private modalWidth!: string;
  @Prop() private cssClass!: string | undefined;

  private instanceId!: number;

  get order() {
    return this.instanceId;
  }

  get instanceKey(): string {
    return `tr-modal-${this.instanceId}`;
  }

  beforeCreate() {
    instanceCount += 1;
    this.instanceId = instanceCount;
  }

  mounted() {
    toggleBodyOverflow(true);
  }

  destroyed() {
    toggleBodyOverflow(false);
  }

  close() {
    this.$emit('close');
  }
}
