




















































import LocationCard from '@/components/global/LocationCard.vue';
import SalesCard from '@/components/global/SalesCard.vue';
import TrButton from '@/components/shared/TrButton.vue';
import TrModal from '@/components/shared/TrModal.vue';
import {SalesContact} from '@/models/dealers';
import {Category} from '@/typings/interfaces/Misc';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'SalesSearch', components: {
    SalesCard,
    LocationCard,
    TrModal,
    TrButton
  }
})
export default class SalesSearch extends Vue {
  @Prop({required: true}) readonly countries!: Array<Category>;
  @Prop({required: true}) readonly productLines!: Array<any>;
  @Prop({default: ''}) readonly title?: string;

  private country: string = '';
  private productLine: string = '';
  private isModalOpen: boolean = false;
  private resultsTextLabel: string = '';

  private get results(): Array<SalesContact> {
    return this.$store.getters['salesContacts/results'];
  }

  private get resultsCount(): number {
    if (!this.results) return 0;
    return this.results.length || 0;
  }

  private search(): void {
    this.$store.dispatch('salesContacts/setCountry', this.country);
    this.$store.dispatch('salesContacts/setProductLine', this.productLine);
    this.resultsTextLabel = this.resultsCount === 1 ? 'salesSearch.modal.resultText' :
        'salesSearch.modal.resultsText';
    this.isModalOpen = true;
  }
}
