






























import {Component, Mixins, Prop, Ref, Watch} from 'vue-property-decorator';
import { FilterHelpers } from '@/components/filter/FilterHelpers';
import SelectableImage from '@/components/global/SelectableImage.vue';
import PreviewImgListItem from '@/components/preview-img/PreviewImgListItem.vue';
import TrButton from '@/components/shared/TrButton.vue';
import { ImageHelpers } from '@/utils/imageHelpers';
import ChevronDown from '@/assets/svg/icons/icon-chevron-down.svg?inline';
import QuestionMark from '@/assets/svg/icons/icon-question-mark.svg?inline';
import VueI18n from 'vue-i18n';
import TrModal from '@/components/shared/TrModal.vue';
import SampleSelectorWizard from '@/components/global/SampleSelectorWizard.vue';
import TrSidePanel from '@/components/side-panel/TrSidePanel.vue';
import { BreakPoint, mqMaxWidth } from '@/utils/mq';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'FilterDropdown',
  components: {
    SampleSelectorWizard,
    TrModal,
    SelectableImage,
    PreviewImgListItem,
    TrButton,
    ChevronDown,
    QuestionMark,
    TrSidePanel,
  },
})
export default class FilterDropdown extends Mixins(ImageHelpers, FilterHelpers) {
  @Prop() private label: VueI18n.TranslateResult | undefined;

  private isShowDropdown: boolean = false;
  private isShowSidePanel: boolean = false;
  private isShowModal: boolean = false;
  private showMobileFilters: boolean = false;
  private numberOfCheckedCheckboxes: number = 0;

  beforeMount() {
    // Conditionally determine the visibility of mobile filters.
    const mq = mqMaxWidth(BreakPoint.LG);
    this.showMobileFilters = mq.matches;
    mq.addEventListener('change', (ev: MediaQueryListEvent) => {
      this.showMobileFilters = ev.matches;
    });
  }

  mounted() {
    // Automatically closes any open dropdown when a new one is clicked to open.
    document.addEventListener('click', (event: Event) => {
      !this.$el.contains(event.target as Node | null) && this.setShowDropdown(false);
    });

    // Close everything if window is resized
    window.addEventListener('resize', () => {
      this.setShowDropdown(false);
      this.setShowSidePanel(false);
      this.setShowModal(false);
    });

    setInterval(()=>{
      this.updateDropdownActiveIndicator();
    }, 200)
  }

  toggle() {
    if (this.showMobileFilters) {
      this.setShowModal(true);
    } else {
      this.isShowDropdown ? this.setShowDropdown(false) : this.setShowDropdown(true);
    }
  }

  setShowDropdown(isShow: boolean) {
    this.isShowDropdown = isShow;
  }

  setShowModal(isShow: boolean) {
    this.isShowModal = isShow;
  }

  setShowSidePanel(isShow: boolean) {
    this.setShowDropdown(false);
    this.isShowSidePanel = isShow;
  }

  hasSidePanelContent() {
    return !!this.$slots['side-panel-content'];
  }

  public reset() {
    this.numberOfCheckedCheckboxes = 0;
  }

  updateDropdownActiveIndicator() {
    this.numberOfCheckedCheckboxes = this.$el.querySelectorAll('[type="checkbox"]:checked, [type="radio"]:checked').length;
  }
}
