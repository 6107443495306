






























import IconArrowRight from '@/assets/svg/icons/icon-arrow-right.svg?inline';
import IconDownload from '@/assets/svg/icons/icon-download.svg?inline';
import DocumentationLink from '@/components/DocumentationLink.vue';
import {LinkInterceptionMixin} from '@/utils/mixins/LinkInterceptionMixin';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {parseTextFields} from "@/utils/StringUtils";

@Component({name: 'ProductOverviewDesc', components: {DocumentationLink, IconArrowRight, IconDownload}})
export default class ProductOverviewDesc extends mixins(LinkInterceptionMixin) {
  @Prop() readonly data;
  @Prop({}) private technicalDocumentationLabel!: string;
  @Prop({}) private pageLinkLabel!: string;

  get parsedData() {
    return parseTextFields(this.data);
  }

  get isEmptyData() {
    return !this.data.productsTitle && !this.data.products;
  }
  get hasDocs() {
    return this.data.documentation && this.data.documentation.length > 0;
  }
}
