






























import Pagination from '@/components/global/Pagination.vue';
import * as ElasticSearch from '@/typings/interfaces/ElasticSearch';
import * as WebsiteSearch from '@/typings/interfaces/WebsiteSearch';
import axios from 'axios';
import {Component, Vue, Watch} from 'vue-property-decorator';

@Component({
  name: 'SearchResults',
  components: {
    Pagination
  }
})
export default class SearchResults extends Vue {
  private static RESULTS_PER_PAGE = 'rpp';
  private static CURRENT_PAGE = 'p';
  private static QUERY = 'q';
  private static LANGUAGE = 'lang';

  private response: ElasticSearch.Response<WebsiteSearch.Source> | null = null;

  private params: URLSearchParams = new URLSearchParams();
  private itemsPerPage: number = 10;
  private currentPage: number = 1;
  private q: string = '';

  get responseTotal() {
    if (this.response) {
      return this.response.hits.total.value;
    }

    return 0;
  }

  private get contextPath() {
    return _mgnl.contextPath;
  }

  private get pages() {
    if (this.response && this.response.hits && this.response.hits.hits) {
      return this.response.hits.hits.map((item: ElasticSearch.Hit<WebsiteSearch.Source>) => item._source);
    }
    return [];
  }

  private mounted() {
    this.initParams();
    this.search();
  }

  private initParams() {
    this.params.set(SearchResults.LANGUAGE, (_mgnl.language || 'en').replace('_', '-'));

    const initParams = new URLSearchParams(window.location.search);
    if (initParams.get(SearchResults.CURRENT_PAGE) === null) {
      this.params.set(SearchResults.CURRENT_PAGE, this.currentPage.toString());
    } else {
      this.params.set(SearchResults.CURRENT_PAGE, initParams.get(SearchResults.CURRENT_PAGE) || '1');
      this.currentPage = parseInt(this.params.get(SearchResults.CURRENT_PAGE) || '1', 10);
    }
    this.params.set(SearchResults.RESULTS_PER_PAGE, this.itemsPerPage.toString());
    if (initParams.get(SearchResults.QUERY) !== null) {
      this.params.set(SearchResults.QUERY, initParams.get(SearchResults.QUERY) || '');
    }
  }

  private search() {
    if (!_mgnl.editMode) {
      window.history.replaceState({}, '', `${location.pathname}?${this.params}`);
    }

    axios.get(`${_mgnl.contextPath}/.rest/search/v1/search/website`, {
      params: {
        lang: this.params.get(SearchResults.LANGUAGE),
        p: this.params.get(SearchResults.CURRENT_PAGE),
        rpp: this.params.get(SearchResults.RESULTS_PER_PAGE),
        q: this.params.get(SearchResults.QUERY),
      },
    }).then((res) => {
      this.response = res.data;
    });
  }

  @Watch('currentPage')
  private onCurrentPageChanged(val: string) {
    this.params.set(SearchResults.CURRENT_PAGE, val);
    this.search();
  }
}
