























import {Component, Prop, Vue} from 'vue-property-decorator';
import TrButton from "@/components/shared/TrButton.vue";
import VueI18n from "vue-i18n";

@Component({
  components: {
    TrButton
  },
})
export default class SelectableImage extends Vue {
  @Prop({required: true}) src!: string;
  @Prop({required: true}) label!: string | VueI18n.TranslateResult;
  @Prop({default: 'none'}) force!: '1x1' | '2x1' | '3x2' | '4x3' | '16x9' | 'none';
  @Prop({default: 'checkbox'}) type!: string;
  @Prop({default: ''}) name!: string;
  @Prop({default: false}) value!: boolean;
  @Prop({default: false}) faded!: boolean;
  @Prop({default: false}) hideImagesOnMobile!: boolean;

  get key() {
    return this.$vnode.key;
  }

}
