import documentLanguage from '@/utils/documentLanguage';
import axios from 'axios';

const docLang = documentLanguage();

axios.defaults.params = {};
axios.defaults.params['lang'] = docLang;

export default axios.create({
    baseURL: `${_mgnl.contextPath}/.rest/`,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
})
