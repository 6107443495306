import dealersApi from '@/api/dealersApi';
import salesContactsApi from '@/api/salesContactsApi';
import {StoreModules} from '@/store';
import _ from 'lodash';
import {Module} from 'vuex';

export interface DealersState {
    results?: any;
    lastResultsUpdate: number;
    country: string;
    productLine: string;
}

const module: Module<DealersState, StoreModules> = {
    namespaced: true
};

module.state = {
    results: undefined,
    lastResultsUpdate: 0,
    country: '',
    productLine: ''
};

module.getters = {
    results: state => state.results,
};

module.actions = {
    performSearch: ({commit, state}) => {
        return new Promise<void>((resolve, reject) => {
            dealersApi.performSearchWithFilters(state.country, state.productLine)
                .then((value) => {
                    const data = value.data;
                    commit('setResults', data);
                    resolve();
                }).catch(reject);
        });
    },
    debouncedSearch: _.debounce(({dispatch}) => dispatch('performSearch'), 500),
    setCountry({dispatch, commit}, payload: string) {
        commit('setCountry', payload);
        return dispatch('debouncedSearch');
    },
    setProductLine({dispatch, commit}, payload: string) {
        commit('setProductLine', payload);
        return dispatch('debouncedSearch');
    },
};

module.mutations = {
    setResults(state, data: google.maps.GeocoderResult) {
        state.results = data;
        state.lastResultsUpdate = Date.now();
    },
    setCountry(state, payload: string) {
        state.country = payload;
    },
    setProductLine(state, payload: string) {
        state.productLine = payload;
    },
};

export default module;
