import {StoreModules} from '@/store';
import * as InspirationSearch from '@/typings/interfaces/InspirationSearch';
import {InspirationFilters} from '@/typings/types/InpirationFilters';
import _ from 'lodash';
import {Module} from 'vuex';

export type NameValue = { name: string, value: string };

export const inspirationFilterCodes = {
    "productLine.name":     "p1",
    "colourTints":          "p2",
    "buildType.name":       "p3",
    "decorSet.name":        "p4",
    "marketSegment.name":   "p5"
};

export interface InspirationState {
    activeFilters: InspirationFilters;
    results?: InspirationSearch.Response;
    lastResultsUpdate: number;
    filtersOpen: boolean;
    currentUuid?: string;
    // Ready becomes true on first successful load
    ready: boolean;
    fullSet: boolean;
    setSlice: {
        first: number;
        end: number;
        pointer: number;
    }
}

const module: Module<InspirationState, StoreModules> = {
    namespaced: true
};

module.state = {
    activeFilters: {
        uuid: '',
        padding: 7,
    },
    results: undefined,
    lastResultsUpdate: 0,
    filtersOpen: false,
    currentUuid: undefined,
    ready: false,
    fullSet: false,
    setSlice: {
        first: 0,
        end: 0,
        pointer: 0
    }
};

module.getters = {
    results:            state => state.results,
    lastResultsUpdate:  state => state.lastResultsUpdate,
    activeFilters:      state => state.activeFilters,
    filtersOpen:        state => state.filtersOpen,
    currentUuid:        state => state.currentUuid,
    ready:              state => state.ready,
    resultList:         state => {
        return state.results
    },
    resultPointer:      state => {
        if (!state.results?.fullSet)
            return state.activeFilters.padding;
        return state.setSlice.pointer;
    }
};


module.actions = {
    // performSearch: ({dispatch, commit, state}) => {
    //     return new Promise<void>((resolve, reject) => {
    //         inspirationApi.performSearchWithFilters(state.activeFilters)
    //             .then((value) => {
    //                 const data = value.data;
    //                 commit('setResults', data);
    //                 if (data.fullSet)
    //                     dispatch('recenterSlides', 0);
    //                 resolve();
    //             }).catch(reject);
    //     });
    // },
    debouncedSearch: _.debounce(({dispatch}) => dispatch('performSearch'), 300),
    addFilter({dispatch, commit}, {name, value}: NameValue) {
        commit('addFilter', {name, value});
        return dispatch('debouncedSearch');
    },
    setFilter({dispatch, commit}, {name, value}: NameValue) {
        commit('setFilter', {name, value});
    },
    removeFilter({dispatch, commit}, {name, value}: NameValue) {
        commit('removeFilter', {name, value});
    },
    setQuery({dispatch, commit}, query: string) {
        commit('setQuery', query);
    },
    setFiltersOpen({commit}, open: boolean) {
        commit('setFiltersOpen', open);
    },
    setCurrentUuid({commit}, uuid: string) {
        commit('setCurrentUuid', uuid);
    },
    setPadding({commit}, padding: number) {
        commit('setPadding', padding);
    },
    changeSlides({commit, state, dispatch}, swiperIndex: number) {
        if (state.fullSet) {
            // Just check if we need to re-request new pages
            // TODO See inspirationHeader.vue
        } else {
            const absoluteIndex = swiperIndex + state.setSlice.first;
            const len = state.results?.uuids.length || 0;
            const lastSliceIndex = state.setSlice.first + state.setSlice.pointer + state.activeFilters.padding + 1;

            if ((absoluteIndex <= state.setSlice.first + 2 && state.setSlice.first != 0)
                || (absoluteIndex >= lastSliceIndex - 2 && state.setSlice.end != len)) {
                dispatch('recenterSlides', absoluteIndex);
            }
        }
    },
    changeToSlide({commit, state, dispatch}, uuid: string) {
        const uuidIndex = state.results?.uuids.indexOf(uuid);
        if (uuidIndex === undefined || uuidIndex === -1)
            return;
        dispatch('recenterSlides', uuidIndex);
    },
    recenterSlides({commit, state}, focusedIndex) {
        const sliceStart = Math.max(focusedIndex - state.activeFilters.padding, 0);
        const setLength: number = state.results?.uuids.length || 0;
        const sliceEnd = Math.min(focusedIndex + state.activeFilters.padding + 1, setLength);
        commit('setSetSlice', {
            first: sliceStart,
            end: sliceEnd,
            pointer: focusedIndex - sliceStart
        });
    }
};

module.mutations = {
    setResults(state, data: InspirationSearch.Response) {
        state.ready = true;
        state.lastResultsUpdate = Date.now();
        state.results = data;
        if (!data.fullSet) {
            state.setSlice = {
                first: 0,
                end: 0,
                pointer: state.activeFilters.padding,
            }
        } else {
            state.setSlice = {
                first: 0,
                end: Math.min(state.activeFilters.padding + 1, data.uuids.length),
                pointer: 0
            }
        }
    },
    setSetSlice(state, sliceData) {
        state.setSlice = sliceData;
    },
    addFilter(state, {name, value}: NameValue) {
        if (!Array.isArray(state.activeFilters[name])) {
            state.activeFilters[name] = [];
        }
        (state.activeFilters[name] as string[]).push(value);
    },
    setFilter(state, {name, value}: NameValue) {
        state.activeFilters[name] = value;
    },
    removeFilter(state, {name, value}: NameValue) {
        if (Array.isArray(state.activeFilters[name])) {
            state.activeFilters[name] = (state.activeFilters[name] as string[]).filter(v => value != v);
        } else {
            delete state.activeFilters[name];
        }
    },
    setQuery(state, query: string = '') {
        query = query.trim();
        state.activeFilters.q = query;
    },
    setFiltersOpen(state, open: boolean) {
        state.filtersOpen = open;
    },
    setCurrentUuid(state, uuid: string) {
        state.currentUuid = uuid;
        state.activeFilters.uuid = uuid;
    },
    setPadding(state, padding: number) {
        state.activeFilters.padding = padding;
    }
};

export default module;

