import api from '@/api/api';
import {Filter, parseFilters} from '@/api/DeliveryEndpoint';
import {ProductDeliveryEndpoint} from '@/typings/interfaces/ProductDeliveryEndpoint';
import {AxiosResponse} from 'axios';
import {ISetupCache, setupCache} from 'axios-cache-adapter';
import qs from 'qs';
import Finish = ProductDeliveryEndpoint.Finish;
import Results = ProductDeliveryEndpoint.Results;


const cache: ISetupCache = setupCache({
    maxAge: 5000,
    exclude: {
        query: false
    }
});


const methods = {
    getFinishes(filters: Filter | Filter[], cached: boolean = true): Promise<AxiosResponse<Results<Finish>>> {
        return api.get<Results<Finish>>('finishes/v1/', {
            params: parseFilters(filters),
            adapter: cached ? cache.adapter: undefined,
            paramsSerializer: params => qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        });
    }
};
export default methods;
