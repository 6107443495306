














import SampleListItem from '@/components/sample-selector/SampleListItem.vue';
import * as ElasticSearch from '@/typings/interfaces/ElasticSearch';
import * as ProductSearch from '@/typings/interfaces/ProductSearch';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'SampleList',
  components: {SampleListItem},
})
export default class SampleList extends Vue {

  get hits(): ElasticSearch.Hit<ProductSearch.Source>[] | undefined {
    return this.results?.hits?.hits;
  }

  get hasResults(): boolean {
    if (this.results) {
      return this.results?.hits?.hits.length > 0;
    }

    return true;
  }

  get results(): ProductSearch.Response {
    return this.$store.getters['productSearch/results'];
  }

}
