import * as ElasticSearch from '@/typings/interfaces/ElasticSearch';
import * as ProductSearch from '@/typings/interfaces/ProductSearch';
import Vue from 'vue';
import Component from 'vue-class-component';
import Bucket = ElasticSearch.Bucket;

export type AggregationsAsFilters = Omit<ProductSearch.ProductSearchAggregations, 'doc_count'>;
export type BucketTuple = [string, string];

@Component
export class FilterHelpers extends Vue {

    get results(): ProductSearch.Response {
        return this.$store.getters['productSearch/results'];
    }

    get aggregations(): ProductSearch.ProductSearchAggregations {
        return this.results?.aggregations?.unfiltered;
    }

    get filters(): AggregationsAsFilters {
        const clone: ProductSearch.ProductSearchAggregations = Object.assign({}, this.aggregations);
        delete (clone as Partial<ProductSearch.ProductSearchAggregations>).doc_count;
        return clone;
    }

    get sortedTypes() {
        const filters: AggregationsAsFilters = this.filters;
        const productRules: ProductSearch.ProductRulesAggregation = filters.productRules as ProductSearch.ProductRulesAggregation;
        const productRuleType = productRules['productRules.type'];
        const buckets: Array<Bucket> = productRuleType.buckets;
        const clone = Array.from(buckets);
        clone.sort((a: Bucket, b: Bucket) => {
            if (a.key.toLowerCase().startsWith('single') && !b.key.toLowerCase().startsWith('single') ||
                a.key.toLowerCase().startsWith('double') && !b.key.toLowerCase().startsWith('double')) {
                return -1;
            }
            if (a.key.toLowerCase().startsWith('single') && b.key.toLowerCase().startsWith('single') ||
                a.key.toLowerCase().startsWith('double') && b.key.toLowerCase().startsWith('double')) {
                return 0;
            }
            return 0;
        });
        return clone;
    }

    isActiveFilter(name: string, value: string): boolean {
        return this.$store.getters['productSearch/hasFilter'](name, value);
    }

    updateFilters(event: Event, name: string, value: string) {
        const target: EventTarget = event.target as EventTarget;
        const checked = (target as HTMLInputElement).checked || false;
        if (checked) {
            this.$store.dispatch('productSearch/addFilter', {name, value});
        } else {
            this.$store.dispatch('productSearch/removeFilter', {name, value});
        }
    }

    public splitMultiTermTuple(tuple: BucketTuple): { key: string; display: string } {
        return {
            key: tuple[0],
            display: tuple[1]
        };
    }

}
