












import ChevronDown from '@/assets/svg/icons/icon-chevron-down.svg?inline';
import TopTaskIcon from '@/components/top-task/TopTaskIcon.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'TopTask',
  components: {
    ChevronDown,
    TopTaskIcon,
  },
})
export default class TopTask extends Vue {
  @Prop({
    required: true,
  })
  readonly data!: any;

  get target() : string {
    if ("target" in this.data && this.data.target != null) {
      return this.data.target;
    }
    return "_self";
  }
}
