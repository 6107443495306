



import {Component, Prop, Vue} from 'vue-property-decorator';

let customImgNames: Array<string> = [];

// get all names from icon list
const imgNames = require.context('@/assets/svg/icons', true);

imgNames.keys().forEach(function (fileName) {
  customImgNames.push(fileName.replace(/\.\//g, ''));
});

@Component
export default class TopTaskIcon extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  readonly imgName!: string;

  get currentSvg() {
    // TODO: see if this is needed, default icon
    return customImgNames.includes(this.imgName)
        ? () => import(`@/assets/svg/icons/${this.imgName}?inline`)
        : () => import('@/assets/svg/icons/icon-chevron-right.svg?inline');
  }
}
