import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class LinkInterceptionMixin extends Vue {

    get interceptedEvent(): MouseEvent {
        return this.$store.getters['shared/interceptedEvent'];
    }
    set interceptedEvent(event: MouseEvent) {
        this.$store.dispatch('shared/interceptedEvent', event);
    }

    public interceptLink(e: MouseEvent) {
        this.interceptedEvent = e;
    }
}
