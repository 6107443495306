











import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { ImageHelpers } from '@/utils/imageHelpers';
import { FinishType } from '@/typings/enums/FinishType';

@Component({ name: 'SidePanelFinish' })
export default class SidePanelFinish extends Mixins(ImageHelpers) {
  @Prop() finishes: FinishType[] | undefined;

  beforeMount() {
    const order = [FinishType.MATT, FinishType.MATT_ROCK, FinishType.ROCK, FinishType.SATIN, FinishType.DIFFUSE, FinishType.OBLIQUE, FinishType.SPECULAR, FinishType.CRYSTAL_MATT];

    this.finishes!.sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b);
    });
  }
}
