




























import IconUserCircle from '@/assets/svg/icons/icon-user-circle.svg?inline';
import SelectableImage from '@/components/global/SelectableImage.vue';
import TrModal from '@/components/shared/TrModal.vue';
import PersonalizationHelper from '@/utils/PersonalizationHelper';
import {SplashScreenOption} from '@/typings/interfaces/splash-screen';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {TrModal, IconUserCircle, SelectableImage},
})
export default class UserTypeSelector extends Vue {
  @Prop({required: true, default: []}) private userTypes!: SplashScreenOption[];
  @Prop({default: ''}) private title!: string;
  private showModal: boolean = !PersonalizationHelper.isPersonalizationActive();

  get currentUserType(): SplashScreenOption | undefined {
    return this.userTypes.find((value, index) => {
      return this.activeByIndex(index);
    });
  }

  isProfileActive(index): boolean {
    if (PersonalizationHelper.isPersonalizationActive()) {
      return PersonalizationHelper.isActiveFromIndex(index);
    }

    return false;
  }

  activeByIndex(index): boolean {
    return PersonalizationHelper.isActiveFromIndex(index);
  }

  setShowModal(value: boolean) {
    // Fallback to default if model is closed without a choice
    if (!PersonalizationHelper.isPersonalizationActive()) {
      PersonalizationHelper.setDefaultConfig();
    }

    this.showModal = value;
  }

  private updateCookie(index: number) {
    if (this.activeByIndex(index)) {
      this.setShowModal(false);
      return;
    }
    PersonalizationHelper.setCookieFromIndex(index);
    location.reload();
  }

}
