
























































































































































































































import IconCheck from '@/assets/svg/icons/check.svg?inline';
import IconCancel from '@/assets/svg/icons/icon-cancel.svg?inline';
import IconDownload from '@/assets/svg/icons/icon-download.svg?inline';
import IconSliders from '@/assets/svg/icons/icon-sliders.svg?inline';
import DocumentationLink from '@/components/DocumentationLink.vue';
import SelectableImage from '@/components/global/SelectableImage.vue';
import AccordionWrap from '@/components/global/AccordionWrap.vue';
import TrButton from '@/components/shared/TrButton.vue';
import TrModal from '@/components/shared/TrModal.vue';
import TrTooltip from '@/components/shared/TrTooltip.vue';
import SmoothReflow from '@/components/SmoothReflow.vue';
import { ParsedDocumentationTabEntry } from '@/models/documentationTabs';
import { LinkInterceptionMixin } from '@/utils/mixins/LinkInterceptionMixin';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { Options } from 'vue-smooth-reflow/types/vue-smooth-reflow';
import { parseTextFieldsArr } from '@/utils/StringUtils';
import { OrderableItem } from '@/typings/types/OrderableItem';
import { CartMixin } from '@/utils/mixins/CartMixin';
import CartLimitReachedModal from '@/components/sample-selector/CartLimitReachedModal.vue';
import CartInfoModal from '@/components/sample-selector/CartInfoModal.vue';

export interface Rule {
  name: string;
  grade: string[];
  panelSize: string[];
  thickness: string[];
  type: string[];
}

export interface Decor {
  id: string;
  name: string;
  code: string;
  imgUrl: string;
  thumbnailImage: string;
  architectImage: string;
  status:string;
  finishes: Finish[];
  rules: Rule[];
}

interface Finish {
  id: string;
  sampleId: string;
  uuid: string;
  name: string;
  imgUrl: string;
  thumbnailImage: string;
  architectImage?: string;
  rules: any[];
}

export interface TechnicalDocument {
  docName: string;
  docType: string;
  link: string;
  linkTarget: string;
  linkRel: string;
  thumbnail: string;
}

export interface DecorSetData {
  title: string;
  titleHeading: string;
  description: string;
  decors: Decor[];
  rules: Rule[];
  technicalDocuments: TechnicalDocument[];
}

@Component({
  name: 'DecorSet',
  components: {
    CartInfoModal,
    DocumentationLink,
    SelectableImage,
    AccordionWrap,
    TrTooltip,
    TrButton,
    IconDownload,
    IconSliders,
    IconCancel,
    SmoothReflow,
    TrModal,
    IconCheck,
    CartLimitReachedModal,
  },
})
export default class DecorSet extends mixins(LinkInterceptionMixin, CartMixin) {
  @Prop({}) private decorSet!: DecorSetData;
  @Prop({}) private titleHeading!: string;
  @Prop({}) private colorSelectLabel!: string;
  @Prop({}) private finishSelectLabel!: string;
  @Prop({}) private orderButtonLabel!: string;
  @Prop({}) private specificationLabel!: string;
  @Prop({}) private technicalDocumentationLabel!: string;
  @Prop({}) private selectColorAndFinishLabel!: string;

  private renderComponent: boolean = true;

  private smoothReflowOptions: Options = {
    hideOverflow: false,
    transitionEvent: {
      propertyName: 'opacity'
    }
  };

  private openModal = false;
  private selectedDecorIndex = 0;
  private selectedFinishIndex = 0;
  private addingSample: boolean = false;
  private showCartLimitReachedModal: boolean = false;
  private showInfoModal: boolean = false;

  get uniqueKey() {
    return this.$vnode.key;
  }

  get statusText() {
    if (this.selectedDecor.status === 'phased-out')
      return 'Phasing out';
    if (this.selectedDecor.status === 'new')
      return this.$t("decors.select.status.new");
    return null;
  }

  get statusTextCenter() {
    if (this.selectedDecor.status === 'new')
      return true;
    return false;
  }

  get selectedDecor(): Decor {
    return this.decors[this.selectedDecorIndex];
  }

  get selectedFinish(): Finish {
    return this.finishes[this.selectedFinishIndex];
  }

  get decors(): Decor[] {
    return parseTextFieldsArr(this.decorSet.decors);
  }

  get finishes(): Finish[] {
    return parseTextFieldsArr(this.selectedDecor.finishes.filter(finish => finish.sampleId.length > 1));
  }

  get specs(): { size: string; thickness: string; grade: string; type: string } {
    const rules = this.selectedDecor.rules;
    return {
      size: this.getRuleValuesAsString(rules, 'panelSize', 'panelSize.options'),
      thickness: this.getRuleValuesAsString(rules, 'thickness', 'panelThickness.options'),
      type: this.getRuleValuesAsString(rules, 'type', 'panelType.options'),
      grade: this.fallbackIfNoGrade(rules)
    };
  }

  fallbackIfNoGrade(rules) {
    const ruleValuesAsString = this.getRuleValuesAsString(rules, 'grade', 'panelGrade.options');
    if (ruleValuesAsString.length < 1) {
      return this.$t('panelGrade.options.no-grade').toString();
    }
    return ruleValuesAsString;
  }

  get imageUrl() {
    if (this.selectedDecor && this.selectedDecor.imgUrl) {
      return this.selectedDecor.imgUrl;
    }
    return this.selectedDecor.imgUrl;
  }

  get architectImage() {
    if (this.selectedFinish && this.selectedFinish.architectImage) {
      return this.selectedFinish.architectImage;
    }
    if (this.selectedDecor && this.selectedDecor.architectImage) {
      return this.selectedDecor.architectImage;
    }
    return null;
  }

  get architectImageExtension() {
    if (this.architectImage && this.architectImage.length > 0) {
      return this.architectImage.split('.').pop();
    }
    return '';
  }

  get downloadFileLabel() {
    return `${this.$t('decorSet.download')} ${this.architectImageExtension?.toUpperCase()} ${this.$t('decorSet.file')}`;
  }

  get thumbnail() {
    if (this.selectedFinish && this.selectedFinish.thumbnailImage) {
      return this.selectedFinish.thumbnailImage;
    }
    if (this.selectedDecor && this.selectedDecor.thumbnailImage) {
      return this.selectedDecor.thumbnailImage;
    }
    return null;
  }

  get architectImageItem(): ParsedDocumentationTabEntry {
    return {
      link: this.architectImage as string,
      linkRel: 'noop',
      linkTarget: '_blank',
      thumbnail: this.thumbnail as string,
      docName: this.downloadFileLabel
    };
  }

  isSelectedFinish(finish: Finish): boolean {
    return this.selectedFinish ? this.selectedFinish.id === finish.id : false;
  }

  public hasSelectedFinish(): boolean {
    return Boolean(this.selectedFinish);
  }

  toggleModal(): void {
    this.renderComponent = false;
    this.selectFinish(this.selectedFinishIndex);

    this.$nextTick(() => {
      // Adding the component back in
      this.renderComponent = true;
    });
    if(this.openModal){
      this.addSample();
    }
    this.openModal = !this.openModal;
  }

  closeModal() {
    this.openModal = false;
  }

  selectDecor(index: number): void {
    this.selectedDecorIndex = index;
    this.selectedFinishIndex = 0;
  }

  selectFinish(index: number): void {
    this.selectedFinishIndex = index;
  }

  getRuleValuesAsString(rules: Array<Rule>, property: string, prefix: string | null = null): string {

    const strings: Array<string> = rules
        .map((value: Rule) => {
          return value[property];
        })
        .reduce((previousValue: Array<string>, currentValue: Array<string>) => {
          return previousValue.concat(currentValue);
        }, [])
        .filter((value: string) => value && value.length > 0)
        .map((value: string) => {
          if (prefix) {
            return this.$t(`${prefix}.${value}`).toString();
          }
          return value;
        })
        .sort((a: string, b: string) => {
          return a.localeCompare(b, undefined, {
            numeric: true, sensitivity: 'base'
          });
        });



    return [...new Set(strings)].join(', ');
  }

  private async addSample() {
    if (this.addingSample) return;

    if (this.isCartLimitReached) {
      this.setShowCartLimitReachedModal(true);
      return;
    }

    this.addingSample = true;
    await this.$store.dispatch('shoppingCart/addItem', await OrderableItem.create(this.selectedFinish?.id, 1));

    if (this.doesCartContainsItemsWithDifferentPanelOrSidingLocation) {
      this.setShowInfoModal(true);
    }

    setTimeout(() => {
      this.addingSample = false;
    }, 2000);
  }

  setShowCartLimitReachedModal(isShow: boolean) {
    this.showCartLimitReachedModal = isShow;
  }

  setShowInfoModal(isShow: boolean) {
    this.showInfoModal = isShow;
  }
}
