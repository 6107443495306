import {Plugin, Store} from 'vuex';

/**
 * 'transform':
 *
 * If not null, the SynchedStatePlugin passes the stored data to the transform function, as well as a callback it can
 * use to sync the transformed data into global storage. The function is responsible for calling sync_fn after it is
 * finished transforming the data, and must pass the transformed data to this function.
 *
 * This helps us with cases where new implementations aren't backwards compatible with previous ones, and also when we
 * need to do something with the data in general (like verify it's still valid), before actually using it.
 */
interface SyncedStateOptions {
    storage?: Storage;
    debug?: boolean;
    transform?: ((sync_fn: (any) => void, data) => any) | null;
}

class SyncedStatePlugin<S, M extends keyof S> {
    private readonly key: string;
    private readonly storage: Storage;
    private readonly debug: boolean;
    private readonly transform: ((sync_fn, data) => any) | null;
    private storageEventHandlerReference: OmitThisParameter<(event: StorageEvent) => void> | null = null;

    constructor(private readonly store: Store<S>, private readonly moduleName: M, private options: SyncedStateOptions = {}) {
        this.key = `${this.moduleName}/state`;
        const optionsWithDefaults = Object.assign({}, {
            storage: localStorage,
            debug: false,
            transform: null
        }, options);
        this.storage = optionsWithDefaults.storage;
        this.debug = optionsWithDefaults.debug;
        this.transform = optionsWithDefaults.transform;
        this.init();
        this.createStorageHandler();
        this.subscribeToStore();
    }

    private static void: () => void = () => {/* void */};

    destroy() {
        if (this.storageEventHandlerReference) {
            window.removeEventListener('storage', this.storageEventHandlerReference);
        }
    }

    // Passed to transform callbacks for data sync.
    private callback_sync = (data) => {
        const currentState: S = this.getImmutableState();
        currentState[this.moduleName] = data;
        this.store.replaceState(currentState);
    }

    private syncState() {
        const storedState = this.getStoredModuleState();
        if (storedState) {
            if (this.transform != null) {
                this.transform(this.callback_sync, storedState);
            } else {
                const currentState: S = this.getImmutableState();
                currentState[this.moduleName] = storedState;
                this.store.replaceState(currentState);
            }
        }
    }


    private getStoredModuleState(): S[M] | null {
        const item = this.storage.getItem(this.key);
        if (item) {
            return JSON.parse(item);
        }
        return null;
    }

    private updateStoredModuleState(moduleState: S[M]): S[M] {
        if (this.hasChanged(moduleState)) {
            this.storage.setItem(this.key, JSON.stringify(moduleState));
        }
        return moduleState;
    }

    private hasChanged(moduleState: S[M]): boolean {
        return this.storage.getItem(this.key) !== JSON.stringify(moduleState);
    }

    private init() {
        this.debug ? console.log(`Initialized for module: ${this.moduleName}`) : SyncedStatePlugin.void;
        this.syncState();
    }

    private getImmutableState(): S {
        return Object.assign({}, this.store.state);
    }

    private subscribeToStore() {
        this.store.subscribe((mutation, state) => {
            if (this.applicableToThisModule(mutation.type)) {
                this.debug ? console.log(`Mutation occurred to module: ${this.moduleName}`) : SyncedStatePlugin.void;
                this.updateStoredModuleState(state[this.moduleName]);
            }
        });
    }

    private applicableToThisModule(string: string | null): boolean {
        return string?.startsWith(`${this.moduleName}/`) || false;
    }

    private createStorageHandler() {
        window.addEventListener('storage', this.storageEventHandlerReference = this.onStorageEvent.bind(this));
    }

    private onStorageEvent(event: StorageEvent) {
        if (this.applicableToThisModule(event.key)) {
            this.debug ? console.log(`A storage event occurred for ${this.moduleName}`) : SyncedStatePlugin.void;
            this.syncState();
        }
    }
}


export default function createSyncedState<S>(moduleName: keyof S, options?: SyncedStateOptions): Plugin<S> {
    return store => new SyncedStatePlugin(store, moduleName, options);
}
