






















import dealersApi from '@/api/dealersApi';
import distributorsApi from '@/api/distributorsApi';
import googleMapsApi from '@/api/googleMapsApi';
import salesContactsApi from '@/api/salesContactsApi';
import IconSearch from '@/assets/svg/icons/icon-search.svg?inline';
import DealerCard from '@/components/global/DealerCard.vue';
import {defaultLatLng} from '@/components/global/GoogleMaps.vue';
import LocationCard from '@/components/global/LocationCard.vue';
import {Dealer} from '@/models/dealers';
import {Unit, Units, findUnit, UnitString} from '@/utils/units';
import {AxiosResponse} from 'axios';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  name: 'DealerLocatorSearch', components: {
    DealerCard,
    LocationCard,
    IconSearch
  }
})
export default class DealerLocatorSearch extends Vue {
  @Prop({required: true}) apiKey!: string;
  @Prop({required: true}) placeholder!: string;
  @Prop({required: true}) productLine!: string;
  public sortedDealers: Array<Dealer> = [];
  @Prop({default: true}) showDealers!: boolean;
  @Prop({default: false}) showDistributors!: boolean;
  @Prop({default: false}) showSalesContacts!: boolean;
  @Prop({default: defaultLatLng.lat}) lat!: number;
  @Prop({default: defaultLatLng.lng}) lng!: number;
  @Prop({default: 'metric'}) units!: UnitString;
  private searchValue: string = '';
  private allDealers: Array<Dealer> = [];
  private userHandlingDone = false;
  private userLat: number = this.lat;
  private userLng: number = this.lng;
  private unit: Unit = Units.Metric;

  private get dealers(): Array<Dealer> | undefined {
    const result: Array<Dealer> | undefined = this.allDealers;
    if (result) {
      return result.slice();
    }
    return undefined;
  }

  private get response(): google.maps.GeocoderResult | undefined {
    return this.$store.getters['geocoding/results'];
  }

  private get responseLocation(): { lat: number, lng: number } | null {
    const location = this.response?.geometry.location;
    if (location) {
      return location.toJSON();
    }
    return {
      lat: this.userLat || this.lat,
      lng: this.userLng || this.lng
    };
  }

  beforeMount() {
    this.unit = findUnit(this.units) as Unit;
  }

  beforeCreate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLat = position.coords.latitude;
        this.userLng = position.coords.longitude;
        this.userHandlingDone = true;
      }, () => {
        this.userHandlingDone = true;
      });
    } else {
      this.userHandlingDone = true;
    }
  }

  mounted() {
    this.getAllDealers();
    this.$store.dispatch('geocoding/setKey', this.apiKey);
  }

  getAllDealers() {
    const promises: Promise<AxiosResponse<Dealer>>[] = [];
    if (this.showDealers) {
      promises.push(dealersApi.performSearchWithFilters(null, this.productLine));
    }
    if (this.showDistributors) {
      promises.push(distributorsApi.performSearchWithFilters(null, this.productLine));
    }
    if (this.showSalesContacts) {
      promises.push(salesContactsApi.performSearchWithFilters(null, this.productLine));
    }
    Promise.all(promises).then((values: AxiosResponse<Dealer>[]) => {
      this.allDealers = values.flatMap(value => value.data);
    }).catch(reason => {
      console.error(reason);
    });
  }

  private async updateSortedDealers(): Promise<void> {
    if (this.dealers && this.responseLocation) {
      const dealers: Array<Dealer & { distance?: number }> = Array.from(this.dealers);
      const comparableDealers = await Promise.all(dealers.map(async dealer => {
        dealer.distance = await googleMapsApi.getLocationDistanceInKm(this.apiKey, {
          lat: dealer.lat, lng: dealer.lng
        }, this.responseLocation as {lat: number; lng: number;});
        return dealer;
      }));
      comparableDealers.sort((dealer1, dealer2) => {
        return (dealer1.distance || Number.MAX_SAFE_INTEGER) - (dealer2.distance || Number.MAX_SAFE_INTEGER);
      });
      this.sortedDealers = comparableDealers.slice(0, 2);
    }
  }

  @Watch('searchValue')
  private onSearchValueChanged() {
    this.$store.dispatch('geocoding/setAddress', this.searchValue);
  }

  @Watch('responseLocation')
  private onResponseLocationChanged() {
    this.updateSortedDealers();
  }

  @Watch('dealers')
  private onDealersChanged() {
    this.updateSortedDealers();
  }
}
