/* Keep in sync with FinishType.ftl */
export enum FinishType {
    CRYSTAL_MATT = 'crystal-matt',
    DIFFUSE = 'diffuse',
    MATT = 'matt',
    MATT_ROCK = 'matt-rock',
    OBLIQUE = 'oblique',
    ROCK = 'rock',
    SATIN = 'satin',
    SPECULAR = 'specular'
}


export enum FinishImages {
    CRYSTAL_MATT = 'crystal-480-60.jpg',
    DIFFUSE = 'diffuse-480-60.jpg',
    MATT = 'matt-480-60.jpg',
    MATT_ROCK = 'matt-rock-480-60.jpg',
    OBLIQUE = 'oblique-480-60.jpg',
    ROCK = 'rock-480-60.jpg',
    SATIN = 'satin-480-60.jpg',
    SPECULAR = 'specular-480-60.jpg'
}
