import {bitWiseEquality} from '@/utils/bitwiseHelpers';
import productsApi from "@/api/productsApi";
import {Filter} from "@/api/DeliveryEndpoint";
import parseBool from "@/utils/parseBool";
import { ProductDeliveryEndpoint } from "@/typings/interfaces/ProductDeliveryEndpoint";

export class OrderableItem {
    id: string;
    quantity: number;
    state: ItemState;
    panelOrSidingLocation?: string | null;

    // See the create function within the OrderableItem namespace if you need an item with the correct state.
    constructor(id, quantity, state = ItemState.NONE, panelOrSidingLocation: string | null = null) {
        this.id = id;
        this.quantity = quantity;
        this.state = state;
        this.panelOrSidingLocation = panelOrSidingLocation;
    }

    // Asynchronously creates an orderable item with the state field correctly set. Returns a promise.
    static create(id: string, quantity: number) {
        return productsApi.getFinishes(new Filter('@jcr:uuid', id)).then(value => {
            const finish = value.data.results[0];

            const panelOrSidingLocation: string | null = this.getPanelOrSidingLocation(finish);

            const enabled = parseBool(finish.enabled);
            const inStock = parseBool(finish.inStock);
            let state = ItemState.NONE;
            if (!enabled) {
                state += ItemState.DISABLED;
            }
            if (!inStock) {
                state += ItemState.OUT_OF_STOCK;
            }
            return new OrderableItem(id, quantity, state, panelOrSidingLocation);
        }).catch(reason => {
            console.error(reason);
            return new OrderableItem(id, quantity, ItemState.NON_EXISTENT);
        });
    }

    public isState(state: ItemState) {
        return bitWiseEquality<ItemState>(state, this.state);
    }

    public itemExists() {
        return !this.isState(ItemState.NON_EXISTENT);
    }

    public isOutOfStock() {
        return this.isState(ItemState.OUT_OF_STOCK);
    }

    public isDisabled() {
        return this.isState(ItemState.DISABLED);
    }

    public hasErrors() {
        return !this.isState(ItemState.NONE);
    }

    public isOrderable() {
        return !this.isDisabled() &&
            !this.hasErrors() &&
            !this.isOutOfStock() &&
            this.itemExists();
    }

    private static getPanelOrSidingLocation(finish: ProductDeliveryEndpoint.Finish): string | null {
        const productGroup: string = finish["@path"].split("/")[1];

        if (productGroup.includes("exterior")) return "exterior";
        if (productGroup.includes("interior")) return "interior";

        return null;
    }
}

/**
 * This enum uses bit flags to map multiple states at once
 * With bits this: 1 << 3, means that we're shifting the flag "1", "3" places to the left using the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Left_shift bitwise left shift operator}
 * Which in binary translates to [1][0][0][0] (in base 2, so this is not a thousand, it's in fact 8 in the decimal system).
 *
 * In this enum something like OUT_OF_STOCK_AND_DISABLED merges the two values by using the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_OR bitwise OR operator},
 * resulting in the combined flags being represented in one variable.
 *
 * This in turn would allow us to check for equality using the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_AND bitwise AND operator}.
 * For a more elaborate example see {@linkplain https://www.typescriptlang.org/play?#code/KYOwrgtgBAkgLsCBlOBDBUDeAoKUByA8vgKJQC8UADADS4HED6JAGjEgCon4cVQCMUADxDqdPIQCqHRoQBijToQDCAaT6CRA8VAAi7AIIAhADIldG4aIBMdAL7ZsAYwD2IAM5woASwTRKOHh4nujAAFywfiihAHREpNgOzm6eUAAm3u6oAEYANsBp8Ih8gUEhCBFFyGgIMfpIxma6iY6uHl4ZWXkFBiBphGBwhABmKC5OANZVJfTBNeGRiNG19Y3mUAA+i9WxUjLyihwqqi3J7emZOflpvf2DI2OTVUYzQVDlC1XLwHWGpusAam23xie1kCiUalOAHpoVAOAALTI+dxQADuCPQUAAUqgAG6oJBOABO3gADl5vCAEMSycTgHBURjQD4vMMqWlUagoNlfGjMsAoCAXDVvG4fCAoKhJd5hu95hBQF4zqkAFbuACyqAA5t4nHwABR4sLSgCeAEoKAA+KB4qAAfigcGJYEFEWGqFy7mAAG5WikvE4EcBJt88JQDb5EBFMB9KlF5nYaPLQvGlvMgpbyDbSng2qk7RGPhRyJQvvM4sQSJbHVGIDFi6WUxgInWGxmAGTN32zKD0uBgYmS3NvHkuFy5CLqrW6pxG806Ue2z0GVt+duhRejgm5IwRD69hx2P0qyl+dwxgDaE2Apv3zqp2oAujG48DE3ZXkE68nOlcClUv6XN0Nx9AMQyjHA4xTH4QFdNctzgQ8UFPH4RiJCe+ZeC4gx8IQ2SqiGcAxDepruJG57mjE9JpGATjAAaBquLk+ROHAyZOIO9LUlmOa9lhrLFJQdbuJenHEtxcBPn6bzMaxcBiVxSpPl+o6ZLowHXBEQYhhM3wUYgyblrEqz-LoC69t+7hIZB0HacGobzAZEBGQmuzSOChzHBZS5Ci4JASS4xIXlAOmOaEzmuemsTxNWh6zP2g6SnJRF+kmWCfqgXIgBamEpBOPy5C42oGjhcDmn6QA this Typescript playground example}.
 *
 */

export enum ItemState {
    NONE = 0,
    NON_EXISTENT = 1 << 0,
    OUT_OF_STOCK = 1 << 1,
    DISABLED = 1 << 2
}
