
























import IconShopping from '@/assets/svg/icons/icon-shopping.svg?inline';
import * as ElasticSearch from '@/typings/interfaces/ElasticSearch';
import * as ProductSearch from '@/typings/interfaces/ProductSearch';

import {ImgUrlMixin} from '@/utils/mixins/ImgUrlMixin.js';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {parseTextFields} from '@/utils/StringUtils';

@Component({
  name: 'SampleListItem',
  components: {IconShopping},
})
export default class SampleListItem extends mixins(ImgUrlMixin) {
  @Prop({required: true}) public item!: ElasticSearch.Hit<ProductSearch.Source>;

  get itemSource() {
    return parseTextFields(this.item._source);
  }

  get isItemActive() {
    return this.$store.getters['productSearch/selectedItem'] === this.item._source.id;
  }

  get inCart() {
    const finishIds: string[] = this.item._source.finishes.map(value => value.id);
    return this.$store.getters['shoppingCart/existsInCart'](finishIds);
  }

  public getImage(item: ProductSearch.Source): string | null {
    if (item.thumbnailImage?.renditions) {
      return _mgnl.contextPath + item.thumbnailImage.renditions['productImage'].link;
    }
    if (item.image?.renditions) {
      return _mgnl.contextPath + item.image.renditions['productImage'].link;
    }
    return null;
  }

  selectSample(): void {
    this.$store.dispatch('productSearch/toggleSelectedItem', this.item._source.id);
    this.$store.dispatch('productSearch/setFiltersOpen', false);
  }

}
