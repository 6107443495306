






import {ImgUrlMixin} from '@/utils/mixins/ImgUrlMixin';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {superFormatter} from "@/utils/StringUtils";

@Component({name: 'ProductOverviewTab'})
export default class ProductOverviewTab extends mixins(ImgUrlMixin) {
  @Prop(String) readonly imgUrl!: string;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly scrollId!: string;

  get parsedTitle() {
    return superFormatter(this.title);
  }

  private goTo(val) {
    window.scrollTo({
      top:
          (document.getElementById(val) as HTMLElement).getBoundingClientRect()
              .top +
          window.scrollY -
          150,
      behavior: 'smooth',
    });
  }
}
