



































import SvgFacebook from '@/assets/svg/logos/icon-facebook.svg?inline';
import SvgInstagram from '@/assets/svg/logos/icon-instagram.svg?inline';
import SvgLinkedin from '@/assets/svg/logos/icon-linkedin.svg?inline';
import SvgPinterest from '@/assets/svg/logos/icon-pinterest.svg?inline';
import SvgTwitter from '@/assets/svg/logos/icon-twitter.svg?inline';
import SvgYoutube from '@/assets/svg/logos/icon-youtube.svg?inline';
import TrLogoTrespa from '@/components/shared/TrLogoTrespa.vue';
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

@Component({
  components: {
    SvgFacebook,
    SvgInstagram,
    SvgLinkedin,
    SvgTwitter,
    SvgYoutube,
    SvgPinterest,
    TrLogoTrespa,
  },
})
export default class Footer extends Vue {
  @PropSync('home', {default: '/'}) readonly homeUrl!: string;
  @Prop({}) readonly socialTitle!: string;
  @Prop({}) readonly languageTitle!: string;

  get language() {
    return this.$i18n.locale.toUpperCase();
  }

}
