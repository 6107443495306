


















import pswpTrigger from '@/directives/pswp-trigger';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {Component, Prop, Vue} from 'vue-property-decorator';

gsap.registerPlugin(ScrollTrigger);

@Component({
  components: {
    Swiper, SwiperSlide
  },
  directives: {
    pswpTrigger
  }
})
export default class GridVisual extends Vue {

  @Prop({required: true}) public componentUuid!: string;

  private pictures: { img: { [key: string]: string }, sources: { [key: string]: string }[] }[] = [];
  private swiperOptions = {
    keyboard: true,
    slideToClickedSlide: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    breakpointsInverse: true,
    setWrapperSize: true,
    breakpoints: {
      590: {
        spaceBetween: 40,
      },
    },
  };

  mounted() {
    this.pictures = this.collectPictures();
  }

  private collectPictures() {
    const picturesNodeList = this.$el.querySelectorAll('picture');
    const pictureElements = Array.from(picturesNodeList);
    return pictureElements.map(pictureElement => {
      const sources = Array.from(pictureElement.querySelectorAll('source'));
      const sourcesMap = sources?.map(source => this.collectAttributes(source));
      const image = pictureElement.querySelector('img');
      return {
        sources: sourcesMap,
        img: this.collectAttributes(image)
      };
    });
  }

  private collectAttributes(item: HTMLElement | undefined | null): { [key: string]: string } {
    if (!item) {
      return {};
    }
    const attributeNames = item.getAttributeNames();
    return attributeNames.reduce((collector, attributeName) => {
      if (attributeName.indexOf('data-v-') !== 0) {
        collector[attributeName] = item.getAttribute(attributeName);
      }
      return collector;
    }, {});
  }

}
