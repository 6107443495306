






















import {ImgUrlMixin} from '@/utils/mixins/ImgUrlMixin.js';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';

@Component({name: 'ProductOverviewPanel'})
export default class ProductOverviewPanel extends mixins(ImgUrlMixin) {
  @Prop() readonly data?: any;
}
