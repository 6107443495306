




























import IconLocation from '@/assets/svg/icons/icon-location.svg?inline';
import {SalesContact} from '@/models/dealers';
import {Component, Prop, Vue} from 'vue-property-decorator';
import LocationCard from '@/components/global/LocationCard.vue';

@Component({
  name: 'SalesCard', components: {
    LocationCard,
    IconLocation
  }
})
export default class SalesCard extends Vue {
  @Prop({required: true}) dealer!: SalesContact;
}
