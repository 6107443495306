






























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'TrButton',
})
export default class TrButton extends Vue {
  @Prop(String) readonly to!: string;
  @Prop(String) readonly target!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop({
    default: 'type-a',
    type: String,
    validator: (value) => {
      return ['type-a', 'type-b', 'type-c', 'type-d', 'type-e'].includes(value);
    },
  })
  readonly btnType!: string;

  // 40 45 50
  @Prop({
    default: 'large',
    type: String,
    validator: (size) => {
      return ['none', 'medium', 'large', 'x-large'].includes(size);
    },
  })
  readonly size!: string;

  @Prop({
    default: 'right',
    type: String,
    validator: (side) => {
      return ['left', 'right'].includes(side);
    },
  })
  readonly side!: string;

  @Prop({
    default: false,
    type: Boolean,
  })
  readonly fullWidth!: boolean

  get customClass() {
    return {
      'btn__type-a': this.btnType === 'type-a',
      'btn__type-b': this.btnType === 'type-b',
      'btn__type-c': this.btnType === 'type-c',
      'btn__type-d': this.btnType === 'type-d',
      'btn__type-e': this.btnType === 'type-e',
      //
      'btn--size-none' : this.size === 'none',
      'btn--size-med': this.size === 'medium',
      'btn--size-large': this.size === 'large',
      'btn--size-x-large': this.size === 'x-large',
      //
      'btn--icon-left': this.side === 'left',
      'btn--icon-right': this.side === 'right',
      //
      'btn--disabled': this.disabled,
      //
      'btn--full-width': this.fullWidth
    };
  }

  handleClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    } else {
      this.$emit('click', event);
    }
  }
}
