





























































































































import { AggregationsAsFilters, FilterHelpers } from '@/components/filter/FilterHelpers';
import SelectableImage from '@/components/global/SelectableImage.vue';
import PreviewImgListItem from '@/components/preview-img/PreviewImgListItem.vue';
import TrButton from '@/components/shared/TrButton.vue';
import * as ElasticSearch from '@/typings/interfaces/ElasticSearch';
import { ImageHelpers } from '@/utils/imageHelpers';
import { Component, Mixins } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import FilterDropdown from '@/components/filter/FilterDropdown.vue';
import SidePanelFinish from '@/components/side-panel/SidePanelFinish.vue';
import SidePanelProductLines from '@/components/side-panel/SidePanelProductLines.vue';
import { FinishType } from '@/typings/enums/FinishType';
import { Bucket } from '@/typings/interfaces/ElasticSearch';
import TrModal from '@/components/shared/TrModal.vue';
import SampleSelectorWizard from '@/components/global/SampleSelectorWizard.vue';
import IconSearch from '@/assets/svg/icons/icon-search.svg?inline';
import { ProductLineType } from "@/typings/enums/ProductLineType";

@Component({
  name: 'FilterOptions',
  components: {
    SampleSelectorWizard,
    TrModal,
    SidePanelFinish,
    SidePanelProductLines,
    FilterDropdown,
    SelectableImage,
    PreviewImgListItem,
    TrButton,
    IconSearch,
  },
})
export default class FilterOptions extends Mixins(ImageHelpers, FilterHelpers) {
  private showSampleSelectorWizard: boolean = false;
  private searchValue: string = '';

  public onInputChange() {
    this.$store.dispatch('productSearch/setQuery', this.searchValue);
  }

  get isFiltersOpen(): boolean {
    return this.$store.getters['productSearch/filtersOpen'];
  }

  setShowSampleSelectorWizard(isShow: boolean) {
    this.showSampleSelectorWizard = isShow;
  }

  get currentMode(): string {
    return this.$store.getters['productSearch/sampleSearchMode'];
  }

  private getListOfFinishes(finishes: Bucket[]): FinishType[] {
    return finishes.map((finish) => finish.key) as FinishType[];
  }

  private getListOfProductLines(productLines: Bucket[]): ProductLineType[] {
    return productLines.map((productLine) => productLine.key[0]) as ProductLineType[];
  }

  // I haven't found a better way to make checkboxes interact with this better.
  get exteriorMode() {
    return this.currentMode == 'exterior';
  }

  set exteriorMode(val: boolean) {
    Cookies.set('sampleSearchMode', 'exterior');
    this.$store.dispatch('productSearch/setSampleSearchMode', 'exterior');
    this.$store.dispatch('productSearch/setActiveFilters', 'exterior');
  }

  get interiorMode() {
    return this.currentMode == 'interior';
  }

  set interiorMode(val: boolean) {
    Cookies.set('sampleSearchMode', 'interior');
    this.$store.dispatch('productSearch/setSampleSearchMode', 'interior');
    this.$store.dispatch('productSearch/setActiveFilters', 'interior');
  }

  get sidingsMode() {
    return this.currentMode == 'sidings';
  }

  set sidingsMode(val: boolean) {
    Cookies.set('sampleSearchMode', 'sidings');
    this.$store.dispatch('productSearch/setSampleSearchMode', 'sidings');
    this.$store.dispatch('productSearch/setActiveFilters', 'sidings');
  }

  get excludedProductLines(): Array<string> {
    let ret = this.$store.getters['productSearch/excludedProductLines'];
    return ret === undefined ? [] : ret;
  }

  public passedValue(name, key) {
    return this.isActiveFilter(name, key);
  }

  getNestedFilteredCount(aggregation: keyof AggregationsAsFilters, nested: string, key: string): number {
    const nestedAggregationResponse: ElasticSearch.NestedAggregation = this.results?.aggregations?.filtered[
      aggregation
    ] as ElasticSearch.NestedAggregation;
    const aggregationResponse: ElasticSearch.Aggregation = nestedAggregationResponse[
      `${aggregation}.${nested}`
    ] as ElasticSearch.Aggregation;
    return this.getDocCountForAggregationResponse(aggregationResponse, key);
  }

  getFilteredCount(aggregation: keyof AggregationsAsFilters, key: string): number {
    const aggregationResponse: ElasticSearch.Aggregation = this.results?.aggregations?.filtered[
      aggregation
    ] as ElasticSearch.Aggregation;
    return this.getDocCountForAggregationResponse(aggregationResponse, key);
  }

  updateFilters(event: Event, name: string, value: string) {
    const target: EventTarget = event.target as EventTarget;
    const checked = (target as HTMLInputElement).checked || false;
    if (checked) {
      this.$store.dispatch('productSearch/addFilter', { name, value });
    } else {
      this.$store.dispatch('productSearch/removeFilter', { name, value });
    }
  }

  public hasFilter(filterName: string): boolean {
    return Object.prototype.hasOwnProperty.call(this.filters, filterName);
  }

  private getDocCountForAggregationResponse(aggregation: ElasticSearch.Aggregation, key: string): number {
    const find = aggregation.buckets.find((value: ElasticSearch.Bucket) => {
      const matchAgainst = (value as unknown as ElasticSearch.MultiTermBucket).key_as_string || value.key;
      return matchAgainst == key;
    });
    return (find && find.doc_count) || 0;
  }
}
