






































import productSearchApi from '@/api/productSearchApi';
import IconCancel from '@/assets/svg/icons/icon-cancel.svg?inline';
import * as ElasticSearch from '@/typings/interfaces/ElasticSearch';
import * as ProductSearch from '@/typings/interfaces/ProductSearch';
import gsap from 'gsap';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {OrderableItem} from "@/typings/types/OrderableItem";

@Component({
  components: {IconCancel},
})
export default class BasketItem extends Vue {
  @Prop({required: true}) private item!: OrderableItem;
  private source: ProductSearch.Source = {} as ProductSearch.Source;

  get finish(): ProductSearch.Finish | undefined {
    return this.source?.finishes?.find(value => value.id === this.item.id);
  }

  public getProductImage(finish: ProductSearch.Finish): string | null {
    if (finish.thumbnailImage?.renditions) {
      return _mgnl.contextPath + finish.thumbnailImage.renditions.productImage.link;
    }

    return null;
  }

  get quantity() {
    return this.item?.quantity || 0;
  }

  removeFromCart(cartEntry: OrderableItem) {
    this.$store.dispatch('shoppingCart/removeItem', new OrderableItem(cartEntry.id, 0));
  }

  get sampleDimensions(): string | undefined {
    const prodLineDims = this.source?.productLine?.sampleDimensions;
    const decorSet = this.source?.decorSet;
    if (decorSet == undefined)
      return prodLineDims;
    if (decorSet.sampleDimensions == null || decorSet.sampleDimensions.trim().length == 0)
      return prodLineDims;
    return decorSet.sampleDimensions.trim();
  }

  @Watch('quantity')
  onQuantityUpdate() {
    const tl = gsap.timeline();
    const wrapper = (this.$refs.wrapper as HTMLElement);
    tl.to(wrapper, {opacity: 0.25, duration: 0.2});
    tl.to(wrapper, {opacity: 1, duration: 0.2});
  }

  private beforeMount() {

    if (this.item.itemExists()) {
      productSearchApi.performSearchWithFilters({
        'finishes.id': this.item.id
      }, true).then(value => {
        const hits: ElasticSearch.Hit<ProductSearch.Source>[] = value.data.hits.hits;
        const hit: ElasticSearch.Hit<ProductSearch.Source> = hits[0];
        this.source = hit?._source;
      });
    }
  }
}
