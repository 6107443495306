import {StoreModules} from '@/store';
import {gsap} from 'gsap';
import Vue from 'vue';
import {Module} from 'vuex';

export interface SharedState {
    globalTimeline: () => GSAPTimeline;
    linkIntercepted: boolean;
    interceptedEvent?: MouseEvent;
}

const module: Module<SharedState, StoreModules> = {
    namespaced: true,
};

module.state = {
    globalTimeline: () => {
        return gsap.timeline({
            onComplete: function () {
                this.clear();
            }
        });
    },
    linkIntercepted: false,
    interceptedEvent: undefined,
}

module.getters = {
    globalTimeline: state => state.globalTimeline(),
    linkIntercepted: state => state.linkIntercepted,
    interceptedEvent: state => state.interceptedEvent
};

module.actions = {
    linkIntercepted({commit}, value: boolean) {
        commit('linkIntercepted', value);
    },
    interceptedEvent({commit}, value: boolean) {
        commit('interceptedEvent', value);
    },
};

module.mutations = {
    linkIntercepted(state, payload: boolean) {
        state.linkIntercepted = payload;
    },
    interceptedEvent(state, payload: MouseEvent) {
        state.interceptedEvent = payload;
    }
};

export default module;

export const getGlobalTimeline = (instance: Vue): GSAPTimeline => {
    return instance.$store.getters['shared/globalTimeline'];
};
