







import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'TrTooltip',
})
export default class TrTooltip extends Vue {
  @Prop(String) readonly message!: string;
  @Prop({
    default: 'bottom',
    type: String,
    validator: (position) => {
      return ['top', 'bottom', 'left', 'right'].includes(position);
    },
  })
  readonly position!: string;

  get customClass() {
    return {
      'tooltip--left': this.position === 'left',
      'tooltip--top': this.position === 'top',
      'tooltip--right': this.position === 'right',
      'tooltip--bottom': this.position === 'bottom',
    };
  }
}
