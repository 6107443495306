






















import IconLocation from '@/assets/svg/icons/icon-location.svg?inline';
import {gsap} from 'gsap';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'LocationCard', components: {
    IconLocation
  }
})
export default class LocationCard extends Vue {
  @Prop({default: false}) isActive?: boolean;
  @Prop({default: true}) hasInfo?: boolean;
  @Prop({default: false}) disableHover?: boolean;

  private showDetails() {
    const target: HTMLElement = this.$el.querySelector('.location-card__info') as HTMLElement;
    if (target) {
      const opacity = target.style.opacity;
      if (opacity !== '1') {
        gsap.to(target, {
          height: 'auto',
          duration: 0.3
        });
        gsap.to(target, {
          opacity: 1,
          pointerEvents: 'all',
          delay: 0.3,
          duration: 0.3
        });
      } else {
        gsap.to(target, {
          opacity: 0,
          pointerEvents: 'none',
          duration: 0.3
        });
        gsap.to(target, {
          height: 0,
          delay: 0.3,
          duration: 0.3
        });
      }
    }
  }

  private hover(e: MouseEvent, hover: boolean) {
    if (this.disableHover) return;

    const element: HTMLElement = e.currentTarget as HTMLElement;
    const parent: HTMLElement | null = element.parentElement;

    if (hover) {
      parent?.classList.add('location-card--hover');
    } else {
      parent?.classList.remove('location-card--hover');
    }
  }
}
