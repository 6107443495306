import Cookies from 'js-cookie';

type PersonalizationConfig = { maxItems: number; value: number };

class PersonalizationHelper {
    private static TRAIT_COOKIE = 'personalizationtrait';
    private static PersonalizationConfigs: { DEFAULT: PersonalizationConfig, [key: string]: PersonalizationConfig } = {
        DEFAULT: {
            value: 0,
            maxItems: 5
        },
        PROFESSIONAL: {
            value: 1,
            maxItems: 10
        },
        ARCHITECT: {
            value: 2,
            maxItems: 10
        },
        END_USER: {
            value: 3,
            maxItems: 5
        }
    };

    constructor() {
        throw new Error('This class should not be instantiated');
    }

    public static getConfigForIndex: (index: number) => PersonalizationConfig = (index: number) => {
        return PersonalizationHelper.getConfigForCookieValue(index + 1);
    };


    public static getConfigForCookieValue: (value: number) => PersonalizationConfig = (value: number) => {
        const result: PersonalizationConfig | undefined = Object.entries(PersonalizationHelper.PersonalizationConfigs)
            .map(entry => {
                return entry[1];
            }).find((entry: PersonalizationConfig) => {
                return entry.value === value;
            });
        return result || PersonalizationHelper.PersonalizationConfigs.DEFAULT;
    };


    public static getConfigFromCookie: () => PersonalizationConfig = () => {
        const cookie = Cookies.get(PersonalizationHelper.TRAIT_COOKIE);
        return PersonalizationHelper.getConfigForCookieValue(Number(cookie));
    };

    public static setCookieFromIndex = (index: number) => {
        const configForIndex = PersonalizationHelper.getConfigForIndex(index);
        Cookies.set(PersonalizationHelper.TRAIT_COOKIE, configForIndex.value.toString(), {expires: 365});
    };

    public static isActiveFromIndex(index: number): boolean {
        const configForIndex = PersonalizationHelper.getConfigForIndex(index);
        return PersonalizationHelper.getConfigFromCookie().value === configForIndex.value;
    }

    public static isPersonalizationActive(): boolean {
        return !!Cookies.get(PersonalizationHelper.TRAIT_COOKIE);
    }

    public static setDefaultConfig(): void {
        Cookies.set(PersonalizationHelper.TRAIT_COOKIE, PersonalizationHelper.PersonalizationConfigs.DEFAULT.value.toString(), {expires: 365});
    }

}

export default PersonalizationHelper;
