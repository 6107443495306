import {StoreModules} from '@/store';
import {
    findCartEntry,
    hasCartEntry,
    mergeCartEntry,
    removeCartEntry
} from '@/store/shoppingCart/helpers';
import _ from 'lodash';
import {Module} from 'vuex';
import {OrderableItem} from '@/typings/types/OrderableItem';
import PersonalizationHelper from '@/utils/PersonalizationHelper';


export class Cart {
    items: OrderableItem[];

    constructor() {
        this.items = [];
    }
}

/*export interface OrderableFinish {
    id: string;
    name: string;
    title: string;
    thumbnailImage: string; // ?
    sampleId: string;
    enabled: boolean;
    inStock: boolean;
}

export type Result = {
    items: {
        uuid: string;
        quantity: number;
        finish: OrderableFinish
    }[];
}*/


export interface ShoppingCartState {
    cart: Cart;
    isModalOpen: boolean;
}

const module: Module<ShoppingCartState, StoreModules> = {
    namespaced: true
};

module.state = {
    cart: new Cart(),
    isModalOpen: false
};

module.getters = {
    cart: state => state.cart,
    count: state => state.cart.items.reduce((prev: number, curr: OrderableItem) => prev + curr.quantity, 0),
    existsInCart: state => (id: string | string[]) => {
        return hasCartEntry(state.cart, id);
    },
    limit: (): number => PersonalizationHelper.getConfigFromCookie().maxItems,
    isEmpty: (state, getters): boolean => getters.count === 0,
    isWithinLimit: (state, getters): boolean => getters.count <= getters.limit,
    isLimitReached: (state, getters): boolean => getters.limit <= getters.count,
    doesCartContainsItemsWithDifferentPanelOrSidingLocation: (state, getters): boolean => {
        const orderableItems: OrderableItem[] = state.cart.items;

        if(orderableItems.length === 0) {
            return false;  // No items in the cart
        }

        const firstLocation = orderableItems[0].panelOrSidingLocation;

        for (const orderableItem of orderableItems) {
            if(orderableItem.panelOrSidingLocation !== firstLocation) {
                return true;  // Found an item with a different panelOrSidingLocation
            }
        }

        return false;  // All items have the same panelOrSidingLocation
    },
    isModalOpen: state => state.isModalOpen
};

module.actions = {
    _addItem({commit}, payload: OrderableItem) {
        if (!(payload instanceof OrderableItem)) {
            throw new Error('Please provide a full orderable item!');
        }
        commit('addItem', payload);
    },
    addItem: _.debounce(({dispatch}, payload: OrderableItem) => dispatch('_addItem', payload), 500,
        {leading: true, trailing: false}),
    removeItem({commit}, payload: OrderableItem) {
        if (!(payload instanceof OrderableItem)) {
            throw new Error('Please provide a full orderable item!');
        }
        commit('removeItem', payload);
    },
    openBasket({commit}, value: boolean) {
        commit('openBasket', value);
    },
    clearCart({commit}) {
        commit('clearCart');
    }
};


module.mutations = {
    openBasket(state, value: boolean) {
        state.isModalOpen = value;
    },
    addItem(_state, item: OrderableItem) {
        if (!item.id) return;
        mergeCartEntry(_state.cart, item);
    },
    removeItem(state, item: OrderableItem) {
        // ToDo: Move all to connection with BACKEND
        if (!item.id) return;
        const {cart} = state;
        const cartEntry = findCartEntry(cart, item.id);
        if (!cartEntry) {
            return;
        }
        if (item.quantity == 0) {
            removeCartEntry(cart, item.id);
            return;
        }
        cartEntry.quantity -= item.quantity;
        if (cartEntry.quantity <= 0) {
            removeCartEntry(cart, item.id);
            return;
        }
        cartEntry.state = item.state;
    },
    clearCart(state) {
        state.cart.items = [];
    }
};


export default module;
