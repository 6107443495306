















import ArrowRightThin from '@/assets/svg/icons/icon-arrow-right-thin.svg?inline';
import { DeliveryResponse, InspirationPageNode } from '@/models/deliveryResponses';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ProjectSlide', components: { ArrowRightThin }
})
export default class ProjectSlide extends Vue {
  @Prop({ required: true }) project!: { url: string; productLines: string[]; title: string; image: string };
  private response: DeliveryResponse<InspirationPageNode> | null = null;
  private src: string = '';
  private link: string = '#';
  private alt: string = '';
  private imageLoaded: boolean = false;

  get page() {
    return this.response?.results[0];
  }

  private get contextPath() {
    return _mgnl.contextPath;
  }

  private getImage() {
    return this.contextPath + this.project.image;
  }
}
