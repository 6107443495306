




























































import TrLogoTrespa from '@/components/shared/TrLogoTrespa.vue';
import PersonalizationHelper from '@/utils/PersonalizationHelper';
import {SplashScreenImage, SplashScreenOption,} from '@/typings/interfaces/splash-screen';
import {isMobile} from '@/utils/helpers';
import {gsap, Power1} from 'gsap';
import {Component, Prop, Vue} from 'vue-property-decorator';
import TrButton from '@/components/shared/TrButton.vue'

const notSelected = -1;

@Component({
  name: 'splashScreen',
  components: {
    TrLogoTrespa,
    TrButton
  },
})
export default class SplashScreen extends Vue {
  private readonly swiperOptions = {
    centeredSlides: true,
    autoplay: {
      delay: 1000,
    },
    speed: 500,
    loop: true,
    effect: 'fade',
  };

  @Prop(Array) public readonly sliderImages!: Array<SplashScreenImage>;
  @Prop(String) public readonly title!: Array<SplashScreenImage>;
  @Prop(String) public readonly text!: Array<SplashScreenImage>;
  @Prop(String) public readonly homePageLink!: string;
  @Prop(Array) public readonly options!: Array<SplashScreenOption>;

  public activeOption = notSelected; // activeOption
  public activeSlide = 0;

  public initialDelay = 1;
  public slideDelay = 5;
  private mainTimeline: gsap.core.Timeline = gsap.timeline({
    repeat: -1,
    delay: this.initialDelay + this.slideDelay,
  });
  private mq: MediaQueryList = matchMedia('only screen and (min-width: 48em)');

  public mounted() {
    const arbitraryNumber = 99;
    (this.$refs.sliderImgElement as Array<HTMLImageElement>)
        .reverse()
        .forEach((elem: HTMLImageElement, index: number) => {
          this.mainTimeline.set(elem, {
            opacity: 0,
            zIndex: index + arbitraryNumber,
          });
          this.mainTimeline.to(elem, {opacity: 1, duration: 1});
          this.mainTimeline.set(elem, {opacity: 1, delay: this.slideDelay});
        });
  }

  logoClickHandler() {
    PersonalizationHelper.setCookieFromIndex(3);
    window.location.href = this.homePageLink;
  }

  get optionSelected(): boolean {
    return this.activeOption != notSelected;
  }

  get selectedOptionImage(): SplashScreenImage {
    if (!this.optionSelected) return {} as SplashScreenImage;

    return this.options[this.activeOption].image;
  }

  public getButtonText(index: number) {
    return this.isSelected(index) ? this.$t('splashScreen.button.enterWebsite') : this.options[index].title;
  }

  get useLightText() {
    if (this.optionSelected) return this.selectedOptionImage.textColorLight;
    return this.sliderImages[this.activeSlide].textColorLight;
  }

  public isSelected(index: number) {
    return index === this.activeOption;
  }

  public isActiveSlide(index: number) {
    return index === this.activeSlide;
  }

  private touchEndHappened = false;

  handleOptionSelectionMouseEnter(event: MouseEvent, index: number) {
    if (this.touchEndHappened) {
      return;
    }
    this.handleOptionSelection(event, index);
  }

  public handleOptionSelection(event: MouseEvent, index: number) {
    if (event.type === 'touchend') {
      this.touchEndHappened = true;
    }

    if (event.type === 'click' && (!isMobile || (this.touchEndHappened))) {
      const params = new URLSearchParams(window.location.search);
      const currentUri = new URL(window.location.href);
      const returnUri = currentUri.origin + params.get('returnUri');

      PersonalizationHelper.setCookieFromIndex(index);
      if (returnUri) {
        window.location.href = returnUri;
      }
      return;
    }

    if (!this.isSelected(index)) {
      this.activeOption = index;
      return;
    }

    this.$emit('navigate');
  }

  public handleOptionDeSelection() {
    if (this.touchEndHappened) {
      return;
    }
    this.activeOption = notSelected;
  }

  public handleOptionSelectionMobile(event: MouseEvent, index: number) {
    if (!this.isSelected(index)) {
      this.activeOption = index;
      return;
    }

    this.$emit('navigate');
  }

  /// transitions
  private backgroundEnterAnimation(el: HTMLElement, done: () => void) {
    gsap.from(el, {
      duration: 2,
      delay: this.initialDelay,
      opacity: 0,
      ease: Power1.easeOut,
      onComplete: () => {
        done();
      },
    });
  }

  private imageEnterAnimation(el: HTMLElement, done: () => void) {
    this.mainTimeline.pause();
    gsap.set(el, {zIndex: 99});
    gsap.from(el, {
      duration: 1.5,
      delay: 0,
      opacity: 0,
      onComplete: () => {
        done();
      },
    });
  }

  private imageLeaveAnimation(el: HTMLElement, done: () => void) {
    gsap.to(el, {
      duration: 0.5,
      delay: 0.5,
      opacity: 0,
      onComplete: () => {
        done();
        this.mainTimeline.play();
      },
    });
  }
}
