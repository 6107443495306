import {getImageSize} from '@/utils/getImageSize';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/dist/default-skin/default-skin.css';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';
import 'photoswipe/dist/photoswipe.css';
import {DirectiveOptions} from 'vue/types/options';

type PwspHTMLImageElement = {
    _pwsp: {
        gid: string;
    }
} & HTMLImageElement;

interface PswpTriggerOptions {
    gid: string;
}

const DEFAULT = 'default';
type PswpStore = { [key: string]: { items: PwspHTMLImageElement[] } };
const store: PswpStore = {
    [DEFAULT]: {
        items: []
    }
};
const pswpElement: HTMLElement = document.querySelectorAll('.pswp')[0] as HTMLElement;


async function clickHandler(this: PwspHTMLImageElement) {
    const {gid} = this._pwsp;
    const found = findInStore(store, gid, this);
    if (found) {
        const number = store[gid].items.indexOf(found);
        if (number > -1) {
            await openPhotoSwipe(gid, number);
        }
        return;
    }
    console.warn('This image is not part of a gallery');
}

const onlyVisible = <T extends HTMLElement>(elements: T[]) => {
    return elements.filter(element => {
        return element.offsetWidth > 0 && element.offsetHeight > 0;
    });
};

function getThumbBounds(items: PwspHTMLImageElement[]): (index: number) => { w: number; x: number; y: number } {
    return (index: number) => {
        const element: PwspHTMLImageElement = items[index];
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const bcr = element.getBoundingClientRect();

        return {
            x: bcr.left,
            y: bcr.top + pageYScroll,
            w: bcr.width
        };
    };
}

async function openPhotoSwipe(gid: string, index) {
    const storeItems = store[gid].items;
    const visibleItems = onlyVisible(storeItems);
    const items: PhotoSwipe.Item[] = await Promise.all(visibleItems.map(async (value: PwspHTMLImageElement) => {
        const src = (value.dataset.largeSrc || value.src);
        const msrc = (value.dataset.msrc || src);
        const dimensions: { width: number; height: number } = await getImageSize(src);
        return {
            src,
            msrc,
            w: dimensions.width,
            h: dimensions.height
        };
    }));
    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, {
        index: index,
        getThumbBoundsFn: getThumbBounds(visibleItems),
        bgOpacity: 0.4,
        showHideOpacity: true,
        galleryUID: gid as any
    });
    gallery.init();
}

const findInStore = (store: PswpStore, gid: string, item: HTMLImageElement) => {
    return store[gid].items.find(value => {
        return value._pwsp.gid === gid && value.src === item.src;
    });
};

export const addOrCreateWith = (store: PswpStore, gid: string, item: HTMLImageElement) => {
    if (!store[gid]) {
        store[gid] = {
            items: []
        };
    }
    (item as PwspHTMLImageElement)._pwsp = {
        gid
    };
    store[gid].items.push(item as PwspHTMLImageElement);
};

const photoswipeParseHash = function () {
    const hash = window.location.hash.substring(1),
        params = {};
    if (hash.length < 5) {
        return params;
    }
    const vars = hash.split('&');
    for (let i = 0; i < vars.length; i++) {
        if (!vars[i]) {
            continue;
        }
        const pair = vars[i].split('=');
        if (pair.length < 2) {
            continue;
        }
        params[pair[0]] = pair[1];
    }
    return params;
};
window.addEventListener('load', () => {
    const hashData: { pid: string, gid: string } = photoswipeParseHash() as unknown as any;
    if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.gid, parseInt(hashData.pid, 10) - 1);
    }
});


const pswpTrigger: DirectiveOptions = {
    inserted: (el, binding) => {
        const value: PswpTriggerOptions = binding.value || {};
        const name = value.gid || DEFAULT;
        if (el instanceof HTMLImageElement) {
            addOrCreateWith(store, name, el);
        }
    },
    bind: (el) => {
        el.style.cursor = 'pointer';
        el.addEventListener('click', clickHandler);
    },
    unbind: (el) => {
        el.removeEventListener('click', clickHandler);
    }
};
export default pswpTrigger;
