











import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { CartMixin } from '@/utils/mixins/CartMixin';
import TrModal from '@/components/shared/TrModal.vue';
import TrButton from '@/components/shared/TrButton.vue';
import CartLimitReachedModal from '@/components/sample-selector/CartLimitReachedModal.vue';
import CartInfoModal from '@/components/sample-selector/CartInfoModal.vue';

@Component({
  components: {
    CartInfoModal,
    CartLimitReachedModal,
    TrModal,
    TrButton,
  },
})
export default class AddToCartLink extends mixins(CartMixin) {
  @Prop({ required: false }) readonly cssClass!: string;
  @Prop({ required: true }) readonly finishId!: string;

  private showCartLimitReachedModal: boolean = false;
  private showInfoModal: boolean = false;

  async addToCartHandler() {
    if (this.isCartLimitReached) {
      this.setShowCartLimitReachedModal(true);
      return;
    }

    await this.addToCart(this.finishId, 1);

    if (this.doesCartContainsItemsWithDifferentPanelOrSidingLocation) {
      this.setShowInfoModal(true);
    }
  }

  setShowCartLimitReachedModal(isShow: boolean) {
    this.showCartLimitReachedModal = isShow;
  }

  setShowInfoModal(isShow: boolean) {
    this.showInfoModal = isShow;
  }
}
