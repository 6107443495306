import {Cart} from '@/store/shoppingCart';
import Vue from 'vue';
import Component from 'vue-class-component';
import {OrderableItem} from '@/typings/types/OrderableItem';

@Component
export class CartMixin extends Vue {

    get cart(): Cart {
        return this.$store.getters['shoppingCart/cart'];
    }

    get cartCount(): number {
        return this.$store.getters['shoppingCart/count'];
    }

    get cartLimit(): number {
        return this.$store.getters['shoppingCart/limit'];
    }

    get isValidCart(): boolean {
        const isNotEmpty = !this.$store.getters['shoppingCart/isEmpty'];
        const isWithinLimit = this.$store.getters['shoppingCart/isWithinLimit'];
        for (const it of this.cart.items) {
            if (!it.isOrderable())
                return false;
        }
        return isNotEmpty && isWithinLimit;
    }

    get isCartLimitReached(): boolean {
        return this.$store.getters['shoppingCart/isLimitReached'];
    }

    get doesCartContainsItemsWithDifferentPanelOrSidingLocation(): boolean {
        return this.$store.getters['shoppingCart/doesCartContainsItemsWithDifferentPanelOrSidingLocation'];
    }

    async addToCart(id: string, quantity: number) {
        await this.$store.dispatch('shoppingCart/addItem', await OrderableItem.create(id, quantity));
    }
}
