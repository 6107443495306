







import {PortalTarget} from 'portal-vue';
import {CreateElement, RenderContext} from 'vue';
import {Component, Vue} from 'vue-property-decorator';


@Component({
  components: {PortalTarget},
})
export default class PortalWrapper extends Vue {
  get fadeTransition() {
    return {
      functional: true,
      render(h: CreateElement, context: RenderContext) {
        return h('transition-group', {
          props: {
            tag: 'div',
            name: 'tr-modal-fade',
            mode: 'out-in'
          }
        }, context.children);
      }
    };
  }
  get trSidePanelTransition() {
    return {
      functional: true,
      render(h: CreateElement, context: RenderContext) {
        return h('transition-group', {
          props: {
            tag: 'div',
            name: 'tr-slide-panel-transition',
            mode: 'out-in'
          }
        }, context.children);
      }
    };
  }
}
