import productSearchApi from '@/api/productSearchApi';
import {StoreModules} from '@/store';
import * as ProductSearch from '@/typings/interfaces/ProductSearch';
import {ActiveFilters, OneOrManyOf} from '@/typings/types/ActiveFilters';
import {BreakPoint, mqMinWidth} from '@/utils/mq';
import {AxiosResponse} from 'axios';
import _ from 'lodash';
import {Module} from 'vuex';

/* Exclusion lists for the sample selector */
const excludedProdLines = {
    'sidings': [
        'trespa--izeon-',
        'trespa--meteon-',
        'trespa--toplab-',
    ],
    'exterior': [
        'trespa--toplab-',
        'pura--nfc',
        'trespa--pura-'
    ],
    'interior': [
        'trespa--meteon-',
        'trespa--pura-',
        'trespa--izeon-',
        'pura--nfc'
    ]
}

export type NameValue = { name: string, value: string | number | boolean };
export type SampleType = 'exterior' | 'interior' | 'sidings';

export interface ProductSearchState {
    activeFilters: ActiveFilters;
    results?: ProductSearch.Response;
    lastResultsUpdate: number;
    selectedItem?: string;
    filtersOpen: boolean;
    sampleSearchMode: SampleType | null;
}

const module: Module<ProductSearchState, StoreModules> = {
    namespaced: true
};


export const addFilter = (state, {name, value}: NameValue) => {
    if (!Array.isArray(state.activeFilters[name])) {
        state.activeFilters[name] = [];
    }
    if (!(state.activeFilters[name] as Array<number | string | boolean>).includes(value)) {
        (state.activeFilters[name] as Array<number | string | boolean>).push(value);
    }
};

export const removeFilter = (state, {name, value}: NameValue) => {
    if (Array.isArray(state.activeFilters[name])) {
        state.activeFilters[name] = (state.activeFilters[name] as string[]).filter(v => value != v);
    }
};

export const hasFilter = (state, {name, value}: NameValue) => {
    const activeFilter: OneOrManyOf<number | string | boolean> = state.activeFilters[name];
    if (Array.isArray(activeFilter)) {
        return activeFilter.includes(value);
    }
    return activeFilter === value;
};


module.state = {
    activeFilters: {},
    results: undefined,
    lastResultsUpdate: 0,
    selectedItem: undefined,
    filtersOpen: mqMinWidth(BreakPoint.MD).matches,
    sampleSearchMode: null
};

module.getters = {
    results: state => state.results,
    lastResultsUpdate: state => state.lastResultsUpdate,
    activeFilters: state => state.activeFilters,
    selectedItem: state => state.selectedItem,
    excludedProductLines: state => state.activeFilters.excludedProductLines,
    filtersOpen: state => state.filtersOpen,
    sampleSearchMode: state => state.sampleSearchMode,
    hasFilter: state => (name: string, value: number | string | boolean): boolean => {
        return hasFilter(state, {name, value});
    }
};


module.actions = {
    performSearch: ({commit, state}) => {
        commit('setSelectedItem', null);
        return new Promise<void>((resolve, reject) => {
            productSearchApi.performSearchWithFilters(state.activeFilters)
                .then((value: AxiosResponse<ProductSearch.Response>) => {
                    const data: ProductSearch.Response = value.data;
                    commit('setResults', data);
                    resolve();
                }).catch(reject);
        });
    },
    debouncedSearch: _.debounce(({dispatch}) => dispatch('performSearch'), 100),
    setActiveFilters({dispatch, commit}, mode: SampleType) {
        commit('setActiveFilters', mode);
        return dispatch('debouncedSearch');
    },
    setSampleSearchMode({dispatch, commit}, mode: SampleType) {
        commit('setSampleSearchMode', mode);
        return dispatch('debouncedSearch');
    },
    addFilter({dispatch, commit}, {name, value}: NameValue) {
        commit('addFilter', {name, value});
        return dispatch('debouncedSearch');
    },
    removeFilter({dispatch, commit}, {name, value}: NameValue) {
        commit('removeFilter', {name, value});
        return dispatch('debouncedSearch');
    },
    setQuery({dispatch, commit}, query: string) {
        commit('setQuery', query);
        return dispatch('debouncedSearch');
    },
    setSelectedItem({commit}, itemId: string) {
        commit('setSelectedItem', itemId);
    },
    toggleSelectedItem({commit, state}, itemId: string) {
        const newValue = (state.selectedItem === itemId) ? null : itemId;
        commit('setSelectedItem', newValue);
    },
    setFiltersOpen({commit}, open: boolean) {
        commit('setFiltersOpen', open);
    },
    replaceFilters({dispatch, commit}, filters: ActiveFilters) {
        commit('replaceFilters', filters);
        return dispatch('debouncedSearch');
    }
};


module.mutations = {
    setResults(state, data: ProductSearch.Response) {
        state.results = data;
        state.lastResultsUpdate = Date.now();
    },
    addFilter,
    removeFilter,
    setQuery(state, query: string = '') {
        query = query.trim();
        state.activeFilters.q = query;
    },
    setSelectedItem(state, itemId: string) {
        state.selectedItem = itemId;
    },
    setFiltersOpen(state, open: boolean) {
        state.filtersOpen = open;
    },
    replaceFilters(state, filters: ActiveFilters) {
        state.activeFilters = filters;
    },
    setActiveFilters(state, sampleType: SampleType) {
        state.activeFilters.excludedProductLines = excludedProdLines[sampleType];
    },
    setSampleSearchMode(state, mode: SampleType) {
        state.sampleSearchMode = mode;
    },
};

export default module;

