export enum FilteringOperators {
    EQ = 'eq',
    NE = 'ne',
    GT = 'gt',
    LT = 'lt',
    LTE = 'lte',
    GTE = 'gte',
    IN = 'in',
    NOT_IN = 'not-in',
    LIKE = 'like',
    NULL = 'null',
    DEFAULT = EQ,
}

export type AllowedValue = string | Date | Array<string | Date>;

export class Filter {
    private readonly property: string;
    private readonly operator: FilteringOperators;
    private readonly value: string;

    constructor(property: string, value: AllowedValue, operator: FilteringOperators = FilteringOperators.EQ) {
        this.property = property;
        this.operator = operator;
        this.value = this.transform(value);
    }

    public toObject(): { [p: string]: string } {
        return {
            [`${this.property}[${this.operator}]`]: this.value
        };
    }

    private transform(v: AllowedValue): string {
        let joinWith = '|';
        if (this.operator === FilteringOperators.IN ||
            this.operator === FilteringOperators.NOT_IN) {
            joinWith = '~';
        }
        if (!Array.isArray(v)) {
            v = [v];
        }
        return v.map(value1 => {
            if (value1 instanceof Date) {
                return value1.toISOString();
            } else {
                return value1;
            }
        }).join(joinWith);
    }

}

export const parseFilters = (filters: Filter | Filter[] | undefined): { [p: string]: string } => {
    if (!filters) {
        return {};
    }
    if (!Array.isArray(filters)) {
        filters = [filters];
    }
    return filters.map(value => value.toObject()).reduce((collector, current) => {
        const key: string = Object.keys(current)[0];
        collector[key] = current[key];
        return collector;
    }, {});
};
