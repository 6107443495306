export const toggleBodyOverflow = (indicator: boolean) => {
    const el = document.querySelector('body') as HTMLElement;
    indicator
        ? el.classList.add('overflow-hidden')
        : el.classList.remove('overflow-hidden');
};

export const isMobile = window.matchMedia('(max-width: 1024px)').matches;

export function dictIndexOf(dictionary, value) {
    for (const i in dictionary) {
        if (dictionary[i] === value)
            return i;
    }
    return null;
}
