import api from '@/api/api';
import * as ProductSearch from '@/typings/interfaces/ProductSearch';
import {ActiveFilters} from '@/typings/types/ActiveFilters';
import {AxiosResponse} from 'axios';
import {ISetupCache, setupCache} from 'axios-cache-adapter';
import qs from 'qs';

const cache: ISetupCache = setupCache({
    maxAge: 1 * 60 * 1000,
    exclude: {
        query: false
    }
});


export default {
    performSearch(): Promise<AxiosResponse<ProductSearch.Response>> {
        return api.get<ProductSearch.Response>('products/v1/', {
            params: {
                size: 1000
            }
        });
    },
    performSearchWithFilters(filters: ActiveFilters, cached: boolean = false): Promise<AxiosResponse<ProductSearch.Response>> {
        return api.get<ProductSearch.Response>('products/v1/', {
            adapter: cached ? cache.adapter : undefined,
            params: {
                ...filters,
                size: 1000
            },
            paramsSerializer: params => qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        });
    }
};
