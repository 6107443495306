
















import ChevronDown from '@/assets/svg/icons/icon-chevron-down.svg?inline';
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';

@Component({name: 'AccordionWrap', components: {ChevronDown}})
export default class AccordionWrap extends Vue {
  @Prop({required: true}) public title!: string;
  @Prop({default: true}) public isArticle!: boolean;
  @Ref('acc-btn') public readonly accBtn!: HTMLElement;
  private isAccordionOpen = false;

  private element() {
    return this.isArticle ? 'article' : 'div';
  }

  @Watch('isAccordionOpen')
  private onChildChanged(val: string) {
    this.accBtn.style.maxHeight = val ? this.accBtn.scrollHeight + 'px' : '';
    this.accBtn.style.margin = val ? '10px 0px 10px 0px' : '';
  }

  private mounted(): void {
    this.accBtn.style.maxHeight = 0 + 'px';
  }
}
