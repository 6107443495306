

















































import {UserTypeDefinitions} from '@/typings/interfaces/Misc';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'TrDropdown',
})
export default class TrDropdown extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  public options!: Array<UserTypeDefinitions>;

  @Prop({type: Boolean, default: true}) readonly chevron!: boolean;
  @Prop({type: Boolean, default: false}) readonly isLink!: boolean;
  @Prop({type: Boolean, default: false}) readonly isLarge!: boolean;
  @Prop({type: Boolean, default: false}) readonly emit!: boolean;
  @Prop({type: Number, default: 0}) readonly selectedIndex!: number;

  dropdownActive = false;
  dropUp = false;
  mainTitle: UserTypeDefinitions = this.options[this.selectedIndex];

  get dropDownClasses() {
    return {
      'tr-dropdown__content--large': this.isLarge,
      'tr-dropdown__content--drop-up': this.dropUp
    };
  }

  mounted() {
    this.dropUp = this.calcDropUp();
  }

  toggle() {
    this.dropdownActive = !this.dropdownActive;
  }

  selectedItem(index: number) {
    this.mainTitle = this.options[index];
    if (this.emit) {
      this.$emit('click', index);
    }
  }

  /**
   * For now check if there is more then 200 pixels space (TODO: refactor to dynamically check based on content)
   * Has been tried multiple ways to do it dynamically but there were some issues with the HTML not being rendered/visible
   */
  calcDropUp() {
    const body = document.getElementsByTagName('body')[0];
    const bodyHeight = (body as HTMLElement).offsetHeight;
    const elOffset = (this.$el as HTMLElement).offsetTop;

    return (bodyHeight - elOffset) < 200;
  }
}
