










import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Badge extends Vue {
  @Prop({required: true}) value!: string | number | undefined;

  get showBadge(): boolean {
    const value = this.value;
    if (typeof value === 'string') {
      return value.length > 0;
    }
    if (typeof value === 'number') {
      return value != 0;
    }
    return false;
  }

  get valueGetter() {
    return this.value;
  }
}
