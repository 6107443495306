import {Loader} from '@googlemaps/js-api-loader';

export type Google = typeof google;

class GoogleMapsApi {
    private _google?: Google;
    private _key?: string;

    load(key: string): Promise<Google> {
        if (this._key && this._key !== key) {
            throw new Error('This API instance was already initialized with another key.');
        }
        this._key = key;
        return new Promise<Google>((resolve, reject) => {
            if (this._google) {
                resolve(this._google);
            }
            new Loader({
                apiKey: key,
                libraries: ['geometry']
            }).load().then(google => {
                this._google = google;
                resolve(this._google);
            }).catch(reject);
        });
    }

    /**
     * The usage of this method incurs a cost
     */
    search(key: string, address: string): Promise<google.maps.GeocoderResponse> {
        return new Promise<google.maps.GeocoderResponse>((resolve, reject) => {
            this.load(key).then((google: Google) => {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    address: address
                }).then(resolve).catch(reject);
            });
        });
    }
    /**
     * The usage of this method is free
     */
    getLocationDistanceInKm(key: string, fromPoint: google.maps.LatLngLiteral, toPoint: google.maps.LatLngLiteral): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            this.load(key).then((google: Google) => {
                const fromLatLng = new google.maps.LatLng(fromPoint);
                const toLatLng = new google.maps.LatLng(toPoint);
                const meters: number = google.maps.geometry.spherical.computeDistanceBetween(fromLatLng, toLatLng);
                resolve(Math.ceil(meters / 1000));
            }).catch(reject);
        });
    }

}

export default new GoogleMapsApi();
