export enum ProductLineType {
    TOPLAB = 'trespa--toplab-',
    METEON = 'trespa--meteon-',
    IZEON = 'trespa--izeon-',
    PURA = 'pura--nfc',
    EXPRESS = 'trespa--express'
}

export enum ProductLineImages {
    TOPLAB = 'toplab.jpg',
    METEON = 'meteon.jpg',
    IZEON = 'izeon.jpg',
    PURA = 'pura.jpg',
    EXPRESS = 'express.jpg'
}
