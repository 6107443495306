// Sync with mq.scss
export enum BreakPoint {
    XS = 0,
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
    XXL = 1400
}

export const mqMinWidth: (mq: BreakPoint) => MediaQueryList = (mq: BreakPoint) => window.matchMedia(
    `(min-width: ${mq}px)`);
export const mqMaxWidth: (mq: BreakPoint) => MediaQueryList = (mq: BreakPoint) => window.matchMedia(
    `(max-width: ${mq - 0.02}px)`);
