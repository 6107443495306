import api from '@/api/api';
import {Filter, parseFilters} from '@/api/DeliveryEndpoint';
import {ProductDeliveryEndpoint} from '@/typings/interfaces/ProductDeliveryEndpoint';
import {AxiosResponse} from 'axios';
import {ISetupCache, setupCache} from 'axios-cache-adapter';
import qs from 'qs';
import Results = ProductDeliveryEndpoint.Results;

const hour = 60 * 60 * 1000;
const cache: ISetupCache = setupCache({
    maxAge: hour,
    exclude: {
        query: false
    }
});


const methods = {
    getDecorSets(filters?: Filter | Filter[]): Promise<AxiosResponse<Results<ProductDeliveryEndpoint.DecorSet>>> {
        return api.get<Results<any>>('decorSets/v1/', {
            params: parseFilters(filters),
            adapter: cache.adapter,
            paramsSerializer: params => qs.stringify(params, {
                arrayFormat: 'repeat'
            })
        });
    }
};
export default methods;
