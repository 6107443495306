












import {Component, Prop, Vue} from 'vue-property-decorator';
import TrModal from "@/components/shared/TrModal.vue";
import TrButton from "@/components/shared/TrButton.vue";

@Component({
  components: {
    TrModal,
    TrButton
  }
})
export default class cartLimitReachedModal extends Vue {
  @Prop({default: false}) private showModal!: boolean;

  get sampleSelectorLink() {
    return _mgnl.siteSettings.sampleSelectorLink;
  }

  close() {
    this.$emit('close');
  }
}
