













import inspirationApi, { InspirationApiConfig } from '@/api/inspirationApi';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ArrowRightThin from '@/assets/svg/icons/icon-arrow-right-thin.svg?inline';
import ProjectSlide from '@/components/global/ProjectSlide.vue';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { InspirationCard, InspirationSearchResponse } from '@/typings/interfaces/InspirationSearch';
import { AxiosResponse } from 'axios';

@Component({
  name: 'Projects',
  components: {
    Swiper,
    SwiperSlide,
    ArrowRightThin,
    ProjectSlide,
  },
})
export default class Projects extends Vue {
  @Prop() filterBy!: string;
  @Prop() filterValue!: string;
  @Prop({ default: 6 }) limit!: number;
  @Ref('swiperRef') private swiperRef!: typeof Swiper;

  private loading = true;
  private projects: InspirationCard[] = [];

  swiperOption = {
    slidesPerView: '1.5',
    keyboard: {
      enabled: true,
    },
    centeredSlides: false,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
    },
    breakpoints: {
      576: {
        slidesPerView: '2.2',
        spaceBetween: 15,
      },
      768: {
        slidesPerView: '3.2',
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: '3.2',
        spaceBetween: 20,
      },
    },
  };

  mounted() {
    const config: InspirationApiConfig = {
      params: {
        size: this.limit,
        'marketSegment.name': this.filterBy === 'marketSegment' ? this.filterValue : null,
        'productLine.name': this.filterBy === 'productLine' ? this.filterValue : null,
      },
    };

    inspirationApi.search(config).then((response: AxiosResponse<InspirationSearchResponse>) => {
      this.projects = response.data.results;
    });
  }

  get swiper() {
    return this.swiperRef.$swiper;
  }
}
