













import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { ImageHelpers } from '@/utils/imageHelpers';
import { FilterHelpers } from '@/components/filter/FilterHelpers';
import { ProductLineType} from '@/typings/enums/ProductLineType';

@Component({ name: 'SidePanelFinish' })
export default class SidePanelFinish extends Mixins(FilterHelpers, ImageHelpers) {
  @Prop() lines: ProductLineType[] | undefined;

  beforeMount() {
    const order = [ProductLineType.METEON, ProductLineType.PURA, ProductLineType.TOPLAB, ProductLineType.IZEON, ProductLineType.EXPRESS]

    this.lines!.sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b);
    });
  }
}
