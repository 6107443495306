









import TrModal from '@/components/shared/TrModal.vue';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'LanguageSelector', components: {TrModal}
})
export default class LanguageSelector extends Vue {
  private showModal: boolean = false;

  setShowModal(value: boolean) {
    this.showModal = value;
  }
}
