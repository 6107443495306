






import IconHome from '@/assets/svg/icons/icon-home.svg?inline';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'BreadcrumbsCustom',
  components: {
    IconHome,
  },
})
export default class BreadcrumbsCustom extends Vue {
}
