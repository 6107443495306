import api from '@/api/api';
import {Dealer} from '@/models/dealers';
import {AxiosResponse} from 'axios';

export default {
    search: (): Promise<AxiosResponse<Dealer>> => {
        return api.get('dealers/v1/');
    },
    performSearchWithFilters: (country: string | null, productLine: string | null): Promise<AxiosResponse<Dealer>> => {
        return api.get('dealers/v1/', {
            params: {
                country,
                productLine
            }
        });
    }
};
