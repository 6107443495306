





















import IconCancel from '@/assets/svg/icons/icon-cancel.svg?inline';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { toggleBodyOverflow } from '@/utils/helpers';
import VueI18n from 'vue-i18n';

let instanceCount = 0;

@Component({ name: 'TrSidePanel', components: { IconCancel } })
export default class TrSidePanel extends Vue {
  @Prop() title!: VueI18n.TranslateResult | undefined;

  private instanceId!: number;

  get order() {
    return this.instanceId;
  }

  get instanceKey(): string {
    return `tr-side-panel-${this.instanceId}`;
  }

  beforeCreate() {
    instanceCount += 1;
    this.instanceId = instanceCount;
  }

  mounted() {
    toggleBodyOverflow(true);
  }

  destroyed() {
    toggleBodyOverflow(false);
  }

  close() {
    this.$emit('close');
  }
}
