import api from '@/api/api';

export default {
    order: (data) => {
        return api.post('cart/v1/', data);
    },

    log: (data) => {
        return api.post('cart/v1/log', data);
    }
};
