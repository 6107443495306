export type UnitString = 'imperial' | 'metric';
export const OneMileInKilometers = 1.609344;

export type Unit = { string: UnitString; unit: string; abbreviation: string };


export const Units: { Metric: Unit, Imperial: Unit } = {
    Metric: {
        string: 'metric',
        unit: 'Kilometers',
        abbreviation: 'Km'
    },
    Imperial: {
        string: 'imperial',
        unit: 'Miles',
        abbreviation: 'Mi'
    }
};

export const findUnit: (value: string) => Unit | undefined = (value: string) => {
    return Object.values(Units).find((unit: Unit) => {
        return Object.values(unit).includes(value);
    })
}

export const convertMilesToKm = (miles: number) => {
    return convert(miles, Units.Imperial).value;
}

export const convertKmToMiles = (km: number) => {
    return convert(km, Units.Metric).value;
}

export const convert = (value: number, unit: Unit): {value: number, unit: Unit} => {
    const r: {value?: number, unit?: Unit} = {
        value: undefined,
        unit: undefined
    };

    if (unit.string === 'metric') {
        r.value = value / OneMileInKilometers;
        r.unit = Units.Imperial;
    }

    if (unit.string === 'imperial') {
        r.value = value * OneMileInKilometers;
        r.unit = Units.Metric;
    }

    return r as {value: number, unit: Unit};
};
