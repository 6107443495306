
















import TrButton from '@/components/shared/TrButton.vue';
import TopTaskIcon from '@/components/top-task/TopTaskIcon.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'MaterialsComponent', components: {
    TopTaskIcon, TrButton
  }
})
export default class MaterialsComponent extends Vue {
  @Prop({required: true, type: String}) private readonly title!: string;
  @Prop({required: true, type: String}) private readonly desc!: string;
  @Prop({required: true, type: String}) private readonly imgPath!: string;
  @Prop({required: true, type: String}) private readonly btnLink!: string;
  @Prop({required: true, type: String}) private readonly btnLabel!: string;
  @Prop({required: true, type: String}) private readonly iconPath!: string;
}
