




























import IconInfo from '@/assets/svg/icons/icon-info.svg?inline';
import IconLocation from '@/assets/svg/icons/icon-location.svg?inline';
import GoogleMaps from '@/components/global/GoogleMaps.vue';
import LocationCard from '@/components/global/LocationCard.vue';
import {Dealer} from '@/models/dealers';
import {convertKmToMiles, Unit, Units} from '@/utils/units';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'DealerCard', components: {
    LocationCard,
    IconLocation,
    IconInfo
  }
})
export default class DealerCard extends Vue {
  @Prop({required: true}) dealer!: Dealer;
  @Prop({required: false}) centerLocation?: { lng: number, lat: number };
  @Prop({default: false}) isActive?: boolean;
  @Prop({default: true}) showAddress?: boolean;
  @Prop({default: true}) showLocationIcon?: boolean;
  @Prop({default: false}) disableHover?: boolean;
  @Prop({default: Units.Metric}) unit!: Unit;

  private formatPhoneNumber(phone) {
    // TODO: check if this will do:
    if (phone) {
      return phone.replace(/[^0-9+]/g, '');
    }
    return phone;
  }

  private getLocationDistance(fromPoint: { lng: number, lat: number }, toPoint: { lng: number, lat: number }): number {
    const km = GoogleMaps.getLocationDistanceInKm(fromPoint, toPoint);
    if (this.unit.string === Units.Metric.string) {
      return km;
    }
    if (this.unit.string === Units.Imperial.string) {
      return Math.round(convertKmToMiles(km));
    }
    return 0;
  }
}
