import { render, staticRenderFns } from "./TrSidePanel.vue?vue&type=template&id=55f1372a&scoped=true&"
import script from "./TrSidePanel.vue?vue&type=script&lang=ts&"
export * from "./TrSidePanel.vue?vue&type=script&lang=ts&"
import style0 from "./TrSidePanel.vue?vue&type=style&index=0&id=55f1372a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f1372a",
  null
  
)

export default component.exports