









































import PreviewModal from '@/components/global/PreviewModal.vue';
import VideoComponent from '@/components/global/VideoComponent.vue';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'TextImageVideo',
  components: {
    PreviewModal,
    VideoComponent,
    Swiper,
    SwiperSlide,
  },
})
export default class TextImageVideo extends Vue {
  @Prop(String) readonly text!: string;
  @Prop(String) readonly imageOne!: string;
  @Prop(String) readonly imageTwo!: string;
  @Prop(String) readonly video!: string;
  @Prop(String) readonly videoImgPrev!: string;

  initialSlide = 0;
  showImageSlide = false;
  contentType = 'image';

  swiperOption = {
    keyboard: true,
    slideToClickedSlide: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    breakpointsInverse: true,
    breakpoints: {
      590: {
        spaceBetween: 40,
      },
    },
  };

  get content(): Array<string> {
    return this.contentType === 'image'
        ? [this.imageOne, this.imageTwo]
        : [this.video];
  }

  openModal(initialSlide: number, type = 'image') {
    this.contentType = type;

    this.initialSlide = initialSlide;
    this.showImageSlide = true;
  }

  closeImageSlide() {
    this.showImageSlide = false;
  }
}
