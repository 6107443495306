




























import newsletterApi, {MailupForm} from '@/api/newsletterApi';
import TrButton from '@/components/shared/TrButton.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

const isPrivacyChecked = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('Please accept Privacy policy'));
  }
  callback();
};

@Component({
  components: {TrButton},
})
export default class NewsletterComponent extends Vue {
  @Prop({required: true}) private groupId!: string;
  @Prop({required: true}) readonly privacyPolicyLink!: string;
  @Prop({required: true}) readonly privacyPolicyLabel!: string;
  @Prop({required: true}) readonly newsletterButtonLabel!: string;
  @Prop({required: true}) readonly emailLabel!: string;
  @Prop({required: true}) readonly sentMessage!: string;

  private didSubmit = false;
  private sending = false;

  private form: MailupForm & {privacy: boolean} = {
    Email: '',
    privacy: false
  }
  private rules = {
    Email: [
      {
        required: true,
        message: 'Please input email address',
        trigger: 'blur',
      },
      {
        type: 'email',
        message: 'Please input correct email address',
        trigger: ['blur', 'change'],
      },
    ],
    privacy: [
      {validator: isPrivacyChecked, trigger: 'change', required: true},
    ],
  }

  private submit() {
      this.sending = true;
      (this.$refs.form as HTMLFormElement).validate((valid) => {
        if (valid) {
          newsletterApi.subscribe(this.groupId, this.form).then(() => {
            (this.$refs.form as HTMLFormElement).resetFields();
            this.didSubmit = true;
          }).catch(reason => {
            console.error('Submission failed', reason);
          }).finally(() => {
            this.sending = false;
          })
        } else {
          console.error('Failed to submit');
          this.sending = false;
          return false;
        }
      });

  }

}
