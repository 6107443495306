









import IconShopping from '@/assets/svg/icons/icon-shopping.svg?inline';
import Badge from '@/components/navigation/Badge.vue';
import TrButton from '@/components/shared/TrButton.vue';
import {BreakPoint, mqMinWidth} from '@/utils/mq';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {TrButton, IconShopping, Badge},
})
export default class OrderSampleButton extends Vue {
  @Prop({required: true, default: '#'}) link!: string;

  // private showFullButton: boolean = false;

  get basketItemCount() {
    // return 3;
    return this.$store.getters['shoppingCart/count'];
  }

  mounted() {
    const mq = mqMinWidth(BreakPoint.XL);
    // this.showFullButton = mq.matches;
    mq.addEventListener('change', (e) => {
      // this.showFullButton = e.matches;
      this.$store.dispatch('shoppingCart/openBasket', false);
    });
  }

  public openSampleSelector() {
    window.location.href = this.link + "#shopping-bag";
  }


}
