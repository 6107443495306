import googleMapsApi, {Google} from '@/api/googleMapsApi';
import {StoreModules} from '@/store';
import _ from 'lodash';
import {Module} from 'vuex';

export interface GeocodingState {
    key?: string;
    address?: string;
    results?: google.maps.GeocoderResult;
    lastResultsUpdate: number;
    google?: Google;
}

const module: Module<GeocodingState, StoreModules> = {
    namespaced: true
};

module.state = {
    key: undefined,
    address: undefined,
    results: undefined,
    lastResultsUpdate: 0
};

module.getters = {
    results: state => state.results,
    key: state => state.key,
    address: state => state.address,
};


module.actions = {
    performSearch: ({commit, state}) => {
        if (state.key && state.address && state.address != '') {
            googleMapsApi.search(state.key, state.address).then((response: google.maps.GeocoderResponse) => {
                const result: google.maps.GeocoderResult = response.results[0];
                commit('setResults', result);
            });
        }
    },
    debouncedSearch: _.debounce(({dispatch}) => dispatch('performSearch'), 1000, {trailing: true, leading: false}),
    setAddress({dispatch, commit},   address: string) {
        commit('setAddress', address);
        dispatch('debouncedSearch');
    },
    setKey({commit}, key: string) {
        commit('setKey', key);
    }
};

module.mutations = {
    setResults(state, data: google.maps.GeocoderResult) {
        state.results = data;
        state.lastResultsUpdate = Date.now();
    },
    setAddress(state, address: string = '') {
        state.address = address;
    },
    setKey(state, key: string = '') {
        state.key = key;
    },
};

export default module;
