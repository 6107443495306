






import {Component, Prop, Vue} from 'vue-property-decorator';
import smoothReflow from 'vue-smooth-reflow';
import {Options} from 'vue-smooth-reflow/types/vue-smooth-reflow';

@Component({
  components: {},
  mixins: [smoothReflow],
})
export default class SmoothReflow extends Vue {
  @Prop({default: 'div', type: String}) private tag!: string;
  @Prop({default: {}, type: Object}) private options!: Options;

  mounted() {
    if (this.$smoothReflow) {
      this.$smoothReflow(this.options);
    }
  }

}
