
































import IconUserCircle from '@/assets/svg/icons/icon-user-circle.svg';
import SelectableImage from '@/components/global/SelectableImage.vue';
import TrModal from '@/components/shared/TrModal.vue';
import {SplashScreenOption} from '@/typings/interfaces/splash-screen';
import {Component, Prop, Vue} from 'vue-property-decorator';
import Cookies from "js-cookie";
import {SampleType} from "@/store/productSearch";

@Component({
  components: {TrModal, IconUserCircle, SelectableImage},
})
export default class SampleTypeSelector extends Vue {
  @Prop({required: true}) private title!: string;
  @Prop({required: true}) private btExterior!: SplashScreenOption;
  @Prop({required: true}) private btSidings!: SplashScreenOption;
  @Prop({required: true}) private btInterior!: SplashScreenOption;
  private showModal: boolean = false;

  setShowModal(value: boolean) {
    this.showModal = value;
  }

  handleSampleTypeClick(sampleType: SampleType) {
    Cookies.set('sampleSearchMode', sampleType);
    this.$store.dispatch('productSearch/setSampleSearchMode', sampleType);
    this.$store.dispatch('productSearch/setActiveFilters', sampleType);
    this.setShowModal(false);
  }
}
