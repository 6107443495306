













import SampleBasket from '@/components/sample-selector/SampleBasket.vue';
import TrModal from '@/components/shared/TrModal.vue';
import {BreakPoint, mqMinWidth} from '@/utils/mq';
import oneTimeListener from '@/utils/oneTimeListener';
import hoverIntent from 'hoverintent';
import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {SampleBasket, TrModal},
})
export default class Navbar extends Vue {

  private subNavigationSelector = '.sub-navigation';
  private subNavigationOpenClassName = 'sub-navigation--open';
  private navigationItemOpenClassName = 'navigation-item--open';

  private registry: {
    [key: string]: {
      parent: HTMLElement,
      subNavigation: HTMLElement,
      arrow: HTMLElement,
      open: boolean
    }
  } = {};
  private mq = mqMinWidth(BreakPoint.XL);
  private isMq: boolean = false;

  get isShowBasket() {
    return this.$store.getters['shoppingCart/isModalOpen'];
  }

  closeAllOthers(index: number | string) {
    Object.keys(this.registry)
        .filter(key => key != index)
        .forEach(key => {
          this.closeSubNavigation(key);
        });
  }

  openSubNavigation(index: number | string) {
    const {parent, subNavigation} = this.registry[index];
    this.closeAllOthers(index);
    subNavigation.classList.add(this.subNavigationOpenClassName);
    parent.classList.add(this.navigationItemOpenClassName);
    this.registry[index].open = true;
  }

  closeSubNavigation(index: number | string) {
    const {parent, subNavigation} = this.registry[index];
    subNavigation.classList.remove(this.subNavigationOpenClassName);
    parent.classList.remove(this.navigationItemOpenClassName);
    this.registry[index].open = false;
  }

  outsideHandler(target: EventTarget, index: number | string) {
    const {subNavigation} = this.registry[index];
    if (target && subNavigation) {
      const hasTarget = subNavigation.contains(target as Node);
      if (!hasTarget) {
        this.closeSubNavigation(index);
      }
    }
  }

  mounted() {

    window.addEventListener('scroll', this.headerScrollBehavior);
    this.headerScrollBehavior();


    this.attachNavigationHandlers();

  }

  private attachNavigationHandlers() {
    this.isMq = this.mq.matches;
    this.mq.addEventListener('change', () => {
      this.isMq = this.mq.matches;
      Object.keys(this.registry).forEach(k => {
        this.closeSubNavigation(k);
      });
    });


    const subNavigationTriggers = this.$el.querySelectorAll<HTMLElement>('.navigation-list > .navigation-item');
    subNavigationTriggers.forEach((parent: HTMLElement, index: number) => {
      const subNavigation: HTMLElement | null = parent.querySelector(this.subNavigationSelector);
      const anchor: HTMLElement | null = parent.querySelector('a');
      const arrow: HTMLElement | null = parent.querySelector('a svg');

      if (subNavigation == null || anchor == null || arrow == null) {
        return;
      }
      let touchTarget = anchor.hasAttribute('href') ? arrow : anchor;

      this.registry[index] = {
        parent, subNavigation, arrow: arrow as HTMLElement, open: false
      };

      parent.addEventListener('touchstart', e => {
        if (this.isMq) {
          // Only prevent default if we're not already open
          if (!subNavigation.classList.contains(this.subNavigationOpenClassName)) {
            e.preventDefault();
          }
          this.openSubNavigation(index);
          setTimeout(() => {
            oneTimeListener<TouchEvent>(document, 'touchstart', e => {
              this.outsideHandler(e.target as EventTarget, index);
            });
          }, 10);
        }
      });


      touchTarget.addEventListener('click', e => {
        e.preventDefault();
        if (!this.isMq) {
          this.registry[index].open ? this.closeSubNavigation(index) : this.openSubNavigation(index);
        }
      });

      touchTarget.addEventListener('touchstart', e => {
        if (!this.isMq) {
          e.stopImmediatePropagation();
          e.preventDefault();
          this.registry[index].open ? this.closeSubNavigation(index) : this.openSubNavigation(index);
        }
      });


      hoverIntent(parent, () => {
        if (this.isMq) {
          this.openSubNavigation(index);
        }
      }, () => {
        if (this.isMq) {
          this.closeSubNavigation(index);
        }
      }).options({
        sensitivity: 7,
        timeout: 100,
        interval: 50
      });


      parent.addEventListener('focusin', () => {
        if (this.isMq) {
          this.openSubNavigation(index);
        }
      });


      parent.addEventListener('focusout', () => {
        if (this.isMq) {
          this.closeSubNavigation(index);
        }
      });

    });
  }

  closeBasket() {
    this.$store.dispatch('shoppingCart/openBasket', false);
  }

  created() {
    this.$root.$on('hamburger', this.hamburgerBehaviour);
  }


  private beforeDestroy() {
    window.removeEventListener('scroll', this.headerScrollBehavior);
  }

  private headerScrollBehavior() {
    const headerEl: HTMLElement = document.querySelector(
        '.navbar'
    ) as HTMLElement;

    if (window.scrollY > 60) {
      headerEl.classList.add('navbar--sticky');
    } else {
      headerEl.classList.remove('navbar--sticky');
    }
  }

  private hamburgerBehaviour(v: boolean) {
    const headerEl: HTMLElement = document.querySelector(
        '.navbar'
    ) as HTMLElement;

    const bodyEl: HTMLElement = document.querySelector(
        'body'
    ) as HTMLElement;
    if (v) {
      headerEl.classList.add('navbar--open');
      bodyEl.classList.add('overflow-hidden');
    } else {
      headerEl.classList.remove('navbar--open');
      bodyEl.classList.remove('overflow-hidden');
    }
  }
}
