


































































import dealersApi from '@/api/dealersApi';
import distributorsApi from '@/api/distributorsApi';
import googleMapsApi from '@/api/googleMapsApi';
import salesContactsApi from '@/api/salesContactsApi';
import IconFilter from '@/assets/svg/icons/icon-filter.svg?inline';
import IconInfo from '@/assets/svg/icons/icon-info.svg?inline';
import IconLocation from '@/assets/svg/icons/icon-location.svg?inline';
import IconSearch from '@/assets/svg/icons/icon-search.svg?inline';
import DealerCard from '@/components/global/DealerCard.vue';
import GoogleMaps, {defaultLatLng, Marker} from '@/components/global/GoogleMaps.vue';
import InfoCard from '@/components/global/InfoCard.vue';
import LocationCard from '@/components/global/LocationCard.vue';
import TrModal from '@/components/shared/TrModal.vue';
import {Dealer} from '@/models/dealers';
import {BreakPoint, mqMaxWidth} from '@/utils/mq';
import {convertMilesToKm, findUnit, Unit, Units, UnitString} from '@/utils/units';
import {AxiosResponse} from 'axios';
import {gsap} from 'gsap';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TweenVars = gsap.TweenVars;

@Component({
  name: 'DealerLocator', components: {
    TrModal,
    InfoCard,
    DealerCard,
    GoogleMaps,
    IconSearch,
    IconFilter,
    IconLocation,
    IconInfo,
    LocationCard
  }
})
export default class DealerLocator extends Vue {
  @Prop({required: true}) apiKey!: string;
  @Prop({required: true}) placeholder!: string;
  @Prop({required: true}) country_code!: string;
  @Prop() mapId?: string;
  @Prop({default: defaultLatLng.lat}) lat!: number;
  @Prop({default: defaultLatLng.lng}) lng!: number;
  @Prop({default: []}) productLines!: [{ label: string, value: string }];

  @Prop({default: true}) showDealers!: boolean;
  @Prop({default: false}) showDistributors!: boolean;
  @Prop({default: false}) showSalesContacts!: boolean;

  @Prop({default: 'metric'}) units!: UnitString;

  private allDealers: Array<Dealer> = [];

  private searchValue: string = '';
  private range: number = 0;
  private activeDealer?: Dealer | null = null;
  private visibleDealers: Array<Dealer> = [];
  private isMarkerClicked: boolean = false;


  private productLineFilter: string = '';

  private userHandlingDone = false;
  private userLat: number = this.lat;
  private userLng: number = this.lng;

  private unit: Unit = Units.Metric;
  private sortedDealers: (Dealer & { distance?: number })[] = [];
  private noResultsInRange: boolean = false;

  get radius(): number {
    const range = this.range;
    if (this.unit.string === Units.Metric.string) {
      return range;
    }
    if (this.unit.string === Units.Imperial.string) {
      return convertMilesToKm(range);
    }
    return 0;
  }

  get fallbackRadius(): number {
    return this.getMaxDistance() * 20;
  }

  get showNoResults(): boolean {
    return (this.noResultsInRange || this.sortedDealers.length === 0) && !this.isMarkerClicked;
  }

  get showMoreResultsMsg(): boolean {
    return this.sortedDealers.length === 0  && !this.isMarkerClicked;
  }

  private get responseLocation(): { lat: number, lng: number } | null {
    const location = this.response?.geometry.location;
    if (location) {
      return location.toJSON();
    }
    return {
      lat: this.userLat || this.lat,
      lng: this.userLng || this.lng
    };
  }

  private get response(): google.maps.GeocoderResult | undefined {
    return this.$store.getters['geocoding/results'];
  }

  private get markers(): { lng: number; title: string; lat: number }[] {
    let countryCode = "";
    let countryCodeSecondary = "";

    switch (this.country_code) {
      case 'de_DE':
        countryCode = "DE";
        break;
      case 'nl_BE':
        countryCode = "LU";
        countryCodeSecondary = "BE";
            break;
      case 'fr_BE':
        countryCode = "LU";
        countryCodeSecondary = "BE";
        break;
      case 'fr_FR':
        countryCode = "FR";
        break;
      case 'nl_NL':
        countryCode = "NL";
        break;
      case 'it_IT':
        countryCode = "IT";
        break;
      case 'es_CL':
        countryCode = "CL";
        break;
      case 'de_CH':
        countryCode = "DE";
        countryCodeSecondary = "CH";
        break;
      case 'de_IT':
        countryCode = "CH";
        break;
      case 'pt_PT':
        countryCode = "PT";
        break;
      case 'pt_BR':
        countryCode = "PT";
        break;
      case 'pl_PL':
        countryCode = "PL";
        break;
    }

    let filteredDealers = this.dealers || [];

    // Conditionally apply filter based on countryCodeSecondary and countryCode
    if (countryCodeSecondary || countryCode) {
      filteredDealers = filteredDealers.filter((dealer) => {
        return dealer.country_code === countryCode || dealer.country_code === countryCodeSecondary;
      });
    }


    return filteredDealers.map((dealer) => ({
          title: dealer.name,
          lat: dealer.lat,
          lng: dealer.lng
        }));
  }

  private get dealers(): Array<Dealer> | undefined {
    return this.allDealers;
  }

  beforeCreate() {
    setTimeout(() => {
      this.userHandlingDone = true;
    }, 100);
    /*if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.userLat = position.coords.latitude;
        this.userLng = position.coords.longitude;
        this.userHandlingDone = true;
      }, () => {
        this.userHandlingDone = true;
      });
    } else {
      setTimeout(() => {
        this.userHandlingDone = true;
      }, 100);
    }*/
  }

  beforeMount() {
    this.unit = findUnit(this.units) as Unit;
    this.range = this.getMaxDistance();
  }

  mounted() {
    this.getAllDealers();
    this.$store.dispatch('geocoding/setKey', this.apiKey);
    setTimeout(() => {
      this.openDealerSidebar();
    }, 300);
  }

  getAllDealers() {
    const promises: Promise<AxiosResponse<Dealer>>[] = [];
    if (this.showDealers) {
      promises.push(dealersApi.performSearchWithFilters(null, this.productLineFilter));
    }
    if (this.showDistributors) {
      promises.push(distributorsApi.performSearchWithFilters(null, this.productLineFilter));
    }
    if (this.showSalesContacts) {
      promises.push(salesContactsApi.performSearchWithFilters(null, this.productLineFilter));
    }
    Promise.all(promises).then((values: AxiosResponse<Dealer>[]) => {
      this.allDealers = values.flatMap(value => value.data);
    }).catch(reason => {
      console.error(reason);
    });
  }

  openDealerSidebar() {
    const mq = mqMaxWidth(BreakPoint.MD);
    let gsapOptions: TweenVars = {
      flexBasis: '50%',
      opacity: 1,
      width: 'auto',
      duration: 0.3,
    };
    if (!mq.matches) {
      gsapOptions.marginLeft = 20;
    }
    gsap.to((this.$refs.locations as Vue).$el, gsapOptions);
  }

  get sortedDealersWithActive() {
    const d = Array.from(this.sortedDealers);
    console.log({d})
    if (this.activeDealer) {
      if (!d.find(value => value.id === this.activeDealer?.id)) {
        d.push(this.activeDealer);
      }
      return d.sort((dealer1, dealer2) => {
        if (dealer1.id === this.activeDealer?.id) {
          return -1;
        }
        return 0;
      });
    }
    return d;
  }

  private async updateSortedDealers(): Promise<void> {
    if (this.visibleDealers && this.responseLocation) {
      const dealers: Array<Dealer & { distance?: number }> = Array.from(this.visibleDealers);
      const comparableDealers = await Promise.all(dealers.map(async dealer => {
        dealer.distance = await googleMapsApi.getLocationDistanceInKm(this.apiKey, {
          lat: dealer.lat, lng: dealer.lng
        }, this.responseLocation as { lat: number; lng: number; });
        return dealer;
      }));
      comparableDealers.sort((dealer1, dealer2) => {
        return (dealer1.distance || Number.MAX_SAFE_INTEGER) - (dealer2.distance || Number.MAX_SAFE_INTEGER);
      });
      this.sortedDealers = comparableDealers;
    }
  }

  private getMaxDistance(): number {
    if (this.unit.string === Units.Metric.string) {
      return 300;
    }
    if (this.unit.string === Units.Imperial.string) {
      return 150;
    }
    return 0;
  }

  private onProductLineChange() {
    this.getAllDealers();
  }

  private updateVisibleDealers(visibleMarkers: Marker[], noResultsInRange: boolean): void {
    this.noResultsInRange = noResultsInRange;
    this.isMarkerClicked = false;
    if (this.dealers) {
      const visibleTitles = visibleMarkers.map(visibleMarker => {
        return visibleMarker.title;
      });
      this.visibleDealers = this.dealers.filter(dealer => {
        return visibleTitles.includes(dealer.name);
      });
      this.updateSortedDealers();
    }
  }

  private markerClicked(marker: google.maps.Marker): void {
    this.isMarkerClicked = true;
    this.activeDealer = this.dealers?.find((d) => {
      return d.name === marker.getTitle();
    });
    this.openDealerSidebar();
  }

  private goToDealer(dealer: Dealer) {
    (this.$refs.map as GoogleMaps).setActiveMarkerByName(dealer.name);
  }

  @Watch('searchValue')
  private onSearchValueChanged() {
    this.$store.dispatch('geocoding/setAddress', this.searchValue);
  }


}
