















































import ChevronLeftIcon from '@/assets/svg/icons/icon-chevron-left.svg?inline';
import ChevronRightIcon from '@/assets/svg/icons/icon-chevron-right.svg?inline';
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
})
export default class Pagination extends Vue {
  @PropSync('currentPage', {required: true}) public syncedCurrentPage!: number;
  @Prop({default: 12}) private itemsPerPage!: number;
  @Prop({required: true}) private totalItems!: number;

  get maxPages() {
    const page = Math.ceil(this.totalItems / this.itemsPerPage);
    if (page === 0) {
      return 1;
    }

    return page;
  }

  get minPage() {
    if (this.maxPages <= 5) {
      return 1;
    }
    if (this.syncedCurrentPage < 3) {
      return 1;
    }
    return this.syncedCurrentPage - 2;
  }

  get maxPage() {
    if (this.maxPages <= 5) {
      return this.maxPages - 1;
    }
    if (this.syncedCurrentPage < 3) {
      if (this.maxPages <= 3) {
        return this.maxPages - 1;
      }
      return 3;
    }
    if (this.syncedCurrentPage === this.maxPages - 1) {
      return this.syncedCurrentPage;
    }
    if (this.syncedCurrentPage === this.maxPages) {
      return this.maxPages - 1;
    }
    return this.syncedCurrentPage + 1;
  }

  get hasBeginningGap() {
    if (this.maxPages <= 5) {
      return false;
    }
    return this.syncedCurrentPage > 3;
  }

  get hasEndingGap() {
    if (this.maxPages <= 5) {
      return false;
    }
    return this.syncedCurrentPage < this.maxPages - 2;
  }
}
