








import googleMapsApi from '@/api/googleMapsApi';
import IconLocationFilled from '@/assets/svg/icons/icon-location-filled.svg?inline-simple';
import {Colours} from '@/typings/enums/Colours';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import _ from 'lodash';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

export type Marker = { lat: number, lng: number, title?: string };
export const defaultLatLng: google.maps.LatLngLiteral = {lat: 52.1326, lng: 5.2913};

@Component({
  name: 'GoogleMaps', components: {
    IconLocationFilled
  }
})
export default class GoogleMaps extends Vue {
  @Prop({required: true}) apiKey!: string;
  @Prop() mapId?: string;
  @Prop({
    default: () => {
      return defaultLatLng;
    }
  }) center!: { lat: number, lng: number };
  @Prop({default: 7}) zoom!: number;
  @Prop({
    default: () => {
      return [];
    }
  }) markers!: Array<Marker>;
  @Prop({default: 0}) circleRadius?: number;
  @Prop({default: 0}) fallbackRadius?: number;
  @Prop({default: false}) initDrawCircle!: boolean;
  @Prop({default: false}) drawMarker!: boolean;

  private symbol?: google.maps.Symbol;
  private infoWindow?: google.maps.InfoWindow;
  private map?: google.maps.Map;
  private circle?: google.maps.Circle;
  private centerPoint?: google.maps.Marker;
  private mapMarkers: Array<google.maps.Marker> = [];
  private markerCluster?: MarkerClusterer;

  private createMarkers: _.DebouncedFunc<() => void> = _.debounce(this._createMarkers.bind(this),
      500);

  private get svgPath(): string | null | undefined {
    const element: Element | undefined = (this.$refs.svgIcon as Vue)?.$el;
    const path: SVGPathElement | null = element?.querySelector<SVGPathElement>('path');
    return path?.getAttribute('d');
  }

  /**
   * Be sure to load the google api with your api key before using this function
   */
  public static getLocationDistanceInKm(fromPoint: google.maps.LatLngLiteral | { lng: number, lat: number }, toPoint: google.maps.LatLngLiteral | { lng: number, lat: number }): number {
    const fromLatLng = new google.maps.LatLng(fromPoint);
    const toLatLng = new google.maps.LatLng(toPoint);
    const meters: number = google.maps.geometry.spherical.computeDistanceBetween(fromLatLng, toLatLng);
    return Math.ceil(meters / 1000);
  }

  created() {
    googleMapsApi.load(this.apiKey).then(() => {
      const mapOptions: google.maps.MapOptions = {
        center: this.center,
        minZoom: 1,
        maxZoom: 10,
        zoom: this.zoom,
        disableDefaultUI: true,
        zoomControl: true
      };

      if (this.mapId) {
        mapOptions.mapId = this.mapId;
      }

      this.map = new google.maps.Map(this.$refs.map as HTMLElement, mapOptions);
      this.markerCluster = new MarkerClusterer(this.map, [], {
        maxZoom: 19,
        calculator: (clusterMarkers: google.maps.Marker[], numStyles: number) => {
          let index = 0;
          const count = clusterMarkers.length;

          let dv = count;
          while (dv !== 0) {
            dv = Math.floor(dv / 10);
            index++;
          }

          index = Math.min(index, numStyles);
          return {
            text: count.toString(),
            url: require('@/assets/svg/icons/icon-circle-filled.svg'),
            index: index,
            title: '',
          };
        }
      });
      this.infoWindow = new google.maps.InfoWindow();
      this.symbol = {
        fillColor: Colours.BRAND,
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 1.45,
        anchor: new google.maps.Point(12, 24),
        path: this.svgPath as string
      };

      if (this.initDrawCircle || this.drawMarker) {
        const icon: google.maps.Symbol = {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: Colours.BLACK,
          fillOpacity: 1,
          scale: 1
        };
        this.centerPoint = new google.maps.Marker({
          position: this.center,
          map: this.map,
          icon: (this.drawMarker) ? this.symbol : icon
        });
      }

      if (this.initDrawCircle) {
        this.drawCircle(this.circleRadius as number);
      }
    });
  }

  mounted() {
    this.createMarkers();
  }

  public setActiveMarkerByName(name: string) {
    const marker: google.maps.Marker | undefined = this.mapMarkers.find((marker: google.maps.Marker) => {
      return marker.getTitle() === name;
    });

    if (marker) {
      google.maps.event.trigger(marker, 'click');
    }
  }

  @Watch('markers', {deep: true})
  onMarkersChange() {
    this.createMarkers();
  }

  private deleteMarkers() {
    this.markerCluster?.clearMarkers();
    this.mapMarkers.forEach(mapMarker => {
      mapMarker.setMap(null);
    });
    this.mapMarkers = [];
  }

  private _createMarkers() {
    googleMapsApi.load(this.apiKey).then(() => {
      this.deleteMarkers();

      // Get markers within radius
      let radiusRestrictedMarkers = this.filterByRadius(this.markers, (this.circleRadius as number));
      let noResultsInRange = false;
      if (radiusRestrictedMarkers.length === 0) {
        radiusRestrictedMarkers = this.filterByRadius(this.markers, (this.fallbackRadius as number));
        noResultsInRange = true;
      }

      this.markers.forEach((location) => {
        this.addMarker(location);
      });
      this.$emit('markersInsideCircleUpdated', radiusRestrictedMarkers, noResultsInRange);

    });
  }

  private drawCircle(circleRadius: number): void {
    if (!this.circle) {
      this.circle = new google.maps.Circle({
        map: this.map,
        radius: circleRadius,
        strokeColor: Colours.BRAND,
        fillOpacity: 0,
        center: this.map?.getCenter(),
      });
    } else {
      this.circle.setRadius(circleRadius);
    }
    this.map?.fitBounds(this.circle.getBounds()!, 0);
  }

  private addMarker(location: Marker): void {
    googleMapsApi.load(this.apiKey).then(() => {
      const marker = new google.maps.Marker({
        position: location,
        map: this.map,
        icon: this.symbol,
        title: location.title,
        optimized: true
      });
      marker.addListener('click', () => {
        this.$emit('markerClicked', marker);
        this.map?.panTo(marker.getPosition() as google.maps.LatLng | google.maps.LatLngLiteral);
        // this.smoothZoom(this.map as google.maps.Map, 15, this.map?.getZoom() as number);

        this.mapMarkers.forEach((selectedMarker) => {
          const icon: google.maps.Symbol = (selectedMarker.getIcon() as google.maps.Symbol);
          if (marker === selectedMarker) {
            icon.fillColor = Colours.BLACK;
          } else {
            icon.fillColor = Colours.BRAND;
          }
          selectedMarker.setIcon(icon);
        });
      });
      this.markerCluster?.addMarker(marker);
      this.mapMarkers.push(marker);
    });
  }

  private smoothZoom(map: google.maps.Map, max: number, count: number): void {
    if (count > max) {
      return;
    } else {
      const listener = map.addListener('zoom_changed', () => {
        listener.remove();
        this.smoothZoom(map, max, count + 1);
      });

      setTimeout(() => {
        map.setZoom(count);
      }, 80);
    }
  }

  private filterByRadius(markers: Array<Marker>, radius: number): Marker[] {
    return  markers.filter(({lat, lng}: Marker) => {
      const rawMarkerLatLng = new google.maps.LatLng({lat, lng});
      const d = google.maps.geometry.spherical.computeDistanceBetween(
          rawMarkerLatLng, this.centerPoint?.getPosition() as google.maps.LatLng
      );
      return d <= radius;
    });
  }

  @Watch('circleRadius')
  private onCircleRadiusChanged() {
    this.drawCircle(this.circleRadius || 0);
    this.createMarkers();
  }

  @Watch('center', {deep: true})
  private onCenterChanged() {
    this.map?.panTo(this.center as google.maps.LatLng | google.maps.LatLngLiteral);
    this.centerPoint?.setPosition(this.map?.getCenter());
    this.circle?.setCenter(this.map?.getCenter() as google.maps.LatLng | google.maps.LatLngLiteral);
    this.createMarkers();
  }

}
