
















































































import {CartMixin} from '@/utils/mixins/CartMixin';
import FilterOptions from '@/components/filter/FilterOptions.vue';
import SampleBasket from '@/components/sample-selector/SampleBasket.vue';
import SampleBasketOrderModal from '@/components/sample-selector/SampleBasketOrderModal.vue';
import SampleDetails from '@/components/sample-selector/SampleDetails.vue';
import SampleList from '@/components/sample-selector/SampleList.vue';
import TrModal from '@/components/shared/TrModal.vue';
import {getGlobalTimeline} from '@/store/shared';
import {Category} from '@/typings/interfaces/Misc';
import {gsap, Power2} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import SampleTypeSelector from '@/components/sample-selector/SampleTypeSelector.vue';
import {SplashScreenOption} from '@/typings/interfaces/splash-screen';
import Cookies from 'js-cookie';
import TrButton from '@/components/shared/TrButton.vue';
import IconShopping from '@/assets/svg/icons/icon-shopping.svg?inline';
import {mixins} from "vue-class-component";

gsap.registerPlugin(ScrollToPlugin);

@Component({
  name: 'SampleSelector',
  components: {
    SampleTypeSelector,
    FilterOptions,
    SampleDetails,
    SampleList,
    SampleBasket,
    TrModal,
    SampleBasketOrderModal,
    TrButton,
    IconShopping,
  },
})
export default class SampleSelector extends mixins(CartMixin) {
  @Prop({required: true}) readonly salutations!: Array<Category>;
  @Prop({required: true}) readonly professions!: Array<Category>;
  @Prop({required: true}) readonly countries!: Array<Category>;
  @Prop({required: true}) readonly regions!: Array<Category>;
  @Prop({required: true}) readonly privacyPolicyLink!: string;
  @Prop({required: true}) readonly formStepOneLabel!: string;
  @Prop({required: true}) readonly formStepTwoLabel!: string;
  @Prop({required: true}) readonly salutationLabel!: string;
  @Prop({required: true}) readonly firstNameLabel!: string;
  @Prop({required: false}) readonly honeyNameLabel!: string;
  @Prop({required: false}) readonly reverseFieldLabel!: string;
  @Prop({required: true}) readonly lastNameLabel!: string;
  @Prop({required: true}) readonly companyNameLabel!: string;
  @Prop({required: true}) readonly houseNumberLabel!: string;
  @Prop({required: true}) readonly addressLabel!: string;
  @Prop({required: true}) readonly countryLabel!: string;
  @Prop({required: true}) readonly cityLabel!: string;
  @Prop({required: true}) readonly zipcodeLabel!: string;
  @Prop({required: true}) readonly emailLabel!: string;
  @Prop({required: true}) readonly phoneLabel!: string;
  @Prop({required: true}) readonly professionLabel!: string;
  @Prop({required: true}) readonly startDateLabel!: string;
  @Prop({required: true}) readonly squareFeetLabel!: string;
  @Prop({required: true}) readonly otherLabel!: string;
  @Prop({required: true}) readonly newsletterLabel!: string;
  @Prop({required: true}) readonly privacyPolicyLabel!: string;
  @Prop({required: true}) readonly regionLabel!: string;
  @Prop({required: true}) readonly blockList!: string;

  @Prop() readonly introText!: string;
  @Prop() readonly moveToStepTwoText!: string;
  @Prop({required: true}) readonly orderPlacedMessage!: string;

  // Sample type selector properties:
  @Prop({required: true}) readonly sampleTypeSelectorTitle!: string;
  @Prop({required: true}) readonly sampleTypeSelectorExterior!: SplashScreenOption;
  @Prop({required: true}) readonly sampleTypeSelectorInterior!: SplashScreenOption;
  @Prop({required: true}) readonly sampleTypeSelectorSidings!: SplashScreenOption;

  @Ref('resultsSection') private resultsSection!: HTMLElement;
  @Ref('sampleTypeSelector') private sampleTypeSelector!: SampleTypeSelector;
  @Ref('sampleBasketOrderModal') private sampleBasketOrderModal!: SampleBasketOrderModal;
  @Ref('sampleBasketSection') private sampleBasketSection!: HTMLElement;

  private hideCloseCross: boolean = false;
  private modalWidth: string | null = null;

  get selectedItem() {
    return this.$store.getters['productSearch/selectedItem'];
  }

  get lastUpdate() {
    return this.$store.getters['productSearch/lastResultsUpdate'];
  }

  private scrollToBasket() {
    gsap.to(window, {
      scrollTo: {
        y: this.sampleBasketSection,
        offsetY: 16
      },
      duration: 0.5,
      ease: Power2.easeOut,
    })
  }

  private mounted() {
    const sampleSearchMode = Cookies.get('sampleSearchMode');

    if(sampleSearchMode) {
      this.$store.dispatch('productSearch/setSampleSearchMode', sampleSearchMode);
      this.$store.dispatch('productSearch/setActiveFilters', sampleSearchMode);
    } else {
      this.sampleTypeSelector.setShowModal(true);
    }

    this.$store.dispatch('productSearch/performSearch');
  }

  get isSampleBasketOrderModalOpen(): boolean {
    return this.$store.getters['ordering/isSampleBasketOrderModalOpen'];
  }

  private closeSampleBasketOrderModal() {
    this.sampleBasketOrderModal.close();
  }

  private handleApplySampleOrderFinishedStylingEvent(doApply: boolean) {
    if (doApply) {
      this.hideCloseCross = true;
      this.modalWidth = '700px'
    } else {
      this.hideCloseCross = false;
      this.modalWidth = null;
    }
  }

  @Watch('$store.state.productSearch.selectedItem')
  private onSelectedItemChange() {
    if (this.selectedItem) {
      this.scrollToTopOfResults();
    }
  }

  private scrollToTopOfResults() {
    this.$nextTick(() => {
      getGlobalTimeline(this).add(
          gsap.to(window, {
            scrollTo: {
              y: this.resultsSection,
              offsetY: 150,
              // autoKill: true
            },
            duration: 0.5,
            ease: Power2.easeOut,
          })
      );
    });
  }
}
