
































import IconMouseArrow from '@/assets/svg/icons/icon-mouse-w-arrow.svg?inline';
import TrButton from '@/components/shared/TrButton.vue';
import {HeroData} from '@/typings/interfaces/Misc';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {parseTextFieldsArr} from "@/utils/StringUtils";

@Component({
  name: 'Hero',
  components: {
    Swiper,
    SwiperSlide,
    IconMouseArrow,
    TrButton,
  },
})
export default class Hero extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  readonly data!: Array<HeroData>;
  @Prop({default: false}) autoplay!: {
    delay: number;
    disableOnInteraction: boolean;
    pauseOnMouseEnter: boolean;
  } | boolean;

  optionsBg = {
    enabled: false,
    allowTouchMove: false,
    effect: 'slide',
  };

  options = {
    autoHeight: false,
    watchOverflow: true,
    slidesPerView: 1,
    // 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip'
    // TODO: pick fade or coverflow if u want
    effect: 'fade',
    // coverflowEffect: {
    //   slideShadows: false,
    // },
    fadeEffect: {
      crossFade: true,
    },
    // loop: false,
    autoplay: this.autoplay,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpointsInverse: true,
    breakpoints: {
      992: {
        autoHeight: false,
      },
    },
  };

  get parsedData() {
    return parseTextFieldsArr(this.data);
  }

  mounted() {
    const swiperObj = (this.$refs.swiperText as any).$swiper;

    swiperObj.on('resize', () => {
      this.getHeroHeight(swiperObj);
    });
  }

  getHeroHeight(swiperObj: any) {
    let heroEl = document.querySelector('.hero') as HTMLElement;

    const sectionHeight = swiperObj.height + 216;

    heroEl.style.height =
        window.innerWidth > 991 ? sectionHeight + 'px' : swiperObj.height + 'px';
  }

  onSlideChange() {
    // eslint-disable-next-line
    let firstSlider = (this.$refs.swiperText as any).$swiper;
    // eslint-disable-next-line
    let bgSlider = (this.$refs.swiperBg as any).$swiper;

    bgSlider.slideTo(firstSlider.activeIndex);
  }

  scrollTo() {
    // TODO: scroll to method
    console.log('Add scroll to method');
  }
}
