type KeyValue = { key: string, value: string; };
const choiceRegExp = new RegExp(/^.*({[\d], ?choice).*$/gm);

const isComment = (line: string) => line.startsWith('#');
const isEmpty = (line: string) => line.trim() == '';

const asKeyValue: (line: string) => Partial<KeyValue> = (line) => {
    let {0: key, 1: value}: string[] = line.split('=');
    key = key?.trim();
    value = value?.trim();
    return {key, value};
};

const hasKeyAndValue = (keyValue: Partial<KeyValue>): keyValue is KeyValue => {
    const {key, value} = keyValue;
    return !!(key && value);
};

const isChoice = (value: string) => choiceRegExp.test(value);

const valueToChoiceFormat = (value: string) => {
    const lastBracket = value.lastIndexOf('}');
    if (lastBracket === -1) {
        return value;
    }
    const choiceString = value.substring(0, lastBracket);
    const restString = value.substring(lastBracket + 1);
    const properties = choiceString.split(',');
    if (properties.length > 2) {
        const options = properties[2].split('|');
        const newOptions: Array<string> = [];
        options.forEach((option) => {
            const replaced = option.replace(/[\d]([#<])/, '');
            newOptions.push(`${replaced}${restString}`);
        });
        value = newOptions.join(' | ');
    }
    return value;
};


const process = (file) => {
    const lines: Array<string> = file.split('\n');
    const translations = {};
    lines.forEach((line) => {
        if (isComment(line) || isEmpty(line)) {
            return;
        }
        const keyValue: Partial<KeyValue> = asKeyValue(line);
        if (!hasKeyAndValue(keyValue)) {
            return;
        }
        const {key} = keyValue;
        let {value} = keyValue;
        if (isChoice(value)) {
            value = valueToChoiceFormat(value);
        }
        if (!Object.prototype.hasOwnProperty.call(translations, key)) {
            translations[key] = value.replace(/\r?\n|\r/, '');
        }
    });
    return translations;
};
export default process;
