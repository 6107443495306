










import {ImgUrlMixin} from '@/utils/mixins/ImgUrlMixin.js';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {parseTextFields} from "@/utils/StringUtils";

@Component({name: 'ImageWithDesc'})
export default class ImageWithDesc extends mixins(ImgUrlMixin) {
  @Prop({required: true}) public readonly data;
  @Prop({
    default: 'small',
    type: String,
    validator: (value) => {
      return ['small', 'medium', 'rectangle'].includes(value);
    },
  })
  public readonly size;

  get parsedData() {
    return parseTextFields(this.data);
  }

  get customClass() {
    return {
      'img-with-desc--small': this.size === 'small',
      'img-with-desc--medium': this.size === 'medium',
      'img-with-desc--rectangle': this.size === 'rectangle',
    };
  }
}
