



































import IconCancel from '@/assets/svg/icons/icon-cancel.svg?inline';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  name: 'PreviewModal',
  components: {
    Swiper,
    SwiperSlide,
    IconCancel,
  },
})
export default class PreviewModal extends Vue {
  @Prop({type: Boolean, default: false}) public show!: boolean;
  @Prop({type: Number, default: 0}) public initialSlide!: number;
  @Prop({type: Array, default: () => []}) public images!: Array<string>;
  @Prop({type: Array, default: () => []}) public videos!: Array<string>;
  @Prop({type: Array, default: () => []}) public content!: Array<string>;
  @Prop({
    type: String,
    validator: (value) => {
      return ['image', 'video'].includes(value);
    },
  })
  public contentType!: Array<string>;

  private swiperOption = {
    keyboard: true,
    slideToClickedSlide: true,
    zoom: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 30,
    initialSlide: 0,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  @Watch('show')
  private onChildChanged() {
    const test = (this.$refs.previewSwiper as any).$swiper;
    test.slideTo(this.initialSlide - 1);
  }

  private closeModal() {
    this.$emit('close');
  }
}
