









import ImageWithDesc from '@/components/global/ImageWithDesc.vue';

import {ImgWithDesc} from '@/typings/interfaces/Misc';
import {Component, Model, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'PreviewImgListItem', components: {ImageWithDesc}})
export default class PreviewImgListItem extends Vue {
  @Prop({required: true}) readonly planName!: ImgWithDesc;
  @Prop(String) readonly size;
  // @Prop(String) readonly selectorType;

  @Prop({
    type: String,
    validator: (value) => {
      return ['img-cb', 'img-rb'].includes(value);
    },
  })
  readonly selectorType!: string;

  private isActive = false;

  @Model('test') readonly checked!: boolean;

  updateActivePlan() {
    if (this.selectorType === 'img-rb') {
      this.$emit('test', this.planName.id);
    } else {
      //its checkbox
      this.$emit('test', !this.isActive);
      this.isActive = !this.isActive;
    }
  }
}
