import dealers, {DealersState} from '@/store/dealers';
import distributors, {DistributorState} from '@/store/distributors';
import geocoding, {GeocodingState} from '@/store/geocoding';
import inspiration, {InspirationState} from '@/store/inspiration';
import ordering, {OrderingState} from '@/store/ordering';
import productSearch, {ProductSearchState} from '@/store/productSearch';
import salesContacts, {SalesContactsState} from '@/store/salesContacts';
import shared, {SharedState} from '@/store/shared';
import shoppingCart, {ShoppingCartState} from '@/store/shoppingCart';
import createSyncedState from '@/store/SyncedStatePlugin';
import Vue from 'vue';
import Vuex from 'vuex';
import {OrderableItem} from "@/typings/types/OrderableItem";

Vue.use(Vuex);
Vue.config.devtools = true;

const debug = process.env.NODE_ENV !== 'production';

export declare interface StoreModules {
    shared: SharedState,
    productSearch: ProductSearchState,
    shoppingCart: ShoppingCartState,
    ordering: OrderingState,
    inspiration: InspirationState
    geocoding: GeocodingState,
    dealers: DealersState,
    salesContacts: SalesContactsState
    distributors: DistributorState
}

// This is a callback for the synched state utility. It sanitizes the items saved in the local storage cart in order to
// check for invalid items, and to get their status in general.
async function sanitizeCartData(sync_cb, data) {
    const items: OrderableItem[] = [];
    for (const it of data.cart.items) {
        items.push(await OrderableItem.create(it.id, it.quantity));
    }
    data.cart.items = items;
    sync_cb(data);
}

export default new Vuex.Store<StoreModules>({
    modules: {
        shared,
        productSearch,
        shoppingCart,
        ordering,
        inspiration,
        geocoding,
        dealers,
        salesContacts,
        distributors
    },
    strict: debug,
    plugins: [
        createSyncedState('shoppingCart', {debug, transform: sanitizeCartData}),
        // debug ? createLogger() : () => { /* void */ }
    ]
});

