import * as psTypes from "@/typings/interfaces/ProductSearch";

export const createHash = (string: string, forcePositive: boolean = false): number =>  {
    let hash = 0;
    let i = 0;
    const len = string.length;
    while ( i < len ) {
        hash  = ((hash << 5) - hash + string.charCodeAt(i++)) << 0;
    }
    return forcePositive ? (hash + 2147483647) + 1 : hash;
}


const superCharPatternAlt = /\^(\^*?[\w|®©™]+)/gi;

export const superFormatter = (s: string) => {

    return s.replace(superCharPatternAlt, (r: string, p1: string) => {
        return superFormatter("<sup>" + p1 + "</sup>");
    });
};



const parseableFields = [
    "title", "subtitle", "description", "desc"
];

export function parseTextFields<T>(elem: T) : T {
    if (elem === null || elem === undefined)
        return elem;
    const new_elem = {...elem} as any;
    for (const f of parseableFields) {
        if (f in new_elem && typeof new_elem[f] == "string") {
            new_elem[f] = superFormatter(new_elem[f]);
        }
    }
    return new_elem;
}

export function parseTextFieldsArr<T>(elems: T[]) : T[] {
    const new_arr = [...elems];
    for (const i in new_arr) {
        new_arr[i] = parseTextFields(new_arr[i]);
    }
    return new_arr;
}

