
























































































import SelectableImage from '@/components/global/SelectableImage.vue';
import PreviewImgListItem from '@/components/preview-img/PreviewImgListItem.vue';
import TrButton from '@/components/shared/TrButton.vue';
import * as ElasticSearch from '@/typings/interfaces/ElasticSearch';
import * as ProductSearch from '@/typings/interfaces/ProductSearch';
import { Component, Watch } from 'vue-property-decorator';
import { parseTextFields, parseTextFieldsArr } from '@/utils/StringUtils';
import { OrderableItem } from '@/typings/types/OrderableItem';
import { mixins } from 'vue-class-component';
import { CartMixin } from '@/utils/mixins/CartMixin';
import SampleNotification from '@/components/sample-selector/SampleNotification.vue';
import QuestionMark from '@/assets/svg/icons/icon-question-mark.svg?inline';
import TrSidePanel from '@/components/side-panel/TrSidePanel.vue';
import { Finish } from '@/typings/interfaces/ProductSearch';
import SidePanelFinish from '@/components/side-panel/SidePanelFinish.vue';
import { FinishType } from '@/typings/enums/FinishType';
import CartInfoModal from '@/components/sample-selector/CartInfoModal.vue';

@Component({
  name: 'SampleDetails',
  components: {
    CartInfoModal,
    SidePanelFinish,
    TrSidePanel,
    PreviewImgListItem,
    SelectableImage,
    TrButton,
    SampleNotification,
    QuestionMark,
  },
})
export default class SampleDetails extends mixins(CartMixin) {
  private selectedFinish?: ProductSearch.Finish | null = null;
  private isShowSidePanel: boolean = false;
  private showInfoModal: boolean = false;

  setShowInfoModal(isShow: boolean) {
    this.showInfoModal = isShow;
  }

  get statusText() {
    if (this.selectedItem === undefined || this.selectedItem.status === undefined) return null;
    if (this.selectedItem.status === 'phased-out') return 'Phasing out';
    if (this.selectedItem.status === 'new') return this.$t('decors.select.status.new');
    return null;
  }

  get sampleDimensions(): string | undefined {
    const prodLineDims = this.selectedItem?.productLine?.sampleDimensions;
    const decorSet = this.selectedItem?.decorSet;
    if (decorSet == undefined) return prodLineDims;
    if (decorSet.sampleDimensions == null || decorSet.sampleDimensions.trim().length == 0) return prodLineDims;
    return decorSet.sampleDimensions.trim();
  }

  get statusTextCenter() {
    if (this.selectedItem === undefined || this.selectedItem.status === undefined) return false;
    if (this.selectedItem.status === 'new') return true;
    return false;
  }

  get searchResults(): ProductSearch.Response {
    return this.$store.getters['productSearch/results'];
  }

  get selectedItem(): ProductSearch.Source | undefined {
    const selectedItem: string = this.$store.getters['productSearch/selectedItem'];
    const matchingHit = this.searchResults?.hits.hits.find((value: ElasticSearch.Hit<ProductSearch.Source>) => {
      return value._source.id === selectedItem;
    });
    return parseTextFields(matchingHit?._source);
  }

  get finishes(): ProductSearch.Finish[] | undefined {
    const temp = this.selectedItem?.finishes;
    if (!temp) return temp;
    return parseTextFieldsArr(temp);
  }

  @Watch('$store.state.productSearch.selectedItem')
  onSelectedItemChange() {
    this.selectedFinish = null;
  }

  getImage() {
    if (this.selectedFinish) {
      if (this.selectedFinish.gifImage?.link) {
        return _mgnl.contextPath + this.selectedFinish.gifImage.link;
      }
      if (this.selectedFinish.image?.renditions) {
        return _mgnl.contextPath + this.selectedFinish.image.renditions['productImage@2x'].link;
      }
      if (this.selectedFinish.thumbnailImage?.renditions) {
        return _mgnl.contextPath + this.selectedFinish.thumbnailImage.renditions['productImage@2x'].link;
      }
    }
    if (this.selectedItem) {
      if (this.selectedItem.gifImage?.link) {
        return _mgnl.contextPath + this.selectedItem.gifImage.link;
      }
      if (this.selectedItem.image?.renditions) {
        return _mgnl.contextPath + this.selectedItem.image.renditions['productImage@2x'].link;
      }
      if (this.selectedItem.thumbnailImage?.renditions) {
        return _mgnl.contextPath + this.selectedItem.thumbnailImage.renditions['productImage@2x'].link;
      }
    }
  }

  public hasSelectedFinish(): boolean {
    return Boolean(this.selectedFinish);
  }

  isSelectedFinish(finish: ProductSearch.Finish): boolean {
    return this.selectedFinish ? this.selectedFinish.id === finish.id : false;
  }

  private selectFinish(finish: ProductSearch.Finish) {
    this.selectedFinish = parseTextFields(finish);
    this.$forceUpdate();
  }

  private getFinishImage(finish: ProductSearch.Finish) {
    return _mgnl.contextPath + finish.thumbnailImage?.renditions.productImage.link;
  }

  private async addSample() {
    if (this.selectedFinish != null)
      await this.$store.dispatch('shoppingCart/addItem', await OrderableItem.create(this.selectedFinish.id, 1));

    if (this.doesCartContainsItemsWithDifferentPanelOrSidingLocation) {
      this.setShowInfoModal(true);
    }
  }

  private setShowSidePanel(isShow: boolean) {
    this.isShowSidePanel = isShow;
  }

  private getListOfFinishes(finishes: Finish[]): FinishType[] {
    return finishes.map((finish) => finish.name);
  }
}
