export enum Colours {
    BLACK = '#000',
    BRAND = '#e8b922',
    DISABLED = '#ddd',
    ERROR = '#ff8282',
    GREY_17 = '#ddd',
    GREY_35 = '#b8b8b8',
    GREY_7 = '#f4f4f4',
    GREY_80 = '#5c5c5c',
    GREY_90 = '#333',
    GREY_A = '#6c6c6c',
    GREY_B = '#535353',
    GREY_C = '#fafafa',
    GREY_D = '#6d6d6d',
    GREY_E = '#cecece',
    GREY_F = '#dfdfdf',
    GREY_G = '#8a8a8a',
    GREY_H = '#ebebeb',
    GREY_I = '#979797',
    LIGHT_YELLOW = '#FCF8E8',
    WHITE = '#fff',
}

