import productSearchApi from '@/api/productSearchApi';
import {Cart} from '@/store/shoppingCart/index';
import {Response} from '@/typings/interfaces/ElasticSearch';
import {Source} from '@/typings/interfaces/ProductSearch';
import {AxiosResponse} from 'axios';
import {OrderableItem} from "@/typings/types/OrderableItem";

export const hasCartEntry = (cart: Cart, id: string | string[]): boolean => {
    return cart.items.some(value => {
        if (Array.isArray(id)) {
            return id.includes(value.id);
        } else {
            return id === value.id;
        }
    });
};

export const findCartEntry = (cart: Cart, id: string): OrderableItem | undefined => {
    return cart.items.find(value => value.id === id);
};

// If the item already exists in the cart, it adds the quantity and sets the status.
// If the item doesn't exist, this instance is added to the cart.
// In any case this function returns the stored instance.
export const mergeCartEntry = (cart: Cart, item: OrderableItem) => {
    const curItem = findCartEntry(cart, item.id);
    if (curItem === undefined) {
        cart.items.push(item);
        return item;
    }
    curItem.quantity += item.quantity;
    curItem.state = item.state;
    return curItem;
}

// Returns the existing OrderableItem associated with th
/*export const createCartEntry = (cart: Cart, id: string): OrderableItem => {
    const cartEntry: OrderableItem = {
        id,
        quantity: 0,
        state: ItemState.NONE
    };
    cart.items.push(cartEntry);
    return cartEntry;
};

export const findOrCreateCartEntry = (cart: Cart, id: string): OrderableItem => {
    const foundItem: OrderableItem | undefined = findCartEntry(cart, id);
    if (foundItem) {
        return foundItem;
    } else {
        return createCartEntry(cart, id);
    }
};*/

export const removeCartEntry = (cart: Cart, id: string): void => {
    cart.items = cart.items.filter(value => value.id != id);
};

export const validateCartEntry: (id: string) => Promise<AxiosResponse<Response<Source>>> = (id: string) => {
    return productSearchApi.performSearchWithFilters({
        'finishes.id': id
    });
};
