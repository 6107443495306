



import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Component, Prop, Vue} from 'vue-property-decorator';

gsap.registerPlugin(ScrollTrigger);

@Component
export default class StickyRequestButton extends Vue {
  @Prop({required: true}) link!: string;
  @Prop({required: true}) label!: string;

  private mounted(): void {
    let footerEl = document.querySelector('footer') as HTMLElement;
    let ctaEl = this.$refs.cta as HTMLElement;
    gsap.fromTo(ctaEl, { opacity: 1, y: 0 }, {
      opacity: 0,
      y: -5,
      pointerEvents: 'none',
      duration: 0.3,
      scrollTrigger: {
        trigger: footerEl,
        start: "top bottom", // when the top of the trigger hits the top of the viewport
        toggleActions: "play none none reverse"
      }
    });
  }
}
