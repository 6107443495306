import { render, staticRenderFns } from "./TrButton.vue?vue&type=template&id=df778432&scoped=true&"
import script from "./TrButton.vue?vue&type=script&lang=ts&"
export * from "./TrButton.vue?vue&type=script&lang=ts&"
import style0 from "./TrButton.vue?vue&type=style&index=0&id=df778432&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df778432",
  null
  
)

export default component.exports