import api from '@/api/api';
import { InspirationSearchResponse } from '@/typings/interfaces/InspirationSearch';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

enum InspirationSearchParamNames {
  BUILD_TYPE = 'buildType.name',
  COUNTRY_CODE = 'countryCode',
  DECOR_SET = 'decorSet.name',
  PRODUCT_LINE = 'productLine.name',
  MARKET_SEGMENT = 'marketSegment.name',
}

export interface InspirationSearchParams {
  q?: string;
  size?: number;
  'productLine.name'?: string | null;
  'buildType.name'?: string | null;
  'decorSet.name'?: string | null;
  'marketSegment.name'?: string | null;
  countryCode?: string | null;
}

export interface InspirationApiConfig extends AxiosRequestConfig {
  params?: InspirationSearchParams;
}

export default {
  search(config: InspirationApiConfig): Promise<AxiosResponse<InspirationSearchResponse>> {
    return api.get('inspiration/v1/search', config);
  },
};
