



































import IconShopping from '@/assets/svg/icons/icon-shopping.svg?inline';
import IconCheckThin from '@/assets/svg/icons/check-thin.svg?inline';
import BasketItem from '@/components/sample-selector/BasketItem.vue';
import TrButton from '@/components/shared/TrButton.vue';
import SmoothReflow from '@/components/SmoothReflow.vue';
import {Cart} from '@/store/shoppingCart';
import {CartMixin} from '@/utils/mixins/CartMixin';
import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {Options} from 'vue-smooth-reflow/types/vue-smooth-reflow';
import SampleNotification from "@/components/sample-selector/SampleNotification.vue";

@Component({
  name: 'SampleBasket',
  components: {SmoothReflow, BasketItem, IconShopping, IconCheckThin, TrButton, SampleNotification},
})
export default class SampleBasket extends mixins(CartMixin) {

  @Prop({default: false}) public showButton!: boolean;

  private smoothReflowOptions: Options = {
    hideOverflow: false,
    transitionEvent: {
      selector: '.basket-item',
      propertyName: 'opacity'
    }
  };

  private openSampleBasketOrderModal() {
    this.$store.dispatch('ordering/setSampleBasketOrderModalOpen', true);
  }

  // // on mobile - handle opening/closing basket modal when switching to order sample modal
  // private toggleBasketModal(val) {
  //   this.$store.commit('global/setIsBasketModalOpen', val);
  // }
}
