import {Aggregation, ComplexAggregations, NestedAggregation} from '@/typings/interfaces/ElasticSearch';

export interface InspirationAggregations extends NestedAggregation {
  productLine: ProductLineAggregation;
  buildType: BuildTypeAggregation;
  decorSet: DecorSetAggregation;
  marketSegment: MarketSegmentAggregation;
  countryCode: { buckets: {key: string, doc_count:  number}[] };
}

export interface ProductLineAggregation extends NestedAggregation {
    // @ts-ignore
    'productLine.name': Aggregation<MultiTermBucket>;
}

export interface DecorSetAggregation extends NestedAggregation {
    // @ts-ignore
    'decorSet.name': Aggregation<MultiTermBucket>;
}

export interface BuildTypeAggregation extends NestedAggregation {
    // @ts-ignore
    'buildType.name': Aggregation<MultiTermBucket>;
}

export interface MarketSegmentAggregation extends NestedAggregation {
    // @ts-ignore
    'marketSegment.name': Aggregation<MultiTermBucket>;
}

export interface CountryCodeAggregation extends NestedAggregation {
    // @ts-ignore
    'countryCode': Aggregation<MultiTermBucket>;
}


export interface Response {
  results: any;
  total: number;
  uuids: string[];
  fullSet: boolean;
  aggregations: ComplexAggregations<InspirationAggregations>;
}

export interface InspirationSearchResponse {
    results: InspirationCard[]
    total: number,
    aggregations: ComplexAggregations<InspirationAggregations>
}

export interface InspirationCard {
    image: string,
    productLines: string[],
    title: string,
    url: string
}

export enum DropdownNames {
    PRODUCT_LINE = 'productLine',
    BUILD_TYPE = 'buildType',
    DECOR_SET = 'decorSet',
    MARKET_SEGMENT = 'marketSegment',
    COUNTRY_CODE = 'countryCode',
}

export interface Dropdown {
    name: DropdownNames;
    count: number | null;
    options: DropdownOption[];
}

export interface DropdownOption {
    label: string;
    value: string;
}



