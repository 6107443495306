









import {Component, Vue} from 'vue-property-decorator';

@Component
export default class TrHambButton extends Vue {
  private active = false;

  toggle() {
    this.active = !this.active;
    this.$root.$emit('hamburger', this.active);
  }
}
