




































































import FilterDropdown from '@/components/filter/FilterDropdown.vue';
import IconSearch from '@/assets/svg/icons/icon-search.svg?inline';
import IconReset from '@/assets/svg/icons/icon-reset.svg?inline';
import TrButton from '@/components/shared/TrButton.vue';
import { ComplexAggregations } from '@/typings/interfaces/ElasticSearch';
import {
  Dropdown,
  DropdownNames,
  DropdownOption,
  InspirationAggregations,
  InspirationCard,
  InspirationSearchResponse,
} from '@/typings/interfaces/InspirationSearch';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import inspirationApi from '@/api/inspirationApi';
import { AxiosResponse } from 'axios';
import ProjectSlide from '@/components/global/ProjectSlide.vue';
import TrModal from '@/components/shared/TrModal.vue';
import SampleSelectorWizard from '@/components/global/SampleSelectorWizard.vue';
import _ from 'lodash';

gsap.registerPlugin(ScrollToPlugin);

interface ActiveFilters {
  [DropdownNames.PRODUCT_LINE]: string | null;
  [DropdownNames.BUILD_TYPE]: string | null;
  [DropdownNames.DECOR_SET]: string | null;
  [DropdownNames.MARKET_SEGMENT]: string | null;
  [DropdownNames.COUNTRY_CODE]: string | null;
}

@Component({
  name: 'InspirationOverview',
  components: {
    SampleSelectorWizard,
    TrModal,
    IconSearch,
    IconReset,
    TrButton,
    FilterDropdown,
    ProjectSlide,
  },
})
export default class InspirationOverview extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ default: '{}' }) queryParams: any;
  @Ref('filter-dropdowns') filterDropdownsRefs!: FilterDropdown[];

  private activeFilters: ActiveFilters = {
    [DropdownNames.BUILD_TYPE]: null,
    [DropdownNames.COUNTRY_CODE]: null,
    [DropdownNames.DECOR_SET]: null,
    [DropdownNames.MARKET_SEGMENT]: null,
    [DropdownNames.PRODUCT_LINE]: null,
  };

  private searchValue: string = '';

  private paginationOptions: { defaultSize: number; defaultIncrementBy: number } = {
    defaultSize: 12,
    defaultIncrementBy: 12,
  };

  private pagination: { size: number; incrementBy: number; total: number } = {
    size: this.paginationOptions.defaultSize,
    incrementBy: this.paginationOptions.defaultIncrementBy,
    total: 0,
  };

  private inspirationCards: InspirationCard[] | null = null;
  private aggregations: ComplexAggregations<InspirationAggregations> | undefined;
  private dropdowns: Dropdown[] | null = null;

  resetFilter(name: DropdownNames){
    this.activeFilters[name] = null;
  }

  private debouncedSearch = _.debounce(() => this.doSearch(), 200);
  mounted() {
    inspirationApi
      .search({ params: { size: this.pagination.size } })
      .then((response: AxiosResponse<InspirationSearchResponse>) => {
        this.inspirationCards = response.data.results;
        this.pagination.total = response.data.total;
        this.aggregations = response.data.aggregations;
        this.dropdowns = this.getDropdowns(response.data.aggregations.filtered);
      });
  }

  getDropdowns({ buildType, countryCode, decorSet, marketSegment, productLine }: InspirationAggregations): Dropdown[] {
    return [
      {
        name: DropdownNames.PRODUCT_LINE,
        count: productLine.doc_count,
        options: this.getDropdownOptionsFromBuckets(productLine['productLine.name'].buckets),
      },
      {
        name: DropdownNames.DECOR_SET,
        count: decorSet.doc_count,
        options: this.getDropdownOptionsFromBuckets(decorSet['decorSet.name'].buckets),
      },
      {
        name: DropdownNames.MARKET_SEGMENT,
        count: marketSegment.doc_count,
        options: this.getDropdownOptionsFromBuckets(marketSegment['marketSegment.name'].buckets),
      },
      {
        name: DropdownNames.BUILD_TYPE,
        count: buildType.doc_count,
        options: this.getDropdownOptionsFromBuckets(buildType['buildType.name'].buckets),
      },
      {
        name: DropdownNames.COUNTRY_CODE,
        count: null,
        options: this.getDropdownOptionsForCountry(countryCode.buckets),
      },
    ];
  }

  getDropdownOptionsFromBuckets(buckets: { key: string[] }[]): DropdownOption[] {
    return buckets.map((item) => {
      return { label: item.key[1], value: item.key[0] };
    });
  }

  getDropdownOptionsForCountry(countryCodeBuckets: { key: string; doc_count: number }[]): DropdownOption[] {
    const countryNames = new (Intl as any).DisplayNames(_mgnl.language.replace("_","-"), { type: 'region' });

    const dropdownOptions =  countryCodeBuckets.map((item) => {
      const countryName = countryNames?.of(item.key);

      return { label: countryName as string, value: item.key };
    });

    return dropdownOptions.sort((a, b) => a.label.localeCompare(b.label, _mgnl.language.replace("_","-")))
  }

  @Watch('searchValue')
  private doSearchWithDebounce() {
    this.debouncedSearch();
  }

  @Watch('activeFilters', { deep: true })
  private doSearch() {
    // gsap.to(window, {
    //   scrollTo: {
    //     y: 0,
    //     autoKill: true,
    //   },
    //   duration: 0.5,
    //   ease: Power2.easeOut,
    // });

    inspirationApi
      .search({
        params: {
          q: this.searchValue,
          size: this.pagination.size,
          'buildType.name': this.activeFilters[DropdownNames.BUILD_TYPE],
          countryCode: this.activeFilters[DropdownNames.COUNTRY_CODE],
          'decorSet.name': this.activeFilters[DropdownNames.DECOR_SET],
          'productLine.name': this.activeFilters[DropdownNames.PRODUCT_LINE],
          'marketSegment.name': this.activeFilters[DropdownNames.MARKET_SEGMENT],
        },
      })
      .then((response: AxiosResponse<InspirationSearchResponse>) => {
        this.inspirationCards = response.data.results;
        this.pagination.total = response.data.total;
      });
  }

  private showMore() {
    this.pagination.size = this.pagination.size + this.pagination.incrementBy;
    this.doSearch();
  }

  get isShowMoreButton() {
    return this.pagination.total >= this.pagination.size;
  }

  get hasActiveFilter() {
    for (let key in this.activeFilters) {
      if (this.activeFilters[key] !== null) {
        return true;
      }
    }

    return false;
  }

  private resetFilters(): void {
    for (const key in this.activeFilters) {
      this.activeFilters[key] = null;
    }

    this.pagination.size = this.paginationOptions.defaultSize;

    this.filterDropdownsRefs.forEach((filterDropdownsRef)=>{
      filterDropdownsRef.reset();
    })
  }
}
